import React from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/Opex.css";
import RevexContent from './RevexContent';

const Revex = () => {
    return (
        <>
            <div className="alcorn-revex-solar-project">
                <Header />
                <RevexContent />
                <Footer />
            </div>
        </>
    )
}

export default Revex