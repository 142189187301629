import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import "./Scss/Home.css";

const Counter = ({ counterdata, host }) => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const statsSectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAnimated) {
                    setHasAnimated(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (statsSectionRef.current) {
            observer.observe(statsSectionRef.current);
        }

        return () => {
            if (statsSectionRef.current) {
                observer.unobserve(statsSectionRef.current);
            }
        };
    }, [hasAnimated]);
    return (
        <>
            <div className="alcorn-home-counter">
                <section className="stats-section" ref={statsSectionRef}>
                    <div className="container">
                        <div className="row justify-content-center">
                            {
                                counterdata?.map((counterdata, index) => {
                                    return (

                                        <div key={index} className="col-md-3 col-sm-6 stat-item">
                                            <div className="stat-icon">
                                                <img src={`${host}/${counterdata?.logo}`} alt="Projects Done" />
                                            </div>
                                            <div className="main_counter">
                                                <h4 className="counter" data-target={counterdata ? Number(counterdata?.number) : 1000}>
                                                    {hasAnimated ? <CountUp end={Number(counterdata?.number)} /> : `${Number(counterdata?.number)}`}
                                                </h4>
                                                <p>{counterdata?.title}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Counter