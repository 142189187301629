import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
const Banner = ({ bannerData, host }) => {
    var settings = {
        // autoplay: true,
        // autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: null,
        nextArrow: null,
    };
    return (
        <div className='alcorn-home-banner'>
            <div className="slider-wrapper">
                <Slider {...settings} className="main-slider-theme owl-carousel owl-theme">
                    {
                        bannerData?.map((value, index) => {
                            return (
                                <div className="" key={index} >

                                    <div className={`slider-items bg-img-${index + 1}`} style={{
                                        background: `url(${host}/${value?.image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                    }}>
                                        <div className="sigle-item pas_detail_section">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="main_banner_headline">
                                                            <h2>
                                                                <span>
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/fav_icon.png`} alt="favicon" />
                                                                </span>
                                                                Welcome to Alcorn India
                                                            </h2>
                                                            <h3 className='text-capitalize'>
                                                                {value?.title}
                                                                {/* Solar Power the
                                              <span>Future with Renewable</span> */}
                                                            </h3>
                                                            <p>
                                                                {parse(`${value?.summary}`)}

                                                            </p>
                                                            <Link to="/all-services" className="main_nav_services">
                                                                Our Services
                                                            </Link>
                                                            <Link to="/contact-us" className="main_nav_contact">
                                                                Contact Us
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </Slider>
            </div>
        </div>
    )
}

export default Banner