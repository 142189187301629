import React from 'react'
import Footer from '../../../Common/Footer/Footer'
import Header from '../../../Common/Header/Header'
import "../Scss/Products.css";
const OffGridSolar = () => {
    return (
        <>
            <div className="on-grid">
                <Header />
                {/* ==========banner=========== */}
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-on-grid">
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2>
                                                Off-Grid <span>Solar</span> System
                                            </h2>
                                            <p># BEAT THE ELECTRICITY BILL </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                {/* ==========Our Solar product=========== */}
                <section className="padding-b-t-50 bg_images_on-grid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main_why_choose_us services_payra pb-3">
                                    <h2 className='text-center'>
                                        Off-Grid <span> Solar </span> Power Solutions{" "}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row flex_dir-xs">
                            <div className="col-lg-12 col-md-12">
                                <div className="main_detail_services product_text_detail">
                                    <p>
                                        Power your homes and businesses with clean, renewable energy using
                                        Alcorn's advanced On grid Inverters. Our range starts from 3KVA,
                                        providing efficient and reliable solar power solutions for various
                                        applications. Our inverters convert solar energy into electricity
                                        efficiently, reducing your reliance on fossil fuels and carbon
                                        footprint.
                                    </p>
                                    <ul>
                                        <li>Connected to the public electricity grid</li>
                                        <li>Excess power is fed back into the grid</li>
                                        <li>
                                            <b>Benefits: </b> Cost savings, reliability, potential earnings
                                            from surplus power
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-50 bg_images_product_next">
                    <div className="container">
                        <div className="row flex_dir-xs">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images s-left product_img_swc">
                                    <img src="/assets/images/on-grid-solar/Off_Grid_1.png" alt="Products" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Inverters</h4>
                                    <p className="pb-3">
                                        An inverter&nbsp;converts the DC electricity from sources such as
                                        batteries or fuel cells to AC electricity.
                                    </p>
                                    <p className="pb-5">
                                        The electricity can be at any required voltage; in particular it
                                        can operate AC equipment designed for mains operation, or
                                        rectified to produce DC at any desired voltage.
                                    </p>
                                    <a href="#">View All Product</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-50 bg_images_on-grid">
                    <div className="container">
                        <div className="row flex_dir-xs">
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Battery</h4>
                                    <p className="pb-3">
                                        Batteries&nbsp;consist of two electrical terminals called the
                                        cathode and the anode, separated by a chemical material called an
                                        electrolyte.{" "}
                                    </p>
                                    <p className="pb-5">
                                        To accept and release energy, a battery is coupled to an external
                                        circuit.
                                    </p>
                                    <a href="#">View All Product</a>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images s-right product_img_swc">
                                    <img src="/assets/images/on-grid-solar/on_grid_2.png" alt="Products" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-50 bg_images_product_next">
                    <div className="container">
                        <div className="row flex_dir-xs">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images s-left product_img_swc">
                                    <img src="/assets/images/on-grid-solar/on_grid_3.png" alt="Products" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Solar Panel</h4>
                                    <p className="pb-3">
                                        Solar panels are&nbsp;usually made from silicon, or another
                                        semiconductor material installed in a metal panel frame with a
                                        glass casing.{" "}
                                    </p>
                                    <p className="pb-3">
                                        When this material is exposed to photons of sunlight (very small
                                        packets of energy) it releases electrons and produces an electric
                                        charge.
                                    </p>
                                    <a href="#">View All Product</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========Our Solar product=========== */}

                <Footer />          </div>
        </>

    )
}

export default OffGridSolar