import React from 'react';
import { Puff } from 'react-loader-spinner';

const Loader = () => {
    return (
        <>
            <Puff
                visible={true}
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="puff-loading"
                wrapperStyle={{ height: "100vh", justifyContent: "center", alignItems: "center" }}
                wrapperClass=""
            />
        </>
    )
}

export default Loader;