import React,{useEffect} from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/Blog.css";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { BlogDetailsPage } from '../../../Redux/actions/action';
const Blog = () => {
    const host = process.env.REACT_APP_API_SERVER
    const { name } = useParams();
    const dispatch = useDispatch();
    const {
        loadingBlogDetailsPageApi,
        findBlogDetailsPage,
        findBlogDetailsPageError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(BlogDetailsPage(name));
    }, [name]);
    return (
        <div className="blog-section">
            <Header />
            <section>
                <div className="slider-wrapper">
                    <div className="slider-items-SPGS" style={{background:`url(${host}/${findBlogDetailsPage?.banner})`}}>
                        <div className="banner_detail_text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <h2>
                                         {findBlogDetailsPage?.heading}
                                        </h2>
                                        <p>{parse(`${findBlogDetailsPage?.banner_subtitle}`)} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="padding-b-t-50 bg_images_product_next">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main_why_choose_us services_payra pb-5">
                                <h2 className="text-left text-capitalize">
                                    {findBlogDetailsPage?.title}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-12 col-md-12">
                            <div className="main_detail_services product_text_detail">
                                <p className="pb-3">
                                    {parse(`${findBlogDetailsPage?.description}`)}
                                </p>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Blog