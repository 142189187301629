import React,{useEffect} from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import ContactusPage from './ContactusPage'

const Contactus = () => {
  

    return (
        <>
        <div className="contact-us-page">

            <Header />
            <ContactusPage />
            <Footer />
            
        </div>
        </>
    )
}

export default Contactus