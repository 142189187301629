import React from 'react';
import "./Scss/About.css";
import parse from 'html-react-parser';
const Whoweare = ({ findAboutUsData }) => {
    const host = process.env.REACT_APP_API_SERVER
    const points = JSON.parse(findAboutUsData?.points || '[]');
    return (
        <>
            <div className="alcorn-about-who-we-are">
                <section className="padding-b-t-50 bg_images_product align-items-center">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6">
                                <div className="adout_mainsection">
                                    <img
                                        src={`${host}/${findAboutUsData?.image_one}`}
                                        alt="Solar Panels Ground"
                                        className="main-image"
                                    />
                                    <img
                                        src={`${host}/${findAboutUsData?.image_two}`}
                                        alt="Solar Panels House"
                                        className="inset-image"
                                    />
                                    <div className="dot-pattern" />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="about_section_detail">
                                    <h6>
                                        <span>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/fav_icon.png`} alt="who" />
                                        </span>
                                        who we are
                                    </h6>
                                    <h2>About Us </h2>
                                    <p className='max-content'>
                                        {parse(`${findAboutUsData?.description}`)}
                                    </p>

                                    <ul>
                                        {
                                            points?.map((value, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span className="main_chek_icon">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/check_icon.png`} alt="chek" />
                                                        </span>
                                                        {value}
                                                    </li>
                                                )
                                            })
                                        }
                                     
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Whoweare