import React,{useEffect} from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import Whoweare from './Whoweare'
import Missionvisonvalue from './Missionvisonvalue'
import Team from './Team'
import Testimonial from '../Home/Testimonial'
import HelpandSupport from '../Home/HelpandSupport'
import { useDispatch, useSelector } from 'react-redux';

import { AboutUsData } from '../../../Redux/actions/action'
const About = () => {
    const host = process.env.REACT_APP_API_SERVER
    const dispatch = useDispatch();
    const {
        loadingAboutUsDataApi,
        findAboutUsData,
        findAboutUsDataError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(AboutUsData());
    }, []);
    return (
        <>
        <div className="about-us-page">

            <Header />
            <Whoweare findAboutUsData={findAboutUsData}/>
            <Missionvisonvalue findAboutUsData={findAboutUsData} host={host} />
            <Team findAboutUsData={findAboutUsData} host={host}/>
            <Testimonial findHomePageDataData={findAboutUsData} host={host}/>
            <HelpandSupport findHomePageDataData={findAboutUsData} host={host}/>
            <Footer />
        </div>
        </>
    )
}

export default About