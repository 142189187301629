import React, { useEffect, useState } from 'react'
import "./Scss/LoadCalculator.css"
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import $ from 'jquery';
import { LoadCalculatorData } from '../../../Redux/actions/action';
import Loader from '../../Common/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
const LoadCalculator = () => {
    const dispatch = useDispatch();
    const [quantities, setQuantities] = useState({});
    const [totalWattage, setTotalWattage] = useState(0);
    const [inverterCapacity, setInverterCapacity] = useState(0);
    const [batteryCapacity, setBatteryCapacity] = useState(0);
    const [noOfBattery, setNoOfBattery] = useState(0);

    const {
        findLoadCalculator,
        findLoadCalculatorError,
        loadingLoadCalculatorApi,
    } = useSelector(state => state?.reducer);

    useEffect(() => {
        dispatch(LoadCalculatorData());
    }, []);
    const formik = useFormik({
        initialValues: {
            percent: "",
            backup_hr: "",
            watt: "",
        },
        validationSchema: Yup.object({
            percent: Yup.string().required("Please select average running load."),
            backup_hr: Yup.string().required("Please enter backup hours."),
            watt: Yup.string().required("Total watt can not be zero."),
        }),
        onSubmit: async (values, { resetForm }) => {
            const backupTime = Number(values.backup_hr); // hours
            const requiredBatteryStorage = Number(values.watt) * backupTime; // in watt-hours (Wh)
            // Calculate the number of batteries needed
            const leadAcidBatteryCapacity = 1300; // in Wh (for 150Ah battery)
            const numBatteries = Math.ceil(requiredBatteryStorage / leadAcidBatteryCapacity);
            // Step 3: Calculate inverter capacity
            const inverterCapacityVal = Number(values.watt + (values.watt * 0.20)); // Add 20% margin
            setInverterCapacity(inverterCapacityVal.toFixed(2));
            setBatteryCapacity(requiredBatteryStorage.toFixed(2));
            setNoOfBattery(numBatteries);

            let modalBtn = document.getElementById("showRecommendation");
            if (modalBtn) { modalBtn.click() }
        },
    });

    // Handle increment
    const handleIncrement = (categoryId, applianceId, watt) => {
        setQuantities(prevQuantities => {
            const newQuantities = {
                ...prevQuantities,
                [categoryId]: {
                    ...prevQuantities[categoryId],
                    [applianceId]: (prevQuantities[categoryId]?.[applianceId] || 0) + 1
                }
            };
            calculateTotalWattage(newQuantities);
            return newQuantities;
        });
    };

    // Handle decrement
    const handleDecrement = (categoryId, applianceId, watt) => {
        setQuantities(prevQuantities => {
            const newQuantities = {
                ...prevQuantities,
                [categoryId]: {
                    ...prevQuantities[categoryId],
                    [applianceId]: Math.max((prevQuantities[categoryId]?.[applianceId] || 0) - 1, 0)
                }
            };
            calculateTotalWattage(newQuantities);
            return newQuantities;
        });
    };

    // Calculate the total wattage
    const calculateTotalWattage = (quantities) => {
        let total = 0;
        findLoadCalculator.forEach(cat => {
            cat?.Appliance.forEach(appliance => {
                const qty = quantities[cat.id]?.[appliance.id] || 0;
                total += qty * appliance.watt;
            });
        });
        setTotalWattage(total);
        formik.setFieldValue("watt", total)
    };

    if (loadingLoadCalculatorApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }


    return (
        <>
            <div className="load-calculator">
                <Header />
                <section className="padding-b-t-20">
                    <div className="container">
                        <div className="requirement_main">
                            <h4>
                                <span>1</span>Define Your Load Requirement
                            </h4>
                            <div className="main_detail_bg">
                                <div className="row">
                                    {findLoadCalculator && findLoadCalculator.map((cat, index) => {
                                        return <div className="col-lg-6" key={index + 1}>
                                            <div className="card-fans-cooler">
                                                <div className="card-header">{cat?.name}</div>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Appliances</th>
                                                                <th>Wattage</th>
                                                                <th>Quantity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                cat?.Appliance.map((appliance, i) => {
                                                                    return <tr key={i + 1}>
                                                                        <td>{appliance?.name}</td>
                                                                        <td>{appliance?.watt}</td>
                                                                        <td>
                                                                            <div className="input-group quantity">
                                                                                <button
                                                                                    className="btn btn-primary btn-minus"
                                                                                    type="button"
                                                                                    onClick={() => handleDecrement(cat.id, appliance.id)}
                                                                                >
                                                                                    -
                                                                                </button>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control text-center"
                                                                                    value={quantities[cat.id]?.[appliance.id] || 0}
                                                                                    readOnly
                                                                                />
                                                                                <button
                                                                                    className="btn btn-primary btn-plus"
                                                                                    type="button"
                                                                                    onClick={() => handleIncrement(cat.id, appliance.id)}
                                                                                >
                                                                                    +
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="requirement_main">
                            <h4>
                                <span>2</span>Define Your Backup Requirement
                            </h4>
                            <div className="main_detail_bg">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="main_input_detail">
                                                <label>Average Running Load: </label>
                                                {/* <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Select Percentage"
                                                /> */}
                                                <select
                                                    className="form-control"
                                                    placeholder="Select Percentage"
                                                    name="percent"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.percent}
                                                >
                                                    <option value="">-Select percentage-</option>
                                                    <option value={25}>25%</option>
                                                    <option value={50}>50%</option>
                                                    <option value={75}>75%</option>
                                                    <option value={100}>100%</option>
                                                </select>
                                                {/* <span>*Average Running Load is required</span> */}
                                                {formik.touched.percent && formik.errors.percent ? (
                                                    <div className="error">{formik.errors.percent}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="main_input_detail">
                                                <label>Backup Requirement (in hours): </label>
                                                <input type="number" className="form-control" placeholder="e.g. 5" min={0}
                                                    name='backup_hr'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.backup_hr}
                                                />
                                            </div>
                                            {formik.touched.backup_hr && formik.errors.backup_hr ? (
                                                <div className="error">{formik.errors.backup_hr}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="main_input_detail">
                                                <label>Total Watt:</label>
                                                <input type="text" className="form-control" placeholder="0" readOnly
                                                    name='watt'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.watt} />
                                            </div>
                                            {formik.touched.watt && formik.errors.watt ? (
                                                <div className="error">{formik.errors.watt}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="main_Calculate_button text-left  pt-4">
                                                <button type='submit'>Calculate</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

            {/* Modal */}
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ display: "none" }} id="showRecommendation">
                Launch static backdrop modal
            </button>
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Inverter Recommendations for you</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <th scope="row">Inverter Capacity</th>
                                        <td>{inverterCapacity} VA</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Battery Capacity</th>
                                        <td>{batteryCapacity} Ah</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">No. of battery</th>
                                        <td colspan="2">{noOfBattery}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Recalculate</button>
                            {/* <button type="button" class="btn btn-primary">Understood</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoadCalculator