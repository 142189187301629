import React, { useEffect } from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/services.css";
import { Link } from 'react-router-dom';
import { AllServiceData } from '../../../Redux/actions/action';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
const AllServices = () => {
    const host = process.env.REACT_APP_API_SERVER

    const dispatch = useDispatch();
    const {
        loadingAllServiceDataApi,
        findAllServiceData,
        findAllServiceDataError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(AllServiceData());
    }, []);
    return (
        <>
            <div className="all-services">
                <Header />
                {/* ==========banner=========== */}
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-srvices" style={{ background: `url(${host}/${findAllServiceData?.banner})` }}>
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h2>
                                                {findAllServiceData?.banner_heading}
                                            </h2>
                                            <p>
                                                {parse(`${findAllServiceData?.banner_subheading}`)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                {/* ==========Our Solar Services=========== */}
                <section className="padding-b-t-50 bg_images_se">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main_why_choose_us services_payra pb-3 text-center">
                                    <h2>
                                        {findAllServiceData?.heading}
                                    </h2>
                                    <p>
                                        {parse(`${findAllServiceData?.description}`)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {
                            findAllServiceData?.servicelist?.map((value, index) => {
                                const isOdd = index % 2 !== 0;
                                // Determine the class to add
                                const sectionClass = isOdd ? "left-section" : " right-section flex_dir-xs align-items-center";
                                const imgRight = isOdd ? "s-left" : "  s-right";
                                return (
                                    <div key={index} className={`row padding-b-t-50 ${sectionClass} align-items-center`}>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="main_detail_services">
                                                <h2>
                                                  {value?.name}
                                                </h2>
                                                {
                                                    parse(`${value?.short_description}`)
                                                }
                                                <Link to={`/capex-solar-projects/${value?.slug}`}>
                                                    View Information
                                                    <span>
                                                        <i className="fa-solid fa-arrow-right-long" />
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className={`srvices_images ${imgRight}`}>
                                                <img src={`${host}/${value?.image}`} alt="savices" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                     
                    </div>
                </section>
                {/* ==========Our Solar Services=========== */}
                <Footer />
            </div>
        </>

    )
}

export default AllServices