import React from 'react';
import "./Scss/Home.css";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
const About = ({ aboutUs, host }) => {
    const points = JSON.parse(aboutUs?.points || '[]');
    return (
        <div className='alcorn-home-about'>
            <section className="padding-b-t-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-6">
                            <div className="adout_mainsection">
                                <img
                                    src={`${host}/${aboutUs?.image_one}`}
                                    alt="Solar Panels Ground"
                                    className="main-image"
                                />
                                <img
                                    src={`${host}/${aboutUs?.image_two}`}
                                    alt="Solar Panels House"
                                    className="inset-image"
                                />
                                <div className="dot-pattern" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="about_section_detail">
                                <h6>
                                    <span>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/fav_icon.png`} alt="who" />
                                    </span>
                                    who we are
                                </h6>
                                <h2>About Us </h2>
                                <p className='max-content'>
                                    {parse(`${aboutUs?.description}`)}
                                </p>
                                <ul>
                                    {
                                        points?.map((value, index) => {
                                            return (
                                                <li key={index}>
                                                    <span className="main_chek_icon">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/check_icon.png`} alt="chek" />
                                                    </span>
                                                    {value}
                                                </li>

                                            )
                                        })
                                    }

                                </ul>
                                <div className="main_about_more">
                                    <Link to="/about">More about us </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default About