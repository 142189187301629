import React,{useState,useEffect} from 'react'
import ContactFormServices from './ContactFormServices'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
const EPCcontent = () => {
    const [captchaInput, setCaptchaInput] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    useEffect(() => {
        loadCaptchaEnginge(6); // Initialize the CAPTCHA with 6 characters
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateCaptcha(captchaInput)) {
            alert('Captcha Matched');
        } else {
            alert('Captcha Does Not Match. Please try again.');
        }
    };
    return (
        <>
            {/* Banner */}
            <section>
                <div className="slider-wrapper">
                    <div className="slider-items-caPex">
                        <div className="banner_detail_text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <h2>
                                            EPC <span>Services</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* our solar services */}
            <section className="padding-b-t-50 bg_images_inverters_series pb-0">
                <div className="container">
                    <div className="row padding-b-t-20 flex_dir-xs align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="main_detail_services">
                                <h2 className="pb-1">
                                    EPC <span> Solar </span> Services
                                </h2>
                                <p className="pb-3">
                                    The deprecation of EPC (Engineering, Procurement, and Construction)
                                    services typically refers to the industry or sector associated with
                                    providing these comprehensive services for large-scale projects. EPC
                                    services involve managing the entire project lifecycle from design
                                    through procurement to construction and sometimes even commissioning
                                    and startup.
                                </p>
                                <p className="pb-3">
                                    Overall, the deprecation of EPC services reflects broader changes
                                    within the construction and project management industries, driven by
                                    a combination of economic, technological, and strategic factors.
                                </p>
                                <p className="pb-3">
                                    Overall, the deprecation of EPC services reflects broader changes
                                    within the construction and project management industries, driven by
                                    a combination of economic, technological, and strategic factors.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="srvices_images s-right b-right">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/CapEx/Capex-detail-1.png`} alt="savices" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="main_detail_services list_style_auto">
                                <ul>
                                    <li>
                                        <b>Shift in Business Focus:</b> Companies might decide to move
                                        away from offering EPC services to focus on other areas of their
                                        business that they find more profitable or strategic.
                                    </li>
                                    <li>
                                        <b>Market Trends:</b> Changes in market demand or technological
                                        advancements may make traditional EPC services less relevant or
                                        competitive.
                                    </li>
                                    <li>
                                        <b>Evolving Project Delivery Models:</b> There might be a shift
                                        towards alternative project delivery models like EPCM
                                        (Engineering, Procurement, and Construction Management), where the
                                        client takes a more active role in managing the project.
                                    </li>
                                    <li>
                                        <b>Regulatory or Environmental Factors:</b> Changes in regulations
                                        or increased emphasis on sustainability might drive companies away
                                        from traditional EPC services towards more environmentally
                                        friendly or compliant project methodologies.
                                    </li>
                                    <li>
                                        <b>Economic Considerations:</b> Cost considerations, such as the
                                        rising costs of construction materials or labor, could also
                                        influence the decision to deprecate EPC services.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="alcorn-contact-form-services">
                <section className="padding-b-t-50 pt-3">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="contact-form p-5">
                                <div className="main_detail_services">
                                    <h2 className="pb-4">
                                        Apply for <span> EPC </span> Services
                                    </h2>
                                </div>
                                <form className="input_detail_sectrion" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Your Name"
                                                    required=""
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-lg-8">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Your Email Address"
                                                    className="form-control"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    name="subject"
                                                    placeholder="Subject"
                                                    className="form-control"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <textarea
                                                    name="Message"
                                                    placeholder="Message"
                                                    className="form-control"
                                                    value={formData.Message}
                                                    onChange={handleChange}
                                                    defaultValue={""}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 custom-captcha">
                                                <LoadCanvasTemplate reloadText={"Reload"} />
                                                <input
                                                    type="text"
                                                    value={captchaInput}
                                                    onChange={(e) => setCaptchaInput(e.target.value)}
                                                    placeholder="Enter CAPTCHA"
                                                    required
                                                    className="form-control mt-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button
                                                    type="submit"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#exampleModal"
                                                >
                                                   Submit Now
                                                    <span style={{marginLeft:"12px"}}>
                                                        <i className="fa-solid fa-arrow-right-long" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                               
                            </div>
                        </div>
                    </div>
                </section>

                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog Registra_modul_Request">
                        <div className="modal-content ">
                            <div className="modal-body thank_heading_detail">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/popup_back.png`} alt="img" />
                                <h4>Thank You</h4>
                                <p>Your submission has been received,</p>
                                <p>We will be in touch and contact you soon!</p>
                                <div className="request_send">
                                    <button
                                        type="button"
                                        className="btn btn-secondary back_color_main"
                                        data-bs-dismiss="modal"
                                    >
                                        Back to site{" "}
                                        <span>
                                            <i className="fa-solid fa-arrow-right-long" />
                                        </span>
                                    </button>
                                    {/* <a href="#" class="btn btn-secondary back_color_main"></span></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default EPCcontent