import React from 'react'
import parse from 'html-react-parser';
const Question = ({ faq, index }) => {
    return (
        <div className="accordion-item faq-question">
            <h2 className="accordion-header" id={`heading${index}`}>
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                >
                    {faq.question}
                </button>
            </h2>
            <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#faqAccordion"
            >
                <div className="accordion-body faq-answer">
                    {parse(`${faq.answer}`)}
                </div>
            </div>
        </div>
    )
}

export default Question