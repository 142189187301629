import React, { useEffect, useState } from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/StoreLocator.css";
import { Link, useNavigate } from 'react-router-dom';
import GoogleMap from './GoogleMap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Common/Loader/Loader';
import { StoreLocatorData, StoreLocatorPageData } from '../../../Redux/actions/action';
import { useFormik } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import * as Yup from "yup";
const host = process.env.REACT_APP_API_SERVER;

const StoreLocator = () => {
    const url = new URL(window.location.href);
    const pincode = url.searchParams.get('pin');
    const navigate = useNavigate();
    let [banner, setBanner] = useState("/assets/images/store_locator/banner.png");
    useEffect(() => {
        if (!pincode) {
            toast.error("Please enter a location.");
            navigate("/store-locator");
        }
    }, [url]);
    const [inputValue, setInputValue] = useState('');
    const [dataFound, setDataFound] = useState(false);
    const [locations, setLocations] = useState([]);
    const [store, setStore] = useState([]);
    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
    };

    const dispatch = useDispatch();
    const {
        findStoreLocator,
        findStoreLocatorError,
        loadingStoreLocatorApi,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(StoreLocatorData(pincode));
    }, [pincode]);

    const {
        findStoreLocatorPage,
        findStoreLocatorPageError,
        loadingStoreLocatorPageApi,
    } = useSelector(state => state?.reducer);

    useEffect(() => {
        dispatch(StoreLocatorPageData());
    }, [])

    useEffect(() => {
        if (findStoreLocator) {
            setStore(findStoreLocator);

            let pinArr = findStoreLocator.map(val => {
                return {
                    lat: Number(val.lat), lng: Number(val.lng), name: val.name
                }
            });
            setLocations(pinArr);

        }
    }, [findStoreLocator, pincode])

    const formik = useFormik({
        initialValues: {
            pincode: pincode
        },
        validationSchema: Yup.object({
            pincode: Yup.string().required("Please enter a city or pincode."),
        }),
        onSubmit: (values) => {
            navigate(`/store-locator-search?pin=${values.pincode}`)
        }
    });

    useEffect(() => {
        if (findStoreLocatorPage) {
            if (findStoreLocatorPage.storeLocatorBanner) {
                setBanner(`${host}/${findStoreLocatorPage.storeLocatorBanner}`)
            }
        }
    }, [findStoreLocatorPage])

    const handleSearchClick = () => {
        if (inputValue.trim() !== '') {
            setDataFound(true);
        } else {
            setDataFound(false);
        }
    };
    if (loadingStoreLocatorPageApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (loadingStoreLocatorApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }


    return (
        <>
            <div className="store-locator">
                <Toaster />
                <Header />
                {/* ==========banner=========== */}
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-store-locator"  style={{ background: `url("${banner}")` }}>
                            <div className="container">
                                <div className="row main_stor_locator">
                                    <div className="col-lg-6">
                                        <div className="main_store_locator">
                                            <h2>{findStoreLocatorPage?.storeLocatorHeading}</h2>
                                            <form onSubmit={formik.handleSubmit}>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Pincode or City"
                                                    className="form-control"
                                                    name='pincode'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.pincode}
                                                />
                                                {formik.touched.pincode && formik.errors.pincode ? (
                                                    <div className="error mb-3">{formik.errors.pincode}</div>
                                                ) : null}
                                                <button type="submit" className="searchStore" onClick={handleSearchClick}>
                                                    Search
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                <section className="padding-b-t-50 bg_images_product_next">
                    <div className="container">


                        {store?.length > 0 ? <div className="map_detail_main">
                            <div className="row">
                                <div className="col-lg-5">
                                    {store.map((data, index) => {
                                        return <div className="main_detail_locetion">
                                            <h3>
                                                {" "}
                                                {data?.name}
                                                {/* <small>2.7 km</small> */}
                                            </h3>
                                            <p>{data?.address}, {data?.city}, {data?.state}, {data?.country} - {data?.pincode}</p>
                                            <Link to={`tel:${data?.mobile}`}>{data.mobile}</Link>
                                            <div className="locetion_button">
                                                <Link
                                                    to={`https://www.google.com/maps?q=${data.lat},${data.lng}`}
                                                    className="locetion_button_first"
                                                    target='_blank'
                                                >
                                                    View On Map
                                                </Link>
                                                {/* <a href="#" className="locetion_button_dir">
                                                    Directions
                                                </a> */}
                                            </div>
                                        </div>
                                    })}
                                    {/* <div className="main_detail_locetion">
                                        <h3>
                                            {" "}
                                            Solarwave solutions <small>2.7 km</small>
                                        </h3>
                                        <p>15 Sellamuttu Avenue, 03 Colombo Colombo 00130</p>
                                        <a href="#">+976453686887</a>
                                        <div className="locetion_button">
                                            <Link
                                                onClick={() => { setDataFound(false); }}
                                                className="locetion_button_first"
                                            >
                                                View On Map
                                            </Link>
                                            <a href="#" className="locetion_button_dir">
                                                Directions
                                            </a>
                                        </div>
                                    </div>
                                    <div className="main_detail_locetion mt-3">
                                        <h3>
                                            {" "}
                                            Shining Solar <small>2.7 km</small>
                                        </h3>
                                        <p>3064 Schinner Village Suite 621 South Raymond Louisiana</p>
                                        <a href="#">+9172052-9388</a>
                                        <div className="locetion_button">
                                            <Link
                                                to="/store-locator-2"
                                                className="locetion_button_first"
                                            >
                                                View On Map
                                            </Link>
                                            <a href="#" className="locetion_button_dir">
                                                Directions
                                            </a>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-lg-7">
                                    <GoogleMap locations={locations} />
                                </div>
                            </div>
                        </div>
                            :
                            <div className="main_detail_section">
                                <h3>Not found Near Store Location</h3>
                            </div>}

                    </div>
                </section>
            </div>
            <Footer />
        </>

    )
}

export default StoreLocator