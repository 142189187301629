import React, { useEffect, useState } from 'react'
import 'intl-tel-input/build/css/intlTelInput.css';
import Header from '../../Common/Header/Header'
import toast, { Toaster } from 'react-hot-toast';
import Footer from '../../Common/Footer/Footer'
import "../DesignSolarProduct/Scss/DesignSolarProduct.css"
import CapexModel from '../Model/CapexModel'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { BookComplaint } from '../../../Redux/actions/action';
import { useFormik } from "formik";
import CancelIcon from '@mui/icons-material/Cancel';
import axios from "axios";
import { bookComplaint } from '../../../validationSchema/Index';
const BookYourComplaint = () => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const [imgWarranty, setImgWarranty] = useState(null);
    const host = process.env.REACT_APP_API_SERVER
    const dispatch = useDispatch();
    const {
        loadingBookComplaintApi,
        findBookComplaint,
        findBookComplaintError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(BookComplaint());
    }, []);

    const formik = useFormik({
        initialValues: {
            fname: "",
            lname: "",
            email: "",
            mobile: "",
            address: "",
            product_type_id: "",
            serial_number: "",
            installation_date: new Date(),
            reason_of_problem: "",
            problem_description: "",
            availability_date: new Date(),
            additional_note: "",
            invoice: null,
            product_images: [],
            warranty_doc: null,
        },
        validationSchema: bookComplaint,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            setIsSubmitting(true); 
            // Append each file individually
            values.product_images.forEach((file, index) => {
                formData.append(`product_images`, file);
            });

            // Append other fields
            Object.keys(values).forEach((key) => {
                if (key !== 'product_images') { // Skip product_images as it is already appended
                    formData.append(key, values[key]);
                }
            });
            try {
                const response = await axios.post(
                    `${host}/web/help-support/book-a-complaint`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                if (response.data.code === 200) {
                    toast.success(response?.data?.msg);
                    resetForm();
                    setSelectedImages([]);
                    setInvoice(null);
                    setImgWarranty(null)

                }
            } catch (error) {
                toast.error(error);
                console.error("Error:", error.response ? error.response.data : error.message);
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });
    const handleImageUpload = (event, formik) => {
        const files = Array.from(event.target.files);
        formik.setFieldValue("product_images", files);
        setSelectedImages((prevImages) => [...prevImages, ...files]);
    };
    const handleRemoveImage = (index) => {
        const updatedImages = selectedImages.filter((_, i) => i !== index);
        formik.setFieldValue("product_images", updatedImages);
        setSelectedImages(updatedImages);
    };
    const handleImageInvoice = (event, formik) => {
        const file = event.target.files[0];
        formik.setFieldValue("invoice", file);
        setInvoice(file);
    };

    const handleRemoveInvoice = () => {
        formik.setFieldValue("invoice", null);
        setInvoice(null);
    };

    const handleImageWarranty = (event, formik) => {
        const file = event.target.files[0];
        formik.setFieldValue("warranty_doc", file);
        setImgWarranty(file);
    };

    const handleRemoveimgWarranty = () => {
        formik.setFieldValue("warranty_doc", null);
        setImgWarranty(null);
    };
    return (
        <div className='design-solar-product'>
            <Toaster />
            <Header />
            <>
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-srvices" style={{ background: `url(${host}/${findBookComplaint?.banner})` }}>
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className=''>{findBookComplaint?.title}</h2>
                                            <p>
                                                {parse(`${findBookComplaint?.subtitle}`)} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                <section className="padding-b-t-50 bg_images_se">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main_why_choose_us services_payra pb-3">
                                    <h2 className="pb-3 text-center">
                                        {findBookComplaint?.heading}
                                    </h2>
                                    <p className="pb-3 text-center">
                                        {parse(`${findBookComplaint?.description}`)}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="card-fans-details">
                                        <div className="customize-header">Contact Information</div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>First Name </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter First Name "
                                                            name="fname"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.fname}
                                                        />
                                                        {formik.touched.fname && formik.errors.fname ? (
                                                            <div className="error">{formik.errors.fname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Last Name </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Last Name"
                                                            name="lname"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.lname}
                                                        />
                                                        {formik.touched.lname && formik.errors.lname ? (
                                                            <div className="error">{formik.errors.lname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Email Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Email Address "
                                                            name="email"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.email}
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className="error">{formik.errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Phone Number</label>
                                                        <div className="form-group custom-mobile-code">
                                                            <input
                                                                type="tel"
                                                                // id="mobile_code"
                                                                // ref={inputRef}
                                                                className="form-control main_detail_numbar w-100"
                                                                placeholder="Phone Number"
                                                                name="mobile"
                                                                inputMode="numeric"
                                                                pattern="[0-9]*"
                                                                onChange={(e) => {
                                                                    // Allow only digits
                                                                    const re = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        formik.handleChange(e);
                                                                    }
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.mobile}
                                                            />
                                                            {formik.touched.mobile && formik.errors.mobile ? (
                                                                <div className="error">{formik.errors.mobile}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Address</label>
                                                        <textarea
                                                            name="address"
                                                            placeholder="Enter Address"
                                                            className="form-control form-info-technology"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.address}
                                                        />
                                                        {formik.touched.address && formik.errors.address ? (
                                                            <div className="error">{formik.errors.address}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-fans-details">
                                        <div className="customize-header">Products Information</div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Solar product type</label>
                                                        <select
                                                            name="product_type_id"
                                                            className="sectopn_class"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.product_type_id}
                                                        >
                                                            <option value="">Select product type</option>
                                                            <option>Select1</option>
                                                            <option>Select2</option>
                                                        </select>
                                                        {formik.touched.product_type_id && formik.errors.product_type_id ? (
                                                            <div className="error">{formik.errors.product_type_id}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Serial Number</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Serial Number "
                                                            name="serial_number"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.serial_number}
                                                        />
                                                        {formik.touched.serial_number && formik.errors.serial_number ? (
                                                            <div className="error">{formik.errors.serial_number}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Installation date</label>
                                                        <div className="input-group-calecer">
                                                            <div className="custom-date-picker">
                                                                <DatePicker
                                                                    selected={formik.values.installation_date}
                                                                    onChange={(date) =>
                                                                        formik.setFieldValue("installation_date", date)
                                                                    }
                                                                    onBlur={formik.handleBlur}
                                                                    name="installation_date"
                                                                />
                                                                <CalendarMonthIcon />
                                                            </div>
                                                            {formik.touched.installation_date && formik.errors.installation_date ? (
                                                                <div className="error">{formik.errors.installation_date}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label htmlFor="#">Upload multiple Product Image</label>
                                                        <div className="file-input">
                                                            <input
                                                                type="file"
                                                                name="product_images"
                                                                id="product_images"
                                                                onChange={(event) => handleImageUpload(event, formik)}
                                                                multiple // Allow multiple file selection
                                                            />
                                                            <button className="folder_main_bro label_1">
                                                                Upload{" "}
                                                                <span>
                                                                    <img src="/assets/images/bx_image-add.png" alt="file" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                        {selectedImages?.length > 0 && (
                                                            <div className="image-preview-container d-flex justify-content-start gap-2">
                                                                {selectedImages.map((image, index) => (
                                                                    <div key={index} className="border border-[#cac8c8] rounded-md d-flex justify-center items-center relative multiple-img-box">
                                                                        <img
                                                                            className="object-contain h-[100px] w-[100px]"
                                                                            src={URL.createObjectURL(image)}
                                                                            alt={`Selected ${index}`}
                                                                        />
                                                                        <CancelIcon
                                                                            className="top-[6px] right-[6px] absolute-img"
                                                                            onClick={() => handleRemoveImage(index)}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        {formik.touched.product_images && formik.errors.product_images ? (
                                                            <div className="error">{formik.errors.product_images}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Reason of the Problem</label>
                                                        <select
                                                            name="reason_of_problem"
                                                            className="sectopn_class"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.reason_of_problem}
                                                        >
                                                            <option value="">Select the Problem</option>
                                                            <option>Select1</option>
                                                            <option>Select2</option>
                                                            {/* Add your problem options here */}
                                                        </select>
                                                        {formik.touched.reason_of_problem && formik.errors.reason_of_problem ? (
                                                            <div className="error">{formik.errors.reason_of_problem}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label htmlFor="#">Upload Warranty</label>
                                                        <div className="file-input">
                                                            <input
                                                                type="file"
                                                                name="warranty_doc"
                                                                onChange={(event) => handleImageWarranty(event, formik)}
                                                                // onChange={(event) => {
                                                                //     formik.setFieldValue("warranty_doc", event.currentTarget.files[0]);
                                                                // }}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            <button className="folder_main_bro label_1">
                                                                Upload{" "}
                                                                <span>
                                                                    <img src="/assets/images/bx_image-add.png" alt="file" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                        {imgWarranty && (
                                                            <div className="border border-[#cac8c8] rounded-md d-flex justify-center items-center relative multiple-img-box">
                                                                <img

                                                                    className="object-contain h-[100px] w-[100px]"
                                                                    src={URL.createObjectURL(imgWarranty)}
                                                                    alt="Selected"
                                                                />
                                                                <CancelIcon
                                                                    className="top-[6px] right-[6px] absolute-img"
                                                                    onClick={handleRemoveimgWarranty}
                                                                />
                                                            </div>
                                                        )}

                                                        {formik.touched.warranty_doc && formik.errors.warranty_doc ? (
                                                            <div className="error">{formik.errors.warranty_doc}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label htmlFor="#">Description of the Problem</label>
                                                        <textarea
                                                            name="problem_description"
                                                            placeholder="Describe the Problem..."
                                                            className="form-control-technology"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.problem_description}
                                                        />
                                                        {formik.touched.problem_description && formik.errors.problem_description ? (
                                                            <div className="error">{formik.errors.problem_description}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Upload Invoice </label>
                                                        <div className="file-input">
                                                            <input type="file"
                                                                name="invoice"
                                                                id="invoice"
                                                                onChange={(event) => handleImageInvoice(event, formik)}
                                                            />
                                                            <button className="folder_main_bro label_1">
                                                                Upload{" "}
                                                                <span>
                                                                    <img src="/assets/images/bx_image-add.png" alt="file" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                        {invoice && (
                                                            <div className="border border-[#cac8c8] rounded-md d-flex justify-center items-center relative multiple-img-box">
                                                                <img

                                                                    className="object-contain h-[100px] w-[100px]"
                                                                    src={URL.createObjectURL(invoice)}
                                                                    alt="Selected"
                                                                />
                                                                <CancelIcon
                                                                    className="top-[6px] right-[6px] absolute-img"
                                                                    onClick={handleRemoveInvoice}
                                                                />
                                                            </div>
                                                        )}
                                                        {formik.touched.invoice && formik.errors.invoice ? (
                                                            <div className="error">{formik.errors.invoice}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Availability date </label>
                                                        <div className="input-group-calecer">
                                                            <div className="custom-date-picker">
                                                                <DatePicker
                                                                    selected={formik.values.availability_date}
                                                                    onChange={(date) =>
                                                                        formik.setFieldValue("availability_date", date)
                                                                    }
                                                                    onBlur={formik.handleBlur}
                                                                    name="availability_date"
                                                                />
                                                                <CalendarMonthIcon />
                                                            </div>
                                                            {formik.touched.availability_date && formik.errors.availability_date ? (
                                                                <div className="error">{formik.errors.availability_date}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-fans-details">
                                        <div className="customize-header">
                                            Documents upload (if applicable)
                                        </div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label htmlFor="#">Additional Notes</label>
                                                        <textarea
                                                            name="additional_note"
                                                            placeholder="Describe the Additional Notes..."
                                                            className="form-control-technology"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.additional_note}
                                                        />
                                                        {formik.touched.additional_note && formik.errors.additional_note ? (
                                                            <div className="error">{formik.errors.additional_note}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="pull-left pb-4">
                                                    <div className='d-flex justify-content-start '>


                                                        <input type="checkbox" name="" className='me-1'/>*
                                                        </div>
                                                        <label
                                                            htmlFor=""
                                                            className="inline-block pointer fz15 white-color"
                                                        >
                                                            I agree to be contacted by Alcorn India regarding my
                                                            solar installation inquiry.
                                                        </label>
                                                    </div>
                                                    <button type="submit" className="main_input_detail_button" disabled={isSubmitting}>
                                                        {isSubmitting ? <i className="fa fa-spinner fa-spin" /> : "Submit"}
                                                    </button>
                                                    {/* <button
                                                        type="submit"
                                                      
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#bookComplaint"
                                                    >
                                                        Submit
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>

            <CapexModel id='bookComplaint' />
            <Footer />
        </div>
    )
}

export default BookYourComplaint