import React, { useEffect, useState } from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/services.css";
import CapexModel from '../Model/CapexModel';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
// import { CategoryData } from '../../../Redux/actions/action';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { ServiceDetails } from '../../../Redux/actions/action';
import CapexSolarProjectForm from './CapexSolarProjectForm';
const CapexSolarProject = () => {
    const [captchaInput, setCaptchaInput] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const host = process.env.REACT_APP_API_SERVER
    const { name } = useParams();
    const dispatch = useDispatch();
    const {
        loadingServiceDetailsApi,
        findServiceDetails,
        findServiceDetailsError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(ServiceDetails(name));
    }, [name]);
    useEffect(() => {
        loadCaptchaEnginge(6); // Initialize the CAPTCHA with 6 characters
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateCaptcha(captchaInput)) {
            alert('Captcha Matched');
        } else {
            alert('Captcha Does Not Match. Please try again.');
        }
    };
    return (
        <div className='capex-solar-project'>
            <Header />
            <>
                {/* ==========banner=========== */}
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-caPex" style={{background:`url(${host}/${findServiceDetails?.banner})`}}>
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h2>
                                               {findServiceDetails?.heading}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                {/* ==========Our Solar Services=========== */}
                <section className="padding-b-t-50 bg_images_inverters_series">
                    <div className="container">
                        <div className="row padding-b-t-20 flex_dir-xs align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="main_detail_services">
                                    <h2 className="pb-1">
                                      {findServiceDetails?.name}
                                    </h2>
                                    <p className="pb-3">
                                      {
                                        parse(`${findServiceDetails?.description}`)
                                      }
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="srvices_images s-right b-right">
                                    <img src={`${host}/${findServiceDetails?.image}`} alt="savices" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="main_detail_services list_style_auto">
                                    {parse(`${findServiceDetails?.key_points}`)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-50">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="contact-form p-5">
                                <div className="main_detail_services">
                                    <h2 className="pb-4">
                                        Apply for {findServiceDetails?.name} <span> Solar </span> Projects
                                    </h2>
                                </div>
                              <CapexSolarProjectForm enquiry_for="SERVICE" product_service_id={findServiceDetails?.id}/>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            <Footer />
            <CapexModel id="exampleModal" />
        </div>
    )
}

export default CapexSolarProject