import React from 'react'
import "../Scss/OnGridSolar.css";
import Header from '../../../../Common/Header/Header';
import Footer from '../../../../Common/Footer/Footer';
import { Link } from 'react-router-dom';
const InverterProductList = () => {
    return (
        <div className='inverter-list-section'>
        <Header/>   
            <>
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-Inverter">
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2>Inverter Power Solution </h2>
                                            <p># BEAT THE ELECTRICITY BILL </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-50 bg_images_inverters_series">
                    <div className="container">
                        <div className="row inverters_series_bg align-items-center">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images s-left product_img_swc">
                                    <img
                                        src="/assets/images/inverters_series/inverters_series.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Inverters Series 1</h4>
                                    <p className="pb-3">
                                        Batteries&nbsp;consist of two electrical terminals called the
                                        cathode and the anode, separated by a chemical material called an
                                        electrolyte.
                                    </p>
                                    <p className="pb-5">
                                        To accept and release energy, a battery is coupled to an external
                                        circuit.
                                    </p>
                                    <Link to="/inverters-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row inverters_series_bg align-items-center">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images s-left product_img_swc">
                                    <img
                                        src="/assets/images/inverters_series/inverters_series.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Inverters Series 2</h4>
                                    <p className="pb-3">
                                        Batteries&nbsp;consist of two electrical terminals called the
                                        cathode and the anode, separated by a chemical material called an
                                        electrolyte.
                                    </p>
                                    <p className="pb-5">
                                        To accept and release energy, a battery is coupled to an external
                                        circuit.
                                    </p>
                                       <Link to="/inverters-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row inverters_series_bg align-items-center">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images s-left product_img_swc">
                                    <img
                                        src="/assets/images/inverters_series/inverters_series.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Inverters Series 2</h4>
                                    <p className="pb-3">
                                        Batteries&nbsp;consist of two electrical terminals called the
                                        cathode and the anode, separated by a chemical material called an
                                        electrolyte.
                                    </p>
                                    <p className="pb-5">
                                        To accept and release energy, a battery is coupled to an external
                                        circuit.
                                    </p>
                                       <Link to="/inverters-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        <Footer/>
        </div>
    )
}

export default InverterProductList