import React from 'react';
import "./Scss/Home.css";
import parse from 'html-react-parser';
const Whychooseus = ({ host, WhychooseUs, whychooseusHeading }) => {
    return (
        <>
            <div className="alcorn-home-why-choose-us">
                <section className="padding-b-t-10 bg_why_choose">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main_why_choose_us">
                                    <h4>Why Choose Us</h4>
                                    <h2>
                                        {whychooseusHeading}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2 justify-content-center">
                            {
                                WhychooseUs?.map((value, index) => {
                                    return (

                                        <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                                            <div className="por_detail_section">
                                                <span className='chooseUsLogo'>
                                                   
                                                    <img src={`${host}/${value?.logo}`} alt="" />
                                                </span>
                                                <h3>{value?.title}</h3>
                                                <p className='data-scroll'>
                                                   {parse(`${value?.description}`)}
                                                </p>
                                                <div className="wrap-decription">
                                                    <img src={`${host}/${value?.bg_img}`} alt="icon" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                       
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Whychooseus