import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery';
import * as Yup from "yup";
import toast, { Toaster } from 'react-hot-toast';
// import 'intl-tel-input/build/css/intlTelInput.css';
// import intlTelInput from 'intl-tel-input';
import "../DesignSolarProduct/Scss/DesignSolarProduct.css"
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CapexModel from '../Model/CapexModel';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useFormik } from "formik";
import { BookComplaint, InstallationHelp } from '../../../Redux/actions/action';
import { installationSchema } from '../../../validationSchema/Index';
// import { installationSchema } from '../../../validationSchema';
import CancelIcon from '@mui/icons-material/Cancel';
const Installation = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const inputRef = useRef(null);
    const formik = useFormik({
        initialValues: {
            fname: "",
            lname: "",
            email: "",
            mobile: "",
            address: "",
            product_name_model: "",
            serial_number: "",
            purchase_date: new Date(),
            installation_information: "",
            invoice: null,
        },
        validationSchema: installationSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsSubmitting(true);
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            try {
                const response = await axios.post(
                    `${host}/web/help-support/add-installation-help`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                if (response.data.code === 200) {
                    toast.success(response?.data?.msg);
                    resetForm();
                    setInvoice(null);

                }
            } catch (error) {
                toast.error(error);
                console.error("Error:", error.response ? error.response.data : error.message);
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });

    const handleImageInvoice = (event, formik) => {
        const file = event.target.files[0];
        formik.setFieldValue("invoice", file);
        setInvoice(file);
    };

    const handleRemoveInvoice = () => {
        formik.setFieldValue("invoice", null);
        setInvoice(null);
    };

    // useEffect(() => {
    //     const input = inputRef.current;

    //     const iti = intlTelInput(input, {
    //         initialCountry: "in",
    //         separateDialCode: true,
    //     });

    //     // Clean up function to destroy the plugin when the component unmounts
    //     return () => {
    //         if (iti) {
    //             iti.destroy();
    //         }
    //     };
    // }, []);
    const host = process.env.REACT_APP_API_SERVER
    const dispatch = useDispatch();
    const {
        loadingInstallationHelpApi,
        findInstallationHelp,
        findInstallationHelpError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(InstallationHelp());
    }, []);

    return (
        <div className="design-solar-product">
            <Toaster />
            <Header />
            <>
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-srvices" style={{ background: `url(${host}/${findInstallationHelp?.banner})` }}>
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className='text-capitalize'>{findInstallationHelp?.title}</h2>
                                            <p>
                                                {parse(`${findInstallationHelp?.subtitle}`)} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                <section className="padding-b-t-50 bg_images_se">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main_why_choose_us services_payra pb-3">
                                    <h2 className="pb-3 text-capitalize text-center">
                                        {findInstallationHelp?.heading}
                                    </h2>
                                    <p className="pb-3 text-center">
                                        {parse(`${findInstallationHelp?.description}`)}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <form onSubmit={formik.handleSubmit} >
                                    <div className="card-fans-details">
                                        <div className="customize-header">Contact Information</div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>First Name </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter First Name "
                                                            name="fname"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.fname}
                                                        />
                                                        {formik.touched.fname && formik.errors.fname ? (
                                                            <div className="error">{formik.errors.fname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Last Name </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Last Name"
                                                            name="lname"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.lname}
                                                        />
                                                        {formik.touched.lname && formik.errors.lname ? (
                                                            <div className="error">{formik.errors.lname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Email Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Email Address "
                                                            name="email"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.email}
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className="error">{formik.errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Phone Number</label>
                                                        <div className="form-group custom-mobile-code">
                                                            <input
                                                                type="text"
                                                                // id="mobile_code"
                                                                // ref={inputRef}
                                                                className="form-control main_detail_numbar w-100"
                                                                placeholder="Phone Number"
                                                                name="mobile"
                                                                inputMode="numeric"
                                                                pattern="[0-9]*"
                                                                onChange={(e) => {
                                                                    // Allow only digits
                                                                    const re = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        formik.handleChange(e);
                                                                    }
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.mobile}

                                                            />
                                                            {formik.touched.mobile && formik.errors.mobile ? (
                                                                <div className="error">{formik.errors.mobile}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Address</label>
                                                        <textarea
                                                            type="text"
                                                            name="address"
                                                            placeholder="Enter Address  "
                                                            className="form-control form-info-technology"
                                                            required=""
                                                            defaultValue={""}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.address}
                                                        />
                                                        {formik.touched.address && formik.errors.address ? (
                                                            <div className="error">{formik.errors.address}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-fans-details">
                                        <div className="customize-header">Products Information</div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Product Name/Model </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Product Name/Model: "
                                                            name="product_name_model"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.product_name_model}
                                                        />
                                                        {formik.touched.product_name_model && formik.errors.product_name_model ? (
                                                            <div className="error">{formik.errors.product_name_model}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Serial Number</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Serial Number "
                                                            name="serial_number"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.serial_number}
                                                        />
                                                        {formik.touched.serial_number && formik.errors.serial_number ? (
                                                            <div className="error">{formik.errors.serial_number}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Date of Purchase</label>
                                                        <div className="input-group-calecer">
                                                            <div className="custom-date-picker">
                                                                <DatePicker selected={formik.values.purchase_date}
                                                                    onChange={(date) => formik.setFieldValue("purchase_date", date)}
                                                                    onBlur={formik.handleBlur}
                                                                    name="purchase_date" />
                                                                <CalendarMonthIcon />
                                                            </div>
                                                            {formik.touched.purchase_date && formik.errors.purchase_date ? (
                                                                <div className="error">{formik.errors.purchase_date}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Installation Information</label>
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="installation_information"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.installation_information}
                                                                placeholder='Enter Installation Information'
                                                            />
                                                            {formik.touched.installation_information && formik.errors.installation_information ? (
                                                                <div className="error">{formik.errors.installation_information}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-fans-details">
                                        <div className="customize-header">
                                            Documents upload (if applicable)
                                        </div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label htmlFor="#">Utility Bill/Invoice:</label>
                                                        <div className="file-input">
                                                            <input type="file"
                                                                name="invoice"
                                                                id="invoice"
                                                                onChange={(event) => handleImageInvoice(event, formik)}
                                                            />
                                                            <button className="folder_main_bro label_1">
                                                                Upload
                                                                <span>
                                                                    <img src="/assets/images/bx_image-add.png" alt="file" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                        {invoice && (
                                                            <div className="border border-[#cac8c8] rounded-md d-flex justify-center items-center relative multiple-img-box">
                                                                <img

                                                                    className="object-contain h-[100px] w-[100px]"
                                                                    src={URL.createObjectURL(invoice)}
                                                                    alt="Selected"
                                                                />
                                                                <CancelIcon
                                                                    className="top-[6px] right-[6px] absolute-img"
                                                                    onClick={handleRemoveInvoice}
                                                                />
                                                            </div>
                                                        )}
                                                        {formik.touched.invoice && formik.errors.invoice ? (
                                                            <div className="error">{formik.errors.invoice}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="pull-left pb-4">
                                                    <div className='d-flex justify-content-start '>
                                                        <input type="checkbox" name="" className='me-1'/> *
                                                        </div>
                                                        <label
                                                            htmlFor=""
                                                            className="inline-block pointer fz15 white-color ms-2"
                                                        >
                                                            I agree to be contacted by Alcorn India regarding my
                                                            solar installation inquiry.
                                                        </label>
                                                    </div>
                                                    <button type="submit" disabled={isSubmitting} className="main_input_detail_button">
                                                        {isSubmitting ? <i className="fa fa-spinner fa-spin" /> : "Submit"}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>

            <Footer />
            <CapexModel id="installation" />
        </div>
    )
}

export default Installation