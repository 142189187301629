import React, { useEffect } from 'react'
import "../Scss/Products.css";
import Header from '../../../Common/Header/Header';
import Footer from '../../../Common/Footer/Footer';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { AllSubCategory } from '../../../../Redux/actions/action';

const OnGridSolar = () => {
  const host = process.env.REACT_APP_API_SERVER
  const { name } = useParams();
  const dispatch = useDispatch();
  const {
    findAllSubCategoryData,
    findAllSubCategoryDataError,
    loadingAllSubCategoryApi,
  } = useSelector(state => state?.reducer);
  useEffect(() => {
    dispatch(AllSubCategory(name));
  }, [name]);
  return (
    <>
      <div className="on-grid">

        <Header />
        <>
          {/* ==========banner=========== */}
          <section>
            <div className="slider-wrapper">
              <div className="slider-items-on-grid" style={{ background: `url(${host}/${findAllSubCategoryData?.banner})` }} >
                <div className="banner_detail_text">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2>
                          {findAllSubCategoryData?.bannertitle}
                        </h2>
                        <p>{findAllSubCategoryData?.bannersubtitle ? parse(`${findAllSubCategoryData?.bannersubtitle}`) : "No data available"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ==========banner=========== */}
          {/* ==========Our Solar product=========== */}
          <section className="padding-b-t-50 bg_images_on-grid">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="main_why_choose_us services_payra pb-3">
                    <h2 className='text-center'>
                      {findAllSubCategoryData?.heading}
                      {/* On-Grid <span> Solar </span> Power Solutions{" "} */}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row flex_dir-xs align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="main_detail_services product_text_detail">
                    <p>
                      {findAllSubCategoryData?.description ? parse(`${findAllSubCategoryData?.description}`) : "No data available"}
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </section>
          {
            findAllSubCategoryData?.Prouduct_detail?.map((value, index) => {
              const isOdd = index % 2 !== 0;
              // Determine the class to add
              const sectionClass = isOdd ? "bg_images_product" : " bg_images_product_next";
              const imgRight = isOdd ? "s-left" : "  s-right";
              return (
                < section key={index} className={`padding-b-t-50  ${sectionClass} no-reverse`}>
                  <div className="container">
                    <div className="row flex_dir-xs align-items-center">
                      <div className="col-lg-6 col-md-6">
                        <div className={`srvices_images ${imgRight} product_img_swc`}>
                          <img src={`${host}/${value?.productImg[0]?.image}`} alt="Products" style={{ objectFit: "cover" }} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="main_detail_services inveter_detail_new product_text_detail">
                          <h4 className="pb-3">{value?.name}</h4>
                          <p className="pb-3">
                            {parse(`${value?.description}`)}
                          </p>
                          <Link to={`/inverters-product-details/${value?.slug}`}>View Product</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            })
          }
          {/* ==========Our Solar product=========== */}
        </>

        <Footer />
      </div >
    </>
  )
}

export default OnGridSolar