import React, { useEffect, useState } from 'react'
import 'intl-tel-input/build/css/intlTelInput.css';
import CapexModel from '../Model/CapexModel';
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { ExtendedWarrantyDetails } from '../../../Redux/actions/action';
import { useFormik } from "formik";
import axios from "axios";
import { boolean } from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import toast, { Toaster } from 'react-hot-toast';
import { extendedWarranty } from '../../../validationSchema/Index';
const ExtendedWarranty = () => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const host = process.env.REACT_APP_API_SERVER
    const dispatch = useDispatch();
    const {
        loadingExtendedWarrantyApi,
        findExtendedWarranty,
        findExtendedWarrantyError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(ExtendedWarrantyDetails());
    }, []);

    const formik = useFormik({
        initialValues: {
            product_name_model: "",
            serial_no: "",
            manufacturer: "",
            standard_warranty_period: "",
            extended_warranty_option: false, // Boolean should be false, not 'boolean'
            invoice: null,
            fname: "",
            lname: "",
            email: "",
            mobile: "",
            address: "",
            additional_note: "",
            product_images: [], // Initialize as an array
        },
        validationSchema: extendedWarranty,
        onSubmit: async (values, { resetForm }) => {
            setIsSubmitting(true);
            const formData = new FormData();

            // Append each file individually
            values.product_images.forEach((file, index) => {
                formData.append(`product_images`, file);
            });

            // Append other fields
            Object.keys(values).forEach((key) => {
                if (key !== 'product_images') { // Skip product_images as it is already appended
                    formData.append(key, values[key]);
                }
            });

            try {
                const response = await axios.post(
                    `${host}/web/help-support/add-extended-warranty`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response?.data?.code === 200) {
                    toast.success(response?.data?.msg);
                    resetForm();
                    setSelectedImages([]);
                    setInvoice(null);
                } else {
                    toast.error(response?.data?.msg);
                }
            } catch (error) {
                const errorMessage = error.response ? error.response.data : error.message;
                toast.error(errorMessage);
                console.error("Error:", errorMessage);
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });
    const handleImageUpload = (event, formik) => {
        const files = Array.from(event.target.files);
        formik.setFieldValue("product_images", files);
        setSelectedImages((prevImages) => [...prevImages, ...files]);
    };
    const handleRemoveImage = (index) => {
        const updatedImages = selectedImages.filter((_, i) => i !== index);
        formik.setFieldValue("product_images", updatedImages);
        setSelectedImages(updatedImages);
    };

    const handleImageInvoice = (event, formik) => {
        const file = event.target.files[0];
        formik.setFieldValue("invoice", file);
        setInvoice(file);
    };
    const handleRemoveInvoice = () => {
        formik.setFieldValue("invoice", null);
        setInvoice(null);
    };
    return (
        <>
            <Toaster />
            <div className='design-solar-product'> <Header />
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-srvices">
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className='text-capitalize'>{findExtendedWarranty?.title}</h2>
                                            <p>
                                                {parse(`${findExtendedWarranty?.subtitle}`)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                <section className="padding-b-t-50 bg_images_se">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main_why_choose_us services_payra pb-3">
                                    <h2 className="pb-3 text-capitalize text-center">{findExtendedWarranty?.heading}</h2>
                                    <p className="pb-3 text-center">
                                        {parse(`${findExtendedWarranty?.description}`)}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="card-fans-details">
                                        <div className="customize-header">Products Information</div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Product Name/Model</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Product Name/Model"
                                                            name="product_name_model"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.product_name_model}
                                                        />
                                                        {formik.touched.product_name_model && formik.errors.product_name_model ? (
                                                            <div className="error">{formik.errors.product_name_model}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Serial Number</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Serial Number "
                                                            name="serial_no"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.serial_no}
                                                        />
                                                        {formik.touched.serial_no && formik.errors.serial_no ? (
                                                            <div className="error">{formik.errors.serial_no}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Manufacturer</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Manufacturer "
                                                            name="manufacturer"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.manufacturer}
                                                        />
                                                        {formik.touched.manufacturer && formik.errors.manufacturer ? (
                                                            <div className="error">{formik.errors.manufacturer}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Upload multiple Product Images</label>

                                                        <div className="file-input">
                                                            <input
                                                                type="file"
                                                                name="product_images"
                                                                id="product_images"
                                                                onChange={(event) => handleImageUpload(event, formik)}
                                                                multiple // Allow multiple file selection
                                                            />
                                                            <button className="folder_main_bro label_1">
                                                                Upload
                                                                <span>
                                                                    <img src="/assets/images/bx_image-add.png" alt="file" />
                                                                </span>
                                                            </button>
                                                        </div>

                                                        {selectedImages?.length > 0 && (
                                                            <div className="image-preview-container d-flex justify-content-start gap-2">
                                                                {selectedImages.map((image, index) => (
                                                                    <div key={index} className="border border-[#cac8c8] rounded-md d-flex justify-center items-center relative multiple-img-box">
                                                                        <img
                                                                            className="object-contain h-[100px] w-[100px]"
                                                                            src={URL.createObjectURL(image)}
                                                                            alt={`Selected ${index}`}
                                                                        />
                                                                        <CancelIcon
                                                                            className="top-[6px] right-[6px] absolute-img"
                                                                            onClick={() => handleRemoveImage(index)}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        {formik.touched.product_images && formik.errors.product_images ? (
                                                            <div className="error">{formik.errors.product_images}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-fans-details">
                                        <div className="customize-header">Warranty Coverage</div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Standard Warranty Period </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Warranty Period "
                                                            name="standard_warranty_period"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.standard_warranty_period}
                                                        />
                                                        {formik.touched.standard_warranty_period && formik.errors.standard_warranty_period ? (
                                                            <div className="error">{formik.errors.standard_warranty_period}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Extended Warranty Option </label>
                                                        <div className="main_extended_option">
                                                            <div className="form-check-new">
                                                                <input
                                                                    className="form-check-input-new"
                                                                    type="radio"
                                                                    name="extended_warranty_option"
                                                                    id="warrantyYes"
                                                                    value={true}
                                                                    checked={formik.values.extended_warranty_option === true}
                                                                    onChange={() => formik.setFieldValue("extended_warranty_option", true)}
                                                                />
                                                                <label
                                                                    className="form-check-label-new"
                                                                    htmlFor="warrantyYes"
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check-new">
                                                                <input
                                                                    className="form-check-input-new"
                                                                    type="radio"
                                                                    name="extended_warranty_option"
                                                                    id="warrantyNo"
                                                                    value={false}
                                                                    checked={formik.values.extended_warranty_option === false}
                                                                    onChange={() => formik.setFieldValue("extended_warranty_option", false)}
                                                                />
                                                                <label
                                                                    className="form-check-label-new"
                                                                    htmlFor="warrantyNo"
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-fans-details">
                                        <div className="customize-header">
                                            Documents upload (if applicable)
                                        </div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Upload Invoice </label>
                                                        <div className="file-input">
                                                            <input type="file"
                                                                name="invoice"
                                                                id="invoice"
                                                                onChange={(event) => handleImageInvoice(event, formik)}
                                                            />
                                                            <button className="folder_main_bro label_1">
                                                                Upload
                                                                <span>
                                                                    <img src="/assets/images/bx_image-add.png" alt="file" />
                                                                </span>
                                                            </button>
                                                        </div>

                                                        {invoice && (
                                                            <div className="border border-[#cac8c8] rounded-md d-flex justify-center items-center relative multiple-img-box">
                                                                <img

                                                                    className="object-contain h-[100px] w-[100px]"
                                                                    src={URL.createObjectURL(invoice)}
                                                                    alt="Selected"
                                                                />
                                                                <CancelIcon
                                                                    className="top-[6px] right-[6px] absolute-img"
                                                                    onClick={handleRemoveInvoice}
                                                                />
                                                            </div>
                                                        )}
                                                        {formik.touched.invoice && formik.errors.invoice ? (
                                                            <div className="error">{formik.errors.invoice}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-fans-details">
                                        <div className="customize-header">Contact Information</div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>First Name </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter First Name "
                                                            name="fname"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.fname}
                                                        />
                                                        {formik.touched.fname && formik.errors.fname ? (
                                                            <div className="error">{formik.errors.fname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Last Name </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Last Name"
                                                            name="lname"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.lname}
                                                        />
                                                        {formik.touched.lname && formik.errors.lname ? (
                                                            <div className="error">{formik.errors.lname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Email Address</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Enter Email Address "
                                                            name="email"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.email}
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className="error">{formik.errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Phone Number</label>
                                                        <div className="form-group custom-mobile-code">
                                                            <input
                                                                type="tel"
                                                                id="mobile_code"
                                                                className="form-control main_detail_numbar w-100"
                                                                placeholder="Phone Number"
                                                                name="mobile"
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.mobile}
                                                                inputMode="numeric"
                                                                pattern="[0-9]*"
                                                                onChange={(e) => {
                                                                    // Allow only digits
                                                                    const re = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        formik.handleChange(e);
                                                                    }
                                                                }}
                                                            />
                                                            {formik.touched.mobile && formik.errors.mobile ? (
                                                                <div className="error">{formik.errors.mobile}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Address</label>
                                                        <textarea
                                                            name="address"
                                                            placeholder="Enter Address"
                                                            className="form-control form-info-technology"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.address}
                                                        />
                                                        {formik.touched.address && formik.errors.address ? (
                                                            <div className="error">{formik.errors.address}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-fans-details">
                                        <div className="customize-header">Additional Information</div>
                                        <div className="main_detail_persanal">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="main_input_detail pb-3">
                                                        <label>Additional Notes</label>
                                                        <textarea
                                                            name="additional_note"
                                                            placeholder="Describe the Additional Notes..."
                                                            className="form-control-technology"
                                                            // required
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.additional_note}
                                                        />
                                                        {formik.touched.additional_note && formik.errors.additional_note ? (
                                                            <div className="error">{formik.errors.additional_note}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="pull-left pb-4">
                                                    <div className='d-flex justify-content-start '>

                                                        <input type="checkbox" name="" className='me-1'/>*
                                                        </div>
                                                        <label
                                                            htmlFor=""
                                                            className="inline-block pointer fz15 white-color "
                                                        >
                                                            I agree to be contacted by Alcorn India regarding my
                                                            solar installation inquiry.
                                                        </label>
                                                    </div>
                                                    <button type="submit" disabled={isSubmitting} className="main_input_detail_button">
                                                        {isSubmitting ? <i className="fa fa-spinner fa-spin" /> : "Submit"}
                                                    </button>
                                                    {/* <button
                                                        type="submit"
                                                     
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#extendedWarranty"
                                                    >
                                                        Submit
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <CapexModel id="extendedWarranty" />
            </div>

        </>
    )
}

export default ExtendedWarranty