import React from 'react'
import Services from './Services'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';
const Product = ({findHomePageDataData,host}) => {
    return (
        <>
            <div className="alcorn-home-product">
                <section className=" main_Product_det ">
                    <div className="span_bg_section">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/bg_img.png`} alt="" />
                    </div>
                    <div className="padding-b-t-20 main_Product_bg pt-5 pb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <h6 className="main_Product_icon">
                                        <span>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/fav_icon.png`} alt="who" />
                                        </span>
                                        Our Product
                                    </h6>
                                </div>
                                <div className="col-md-6">
                                    <div className="header_text_main">
                                        <h2>
                                           {findHomePageDataData?.our_products_line1}
                                            <span>{findHomePageDataData?.our_products_line2}</span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="p_text_detail">
                                        <p className='text-right'>
                                           {parse(`${findHomePageDataData?.our_products_description}`)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4 pb-5 justify-content-space-evenly">
                                {
                                    findHomePageDataData?.product_category?.map((value,index)=>{
                                        return(

                                <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="bg_best_product bg_best_product_1">
                                        <svg
                                            width={92}
                                            height={92}
                                            viewBox="0 0 92 92"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M62.445 9.58331H30.7318C26.3465 9.58331 24.1538 9.58331 22.5247 10.7065C20.8917 11.8335 20.2477 13.7885 18.9597 17.7023L10.1123 44.5356C7.86985 51.3436 6.74668 54.7476 8.59435 57.0821C10.442 59.4166 14.2562 59.4166 21.8883 59.4166H53.6015C57.9868 59.4166 60.1757 59.4166 61.8087 58.2935C63.4417 57.1665 64.0857 55.2115 65.3737 51.2976L74.221 24.4643C76.4635 17.6563 77.5867 14.2523 75.739 11.9178C73.8913 9.58331 70.0772 9.58331 62.445 9.58331Z"
                                                stroke="#036405"
                                                strokeWidth={5}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M84.3333 23L72.6723 58.3165C70.9703 63.4647 70.1193 66.0407 67.9688 67.5165C65.8222 69 62.928 69 57.1473 69H15.3333M17.25 26.8333H72.8333M67.0833 42.1667H11.5M42.1667 70.9167V82.4167M42.1667 82.4167H49.8333M42.1667 82.4167H34.5"
                                                stroke="#036405"
                                                strokeWidth={5}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M59.4167 9.58331H40.25L24.9167 59.4166H44.0834L59.4167 9.58331Z"
                                                stroke="#036405"
                                                strokeWidth={5}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <h3>{value?.name}</h3>
                                        <p>
                                           {value?.subtitle}
                                        </p>
                                        <Link to={`/products/${value?.slug}`}>
                                            Read More <i className="fa-solid fa-arrow-right-long" />
                                        </Link>
                                    </div>
                                </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <Services findHomePageDataData={findHomePageDataData} host={host}/>
                </section>

            </div>
        </>
    )
}

export default Product