import React from 'react'
import Header from '../../../../Common/Header/Header'
import Footer from '../../../../Common/Footer/Footer'

const SolarPanelProductDetails = () => {
    return (
        <div className="inverter-product-details">
            <Header />
            <>
                {/* ==========Our Solar product=========== */}
                <section className="padding-b-t-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-lg-5">
                                <div className="tab-content bg-light" id="nav-tabContent">
                                    <div
                                        className="tab-pane fade active show"
                                        id="nav-all-orders"
                                        role="tabpanel"
                                        aria-labelledby="nav-all-orders-tab"
                                    >
                                        <div className="main_view_detail">
                                            <img
                                                src="/assets/images/Inverters-Product/detail_main_3.png"
                                                alt="rect"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="nav-delivered"
                                        role="tabpanel"
                                        aria-labelledby="nav-delivered-tab"
                                    >
                                        <div className="main_view_detail">
                                            <img
                                                src="/assets/images/Inverters-Product/detail_main_3.png"
                                                alt="rect"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="nav-pending"
                                        role="tabpanel"
                                        aria-labelledby="nav-pending-tab"
                                    >
                                        <div className="main_view_detail">
                                            <img
                                                src="/assets/images/Inverters-Product/detail_main_3.png"
                                                alt="rect"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <nav>
                                    <div
                                        className="nav nav-tabs mb-3 all_Information_main"
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        <button
                                            className="nav-link active"
                                            id="nav-all-orders-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-all-orders"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-all-orders"
                                            aria-selected="true"
                                        >
                                            <img src="/assets/images/Inverters-Product/tab_4.png" alt="" />
                                        </button>
                                        <button
                                            className="nav-link"
                                            id="nav-delivered-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-delivered"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-delivered"
                                            aria-selected="false"
                                        >
                                            <img src="/assets/images/Inverters-Product/tab_4.png" alt="" />
                                        </button>
                                        <button
                                            className="nav-link"
                                            id="nav-pending-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-pending"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-pending"
                                            aria-selected="false"
                                        >
                                            <img src="/assets/images/Inverters-Product/tab_4.png" alt="" />
                                        </button>
                                    </div>
                                </nav>
                            </div>
                            <div className="col-md-7 col-lg-7">
                                <div className="battery-product-detail-text">
                                    <h4 className="pb-3">Solar Panel</h4>
                                    <p className="pb-3">
                                        Solar panels are&nbsp;usually made from silicon, or another
                                        semiconductor material installed in a metal panel frame with a
                                        glass casing.
                                    </p>
                                    <p className="pb-5">
                                        When this material is exposed to photons of sunlight (very small
                                        packets of energy) it releases electrons and produces an electric
                                        charge.
                                    </p>
                                    <div className="pb-4 enquiry_now_button">
                                        <a href="#" className="enquiry_button">
                                            Enquiry Now
                                        </a>
                                        <a href="#" className="store_button">
                                            Store Locator
                                        </a>
                                    </div>
                                    <div className="download_now_button">
                                        <a href="#">Download Sheet</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-20">
                    <div className="main_bg_detail_inve">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-12">
                                    <div className="main_detail_inveter">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="overview-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#overview"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="overview"
                                                    aria-selected="true"
                                                >
                                                    Overview
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="specifications-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#specifications"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="specifications"
                                                    aria-selected="false"
                                                >
                                                    Specifications
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="manufacturer-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#manufacturer"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="manufacturer"
                                                    aria-selected="false"
                                                >
                                                    Manufacturer Information
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade show active detail_text_inveter"
                                        id="overview"
                                        role="tabpanel"
                                        aria-labelledby="overview-tab"
                                    >
                                        <ul>
                                            <li>Capacity - 900 VA, Maximum Bulb Load - 756 W</li>
                                            <li>Technology - Sine Wave Technology</li>
                                            <li>No of Battery Supported - One Battery (12V)</li>
                                            <li>Designed With A Dedicated Battery Enclosure</li>
                                            <li>
                                                Next Generation Revolutionary Inverter Design For Hassle-Free
                                                Battery Water Top
                                            </li>
                                            <li>
                                                Fast, Low Voltage Battery Charging Starts At 95V With Full
                                                Charging Current
                                            </li>
                                            <li>Fast Battery Charging Current - 17 Ampere</li>
                                            <li>Mains Fuse Protection In Case Of Short Circuit</li>
                                            <li>
                                                Simplified Inverter Indications With LED Lights Representing
                                                Normal (Green) And User Attention (Red) Conditions
                                            </li>
                                            <li>
                                                No Open Wires As Battery Is Encapsulated In The Inverter
                                                Making It Completely Safe
                                            </li>
                                            <li>
                                                Supports All Tubular Batteries From 150Ah - 250 Ah (Battery
                                                Needs To Be Purchased Separately)
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        className="tab-pane fade detail_text_inveter"
                                        id="specifications"
                                        role="tabpanel"
                                        aria-labelledby="specifications-tab"
                                    >
                                        <ul>
                                            <li>Capacity - 900 VA, Maximum Bulb Load - 756 W</li>
                                            <li>Technology - Sine Wave Technology</li>
                                            <li>No of Battery Supported - One Battery (12V)</li>
                                            <li>Designed With A Dedicated Battery Enclosure</li>
                                            <li>
                                                Next Generation Revolutionary Inverter Design For Hassle-Free
                                                Battery Water Top
                                            </li>
                                            <li>
                                                Fast, Low Voltage Battery Charging Starts At 95V With Full
                                                Charging Current
                                            </li>
                                            <li>Fast Battery Charging Current - 17 Ampere</li>
                                            <li>Mains Fuse Protection In Case Of Short Circuit</li>
                                            <li>
                                                Simplified Inverter Indications With LED Lights Representing
                                                Normal (Green) And User Attention (Red) Conditions
                                            </li>
                                            <li>
                                                No Open Wires As Battery Is Encapsulated In The Inverter
                                                Making It Completely Safe
                                            </li>
                                            <li>
                                                Supports All Tubular Batteries From 150Ah - 250 Ah (Battery
                                                Needs To Be Purchased Separately)
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        className="tab-pane fade detail_text_inveter"
                                        id="manufacturer"
                                        role="tabpanel"
                                        aria-labelledby="manufacturer-tab"
                                    >
                                        <ul>
                                            <li>Capacity - 900 VA, Maximum Bulb Load - 756 W</li>
                                            <li>Technology - Sine Wave Technology</li>
                                            <li>No of Battery Supported - One Battery (12V)</li>
                                            <li>Designed With A Dedicated Battery Enclosure</li>
                                            <li>
                                                Next Generation Revolutionary Inverter Design For Hassle-Free
                                                Battery Water Top
                                            </li>
                                            <li>
                                                Fast, Low Voltage Battery Charging Starts At 95V With Full
                                                Charging Current
                                            </li>
                                            <li>Fast Battery Charging Current - 17 Ampere</li>
                                            <li>Mains Fuse Protection In Case Of Short Circuit</li>
                                            <li>
                                                Simplified Inverter Indications With LED Lights Representing
                                                Normal (Green) And User Attention (Red) Conditions
                                            </li>
                                            <li>
                                                No Open Wires As Battery Is Encapsulated In The Inverter
                                                Making It Completely Safe
                                            </li>
                                            <li>
                                                Supports All Tubular Batteries From 150Ah - 250 Ah (Battery
                                                Needs To Be Purchased Separately)
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" main_border_feedback">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 border_lg_right">
                                <div className="padding-b-t-20">
                                    <div className="feedbacks_detail_section">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="over_sales_main">
                                                    <div className="main_detail_sales">
                                                        <h3>User Feedbacks</h3>
                                                    </div>
                                                    <div className="main_drop_new_week">
                                                        <select name="#" id="#">
                                                            <option value="#">Most Recent</option>
                                                            <option value="#">Most Recent</option>
                                                            <option value="#">Most Recent</option>
                                                            <option value="#">Most Recent</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-4">
                                                <div className="review-card d-flex align-items-center">
                                                    <img
                                                        src="/assets/images/Inverters-Product/user-image.png"
                                                        alt="User Image"
                                                        className="user-image rounded-circle me-3"
                                                    />
                                                    <div>
                                                        <span className="user-name fw-bold">User Name</span>
                                                    </div>
                                                </div>
                                                <div className="main_flex_div">
                                                    <div className="stars text-warning">
                                                        <i className="fa-solid fa-star" />{" "}
                                                        <i className="fa-solid fa-star" />{" "}
                                                        <i className="fa-solid fa-star" />{" "}
                                                        <i className="fa-solid fa-star" />{" "}
                                                        <i className="fa-solid fa-star" />{" "}
                                                    </div>
                                                    <span className="text_detail_best">Best Quality</span>
                                                </div>
                                                <p className="review-text mb-1">
                                                    Review in Afghanistan on 21 June 2024
                                                </p>
                                                <a href="#" className="verified-seller text-primary">
                                                    Verified seller
                                                </a>
                                            </div>
                                            <div className="col-md-12 mb-4">
                                                <div className="review-card d-flex align-items-center">
                                                    <img
                                                        src="/assets/images/Inverters-Product/user-image.png"
                                                        alt="User Image"
                                                        className="user-image rounded-circle me-3"
                                                    />
                                                    <div>
                                                        <span className="user-name fw-bold">User Name</span>
                                                    </div>
                                                </div>
                                                <div className="main_flex_div">
                                                    <div className="stars text-warning">
                                                        <i className="fa-solid fa-star" />{" "}
                                                        <i className="fa-solid fa-star" />{" "}
                                                        <i className="fa-solid fa-star" />{" "}
                                                        <i className="fa-solid fa-star" />{" "}
                                                        <i className="fa-solid fa-star" />{" "}
                                                    </div>
                                                    <span className="text_detail_best">Best Quality</span>
                                                </div>
                                                <p className="review-text mb-1">
                                                    Review in Afghanistan on 21 June 2024
                                                </p>
                                                <a href="#" className="verified-seller text-primary">
                                                    Verified seller
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="padding-b-t-20">
                                    <div className="row">
                                        <div className="col-lg-12 main_textarea_feed">
                                            <div className="over_sales_main">
                                                <div className="main_detail_sales">
                                                    <h3>Give Feedback</h3>
                                                </div>
                                            </div>
                                            <div className="main_detail_textarea">
                                                <textarea
                                                    name="question"
                                                    placeholder="Give Feedback Here...."
                                                    className="form-control"
                                                    required=""
                                                    defaultValue={""}
                                                />
                                                <a href="#">Submit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            <Footer />
        </div>
    )
}

export default SolarPanelProductDetails