import React from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/Opex.css";
import OpexContent from './OpexContent';

const Opex = () => {
    return (
        <>
            <div className="alcorn-opex-solar-project">
                <Header />
                <OpexContent />
                <Footer />
            </div>
        </>
    )
}

export default Opex