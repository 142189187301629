import React, { useEffect, useState } from 'react'
import Header from '../../../../Common/Header/Header'
import Footer from '../../../../Common/Footer/Footer'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import parse from 'html-react-parser';
import { ProductDetails } from '../../../../../Redux/actions/action';
import { useFormik } from "formik";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import toast, { Toaster } from 'react-hot-toast';
import EnquiryForm from '../../../Model/EnquiryForm';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { feedBackSchema } from '../../../../../validationSchema/Index';
const InverterProductDetails = () => {
    const [rating, setRating] = useState(0);
    const feedbacksPerPage = 2;
    const [sortOption, setSortOption] = useState("Most Recent");
    const [currentPage, setCurrentPage] = useState(1);
    const host = process.env.REACT_APP_API_SERVER
    const { name } = useParams();
    const dispatch = useDispatch();
    const {
        findProductDetailsData,
        findProductDetailsDataError,
        loadingProductDetailsApi,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(ProductDetails(name));
    }, [name]);
    const specificationArray = findProductDetailsData?.specification && JSON?.parse(findProductDetailsData?.specification);
    const formik = useFormik({
        initialValues: {
            product_slug: name,
            name: "",
            rating: rating,
            review: ""
        },
        validationSchema: feedBackSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await fetch(`${host}/web/add-product-feedback`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                });
                const responseData = await response.json();
                if (responseData?.code === 200) {
                    toast.success(responseData?.msg);
                    resetForm();
                    setRating(0)
                } else {
                    const errorResponse = await response.json();
                    toast.error(errorResponse);
                }
            } catch (error) {
                alert(error.message);
            }
        },
    });
    const handleRatingClick = (ratingValue) => {
        setRating(ratingValue);
        formik.setFieldValue("rating", ratingValue);
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const sortFeedbacks = (feedbacks, option) => {
        if (option === "Rating High to Low") {
            return [...feedbacks].sort((a, b) => b.rating - a.rating);
        } else if (option === "Rating Low to High") {
            return [...feedbacks].sort((a, b) => a.rating - b.rating);
        } else {
            return feedbacks;
        }
    };

    const sortedFeedbacks = sortFeedbacks(findProductDetailsData?.customerFeedback, sortOption);
    const indexOfFirstFeedback = (currentPage - 1) * feedbacksPerPage;
    const indexOfLastFeedback = indexOfFirstFeedback + feedbacksPerPage;
    const currentFeedbacks = sortedFeedbacks?.slice(indexOfFirstFeedback, indexOfLastFeedback);


    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);


    const SliderFor = {
        infinite:  findProductDetailsData?.productImg?.length > 1,
        slidesToShow: Math.min(findProductDetailsData?.productImg?.length, 1),
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // asNavFor: '.slider-nav',
    };
    const SliderNav = {
        infinite:  findProductDetailsData?.productImg?.length > 3,
        slidesToShow: Math.min(findProductDetailsData?.productImg?.length, 3),
        slidesToShow: 3,
        slidesToScroll: 1,
        // asNavFor: '.slider-for',
        dots: false,
        autoplay: true,
        focusOnSelect: true,
        centerPadding: '0',
        // swipeToSlide: false,
        centerMode: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    infinite:  findProductDetailsData?.productImg?.length > 3,
                    slidesToShow: Math.min(findProductDetailsData?.productImg?.length, 3),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    infinite:  findProductDetailsData?.productImg?.length > 3,
                    slidesToShow: Math.min(findProductDetailsData?.productImg?.length, 3),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    infinite:  findProductDetailsData?.productImg?.length > 2,
                    slidesToShow: Math.min(findProductDetailsData?.productImg?.length, 2),
                    slidesToScroll: 1,
                },
            },
        ],
    };
console.log(findProductDetailsData?.id,"findProductDetailsData?.id")
    return (
        <div className="inverter-product-details">
            <Header />
            <>
                <Toaster />
                <section className="padding-b-t-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-lg-5">
                                <div className="card-body">

                                    <Slider
                                        {...SliderFor}
                                        asNavFor={slider2}
                                        ref={(slider) => (setSlider1(slider))}
                                        className="slider-for img-sec"
                                    >
                                        {findProductDetailsData?.productImg?.map((data) => (
                                            <div>
                                                <img
                                                    src={data?.image ? `${host}${data?.image}` : "../images/product-details/group-fifty.png"}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>))}



                                    </Slider>
                                    <div className="slider-sec px-5">
                                        <Slider
                                            {...SliderNav}
                                            asNavFor={slider1}
                                            ref={(slider) => (setSlider2(slider))}
                                            className="row slider-nav"
                                        >
                                            {findProductDetailsData?.productImg?.map((data) => (
                                                <div className="col-xl-3 col-lg-3">
                                                    <div className="card mx-2">
                                                        <div className="card-body">
                                                            <div className="img-sec">
                                                                <img
                                                                    src={data?.image ? `${host}${data?.image}` : "../images/product-details/group-fifty-six.png"}
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>

                                {/* <div className="tab-content bg-light" id="nav-tabContent">
                                    {
                                        findProductDetailsData?.productImg?.map((value, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`tab-pane fade ${index === 0 ? "active show" : ""}`}
                                                    id={`nav-all-orders-${index}`}
                                                    role="tabpanel"
                                                    aria-labelledby={`nav-all-orders-tab-${index}`}
                                                >
                                                    <div className="main_view_detail">
                                                        <img
                                                            src={`${host}/${value?.image}`}
                                                            alt="rect"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div> */}


                                {/* <nav>


                                    <div
                                        className="nav nav-tabs mb-3 all_Information_main"
                                        id="nav-tab"
                                        role="tablist"
                                    >


                                        {
                                            findProductDetailsData?.productImg?.map((value, index) => {
                                                return (
                                                    <button
                                                        key={index}
                                                        className={`nav-link ${index === 0 ? "active" : ""}`}
                                                        id={`nav-all-orders-tab-${index}`}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={`#nav-all-orders-${index}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`nav-all-orders-${index}`}
                                                        aria-selected={index === 0 ? "true" : "false"}
                                                    >
                                                        <img src={`${host}/${value?.image}`} alt="" />
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                </nav> */}
                            </div>
                            <div className="col-md-7 col-lg-7">
                                <div className="battery-product-detail-text">
                                    <h4 className="pb-3">{findProductDetailsData?.name}</h4>
                                    <p className="pb-3">
                                        {
                                            findProductDetailsData?.description ? parse(`${findProductDetailsData?.description}`) : "No data available"
                                        }
                                    </p>
                                    <div className="pb-4 enquiry_now_button">
                                        <a data-bs-toggle="modal" data-bs-target="#EnquiryForm" className="enquiry_button">
                                            Enquiry Now
                                        </a>
                                        <Link to="/store-locator" className="store_button">
                                            Store Locator
                                        </Link>
                                    </div>
                                    <div className="download_now_button">
                                        {
                                            findProductDetailsData?.specsFile ? <a href={`${host}/${findProductDetailsData?.specsFile}`} >Download Sheet</a>
                                                : <a href="#" target='blank'>Download Sheet</a>

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-20">
                    <div className="main_bg_detail_inve">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-12">
                                    <div className="main_detail_inveter">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="overview-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#overview"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="overview"
                                                    aria-selected="true"
                                                >
                                                    Overview
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="specifications-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#specifications"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="specifications"
                                                    aria-selected="false"
                                                >
                                                    Specifications
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="manufacturer-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#manufacturer"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="manufacturer"
                                                    aria-selected="false"
                                                >
                                                    Manufacturer Information
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade show active detail_text_inveter"
                                        id="overview"
                                        role="tabpanel"
                                        aria-labelledby="overview-tab"
                                    >
                                        {
                                            parse(`${findProductDetailsData?.keyFeatures}`)
                                        }

                                    </div>
                                    <div
                                        className="tab-pane fade detail_text_inveter"
                                        id="specifications"
                                        role="tabpanel"
                                        aria-labelledby="specifications-tab"
                                    >
                                        <div className='table-responsive'>

                                            <table className="table">
                                                <thead>
                                                    <tr>

                                                        <th scope="col">Specification Title</th>
                                                        <th scope="col">Specification Value</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        specificationArray?.map((value, index) => {
                                                            return (
                                                                <tr>

                                                                    <td>{value?.title}</td>
                                                                    <td>{value?.description}</td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div
                                        className="tab-pane fade detail_text_inveter"
                                        id="manufacturer"
                                        role="tabpanel"
                                        aria-labelledby="manufacturer-tab"
                                    >
                                        {
                                            parse(`${findProductDetailsData?.usp}`)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" main_border_feedback">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 border_lg_right">
                                <div className="padding-b-t-20">
                                    <div className="feedbacks_detail_section">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="over_sales_main">
                                                    <div className="main_detail_sales">
                                                        <h3>User Feedbacks</h3>
                                                    </div>
                                                    <div className="main_drop_new_week">
                                                        <select
                                                            name="sort-options"
                                                            id="sort-options"
                                                            value={sortOption}
                                                            onChange={(e) => {
                                                                setSortOption(e.target.value);
                                                                setCurrentPage(1); // Reset to first page when sorting changes
                                                            }}
                                                        >
                                                            <option value="Most Recent">Most Recent</option>
                                                            <option value="Rating High to Low">Rating High to Low</option>
                                                            <option value="Rating Low to High">Rating Low to High</option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {currentFeedbacks?.length > 0 ? (
                                                <>
                                                    {currentFeedbacks?.map((value, index) => (
                                                        <div className="col-md-12 mb-4" key={index}>
                                                            <div className="review-card d-flex align-items-center">
                                                                <div>
                                                                    <span className="user-name fw-bold">{value?.name}</span>
                                                                </div>
                                                            </div>
                                                            <div className="main_flex_div">
                                                                <div className="stars text-warning">
                                                                    <div className="rating">
                                                                        {Array.from({ length: 5 }, (_, starIndex) => (
                                                                            <i
                                                                                key={starIndex}
                                                                                className={`fa-solid fa-star ${starIndex < value?.rating ? "filled" : "empty"}`}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className="review-text mb-1">
                                                                {value?.review}
                                                            </p>
                                                        </div>
                                                    ))}
                                                    <Stack spacing={2} className="d-flex justify-content-center">
                                                        <Pagination
                                                            count={Math.ceil(sortedFeedbacks.length / feedbacksPerPage)}
                                                            page={currentPage}
                                                            onChange={handlePageChange}
                                                            variant="outlined"
                                                            shape="rounded"
                                                        />
                                                    </Stack>
                                                </>
                                            ) : (
                                                <div className="no-data">No Data Available</div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="padding-b-t-20">
                                    <div className="row">
                                        <div className="col-lg-12 main_textarea_feed">
                                            <div className="over_sales_main">
                                                <div className="main_detail_sales">
                                                    <h3>Give Feedback</h3>
                                                </div>
                                            </div>

                                            <form onSubmit={formik.handleSubmit} className="main_detail_textarea rating">
                                                <div className="mb-3">
                                                    <div className='gap-2 d-flex justify-content-start align-items-center'>
                                                        <h5>Rating:</h5>
                                                        <div className='d-flex justify-content-start align-items-center gap-2'>
                                                            {[1, 2, 3, 4, 5].map((star) => (
                                                                <div key={star} onClick={() => handleRatingClick(star)}>
                                                                    {star <= rating ? <StarIcon /> : <StarBorderIcon />}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {formik.touched.rating && formik.errors.rating ? (
                                                        <div className="error">{formik.errors.rating}</div>
                                                    ) : null}
                                                </div>

                                                <input
                                                    name="name"
                                                    placeholder="Your Name"
                                                    className="form-control feedback-input"
                                                    required=""
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.name && formik.errors.name ? (
                                                    <div className="error ">{formik.errors.name}</div>
                                                ) : null}

                                                <textarea
                                                    name="review"
                                                    placeholder="Write your comment here.."
                                                    className="form-control mb-2"
                                                    required=""
                                                    value={formik.values.review}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.review && formik.errors.review ? (
                                                    <div className="error">{formik.errors.review}</div>
                                                ) : null}

                                                <button type="submit" className='submit-button'>Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            <EnquiryForm id="EnquiryForm" product_service_id={findProductDetailsData?.id}/>
            <Footer />
        </div>
    )
}

export default InverterProductDetails