// import { boolean } from "yup";

import { FeedbackData } from "../actions/action";

const initialState = {
    //Find Header Page
    findHeaderData: [],
    findHeaderDataError: "",
    loadingHeaderApi: false,

    //category data
    findCategoryData: [],
    findCategoryDataError: "",
    loadingCategoryApi: false,

    //subCategory data

    //all sub category data
    loadingAllSubCategoryApi: false,
    findAllSubCategoryData: [],
    findAllSubCategoryDataError: "",

    //product details page
    loadingProductDetailsApi: false,
    findProductDetailsData: [],
    findProductDetailsDataError: '',

    //download page data
    loadingDownloadPageApi: false,
    findDownloadPageData: [],
    findDownloadPageDataError: '',

    //business division data
    loadingBusinessDivisionDataApi: false,
    findBusinessDivisionData: [],
    findBusinessDivisionDataError: '',

    //get home page data
    loadingHomePageDataDataApi: false,
    findHomePageDataData: [],
    findHomePageDataDataError: '',

    //about us page data
    loadingAboutUsDataApi: false,
    findAboutUsData: [],
    findAboutUsDataError: '',

    //all service page data
    loadingAllServiceDataApi: false,
    findAllServiceData: [],
    findAllServiceDataError: '',

    //service details page data
    loadingServiceDetailsApi: false,
    findServiceDetails: [],
    findServiceDetailsError: '',

    //book complaint data
    loadingBookComplaintApi: false,
    findBookComplaint: [],
    findBookComplaintError: '',

    //product registration
    loadingProductRegistrationApi: false,
    findProductRegistration: [],
    findProductRegistrationError: '',

    //extended warranty
    loadingExtendedWarrantyApi: false,
    findExtendedWarranty: [],
    findExtendedWarrantyError: '',

    //installation help
    loadingInstallationHelpApi: false,
    findInstallationHelp: [],
    findInstallationHelpError: '',

    //header footer
    loadingHeaderFooterApi: false,
    findHeaderFooter: [],
    findHeaderFooterError: '',

    //blog details page
    loadingBlogDetailsPageApi: false,
    findBlogDetailsPage: [],
    findBlogDetailsPageError: '',

    //faq
    loadingFAQApi: false,
    findFAQ: [],
    findFAQError: '',

    //cms
    loadingCMSApi: false,
    findCMS: [],
    findCMSError: '',

    //design your solar product
    loadingDesignYourSolarProductApi: false,
    findDesignYourSolarProduct: [],
    findDesignYourSolarProductError: '',

    //odm data
    loadingOdmOemDataApi: false,
    findOdmOemData: [],
    findOdmOemDataError: '',

    //contact us data
    loadingContactUsDataApi: false,
    findContactUsData: [],
    findContactUsDataError: '',
    
    //INSTALLATION AREA
    findInstallationArea: [],

    //type of property
    findTypeOfProperty: [],

    //InstallationTimeline
    findInstallationTimeline: [],

    //SolarPanelType
    findSolarPanelType: [],

    //SolarInverterTech
    findSolarInverterTech: [],

    //HearedAboutUs
    findHearedAboutUs: [],

     //load calculator
     loadingLoadCalculatorApi: false,
     findLoadCalculator: [],
     findLoadCalculatorError: '',

     //store locator
    loadingStoreLoactorApi: false,
    findStoreLoactor: [],
    findStoreLoactorError: '',

     //store locator page
    loadingStoreLoactorPageApi: false,
    findStoreLoactorPage: [],
    findStoreLoactorPageError: '',

}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'FIND_Header_PAGE_REQUEST':
            return {
                ...state,
                loadingHeaderApi: true,
                findHeaderData: "",
                findHeaderDataError: '',
            };
        case 'FIND_Header_PAGE_SUCCESS':
            return {
                ...state,
                loadingHeaderApi: false,
                findHeaderData: action.payload,
            };
        case 'FIND_Header_PAGE_FAILURE':
            return {
                ...state,
                loadingHeaderApi: false,
                findHeaderDataError: action.payload,
            };

        //CATEGORY-DATA//
        case 'FIND_CATEGORY_DATA_PAGE_REQUEST':
            return {
                ...state,
                loadingCategoryApi: true,
                findCategoryData: "",
                findCategoryDataError: '',
            };
        case 'FIND_CATEGORY_DATA_PAGE_SUCCESS':
            return {
                ...state,
                loadingCategoryApi: false,
                findCategoryData: action.payload,
            };
        case 'FIND_CATEGORY_DATA_PAGE_FAILURE':
            return {
                ...state,
                loadingCategoryApi: false,
                findCategoryDataError: action.payload,
            };

        //SubCategory-DATA//
        case 'FIND_SUB_CATEGORY_DATA_PAGE_REQUEST':
            return {
                ...state,
                loadingSubCategoryApi: true,
                findCategoryData: "",
                findCategoryDataError: '',
            };
        case 'FIND_SUB_CATEGORY_DATA_PAGE_SUCCESS':
            return {
                ...state,
                loadingSubCategoryApi: false,
                findCategoryData: action.payload,
            };
        case 'FIND_SUB_CATEGORY_DATA_PAGE_FAILURE':
            return {
                ...state,
                loadingSubCategoryApi: false,
                findCategoryDataError: action.payload,
            };
        //SUB-CATEGORY-DATA//

        //ALL-SUB-CATEGORY-DATA//
        case 'FIND_ALL_SUB_CATEGORY_DATA_PAGE_REQUEST':
            return {
                ...state,
                loadingAllSubCategoryApi: true,
                findAllSubCategoryData: "",
                findAllSubCategoryDataError: '',
            };
        case 'FIND_ALL_SUB_CATEGORY_DATA_PAGE_SUCCESS':
            return {
                ...state,
                loadingAllSubCategoryApi: false,
                findAllSubCategoryData: action.payload,
            };
        case 'FIND_ALL_SUB_CATEGORY_DATA_PAGE_FAILURE':
            return {
                ...state,
                loadingAllCategoryApi: false,
                findAllCategoryDataError: action.payload,
            };
        //ALL-SUB-CATEGORY-DATA//

        //ALL-SUB-CATEGORY-DATA//
        case 'FIND_ALL_PRODUCT_DETAILS_PAGE_REQUEST':
            return {
                ...state,
                loadingProductDetailsApi: true,
                findProductDetailsData: "",
                findProductDetailsDataError: '',
            };
        case 'FIND_ALL_PRODUCT_DETAILS_PAGE_SUCCESS':
            return {
                ...state,
                loadingProductDetailsApi: false,
                findProductDetailsData: action.payload,
            };
        case 'FIND_ALL_PRODUCT_DETAILS_PAGE_FAILURE':
            return {
                ...state,
                loadingProductDetailsApi: false,
                findProductDetailsDataError: action.payload,
            };
        //ALL-SUB-CATEGORY-DATA//

        //ALL-SUB-CATEGORY-DATA//
        case 'FIND_DOWNLOAD_PAGE_DATA_REQUEST':
            return {
                ...state,
                loadingDownloadPageApi: true,
                findDownloadPageData: "",
                findDownloadPageDataError: '',
            };
        case 'FIND_DOWNLOAD_PAGE_DATA_SUCCESS':
            return {
                ...state,
                loadingDownloadPageApi: false,
                findDownloadPageData: action.payload,
            };
        case 'FIND_DOWNLOAD_PAGE_DATA_FAILURE':
            return {
                ...state,
                loadingDownloadPageApi: false,
                findDownloadPageDataError: action.payload,
            };
        //ALL-SUB-CATEGORY-DATA//

        //GET BUSINESS DIVISION DATA//
        case 'FIND_BUSINESS_DIVISION_DATA_REQUEST':
            return {
                ...state,
                loadingBusinessDivisionDataApi: true,
                findBusinessDivisionData: "",
                findBusinessDivisionDataError: '',
            };
        case 'FIND_BUSINESS_DIVISION_DATA_SUCCESS':
            return {
                ...state,
                loadingBusinessDivisionDataApi: false,
                findBusinessDivisionData: action.payload,
            };
        case 'FIND_BUSINESS_DIVISION_DATA_FAILURE':
            return {
                ...state,
                loadingBusinessDivisionDataApi: false,
                findBusinessDivisionDataError: action.payload,
            };
        //GET BUSINESS DIVISION DATA//

        //GET home page data//
        case 'FIND_HOME_PAGE_DATA_REQUEST':
            return {
                ...state,
                loadingHomePageDataDataApi: true,
                findHomePageDataData: "",
                findHomePageDataDataError: '',
            };
        case 'FIND_HOME_PAGE_DATA_SUCCESS':
            return {
                ...state,
                loadingHomePageDataDataApi: false,
                findHomePageDataData: action.payload,
            };
        case 'FIND_HOME_PAGE_DATA_FAILURE':
            return {
                ...state,
                loadingHomePageDataDataApi: false,
                findHomePageDataDataError: action.payload,
            };
        //GET home page data//

        //GET ABOUT US data//
        case 'FIND_ABOUT_US_DATA_REQUEST':
            return {
                ...state,
                loadingAboutUsDataApi: true,
                findAboutUsData: "",
                findAboutUsDataError: '',
            };
        case 'FIND_ABOUT_US_DATA_SUCCESS':
            return {
                ...state,
                loadingAboutUsDataApi: false,
                findAboutUsData: action.payload,
            };
        case 'FIND_ABOUT_US_DATA_FAILURE':
            return {
                ...state,
                loadingAboutUsDataApi: false,
                findAboutUsDataError: action.payload,
            };
        //GET ABOUT US data//

        //ALL SERVICE PAGE DATA//
        case 'FIND_ALL_SERVICE_DATA_REQUEST':
            return {
                ...state,
                loadingAllServiceDataApi: true,
                findAllServiceData: "",
                findAllServiceDataError: '',
            };
        case 'FIND_ALL_SERVICE_DATA_SUCCESS':
            return {
                ...state,
                loadingAllServiceDataApi: false,
                findAllServiceData: action.payload,
            };
        case 'FIND_ALL_SERVICE_DATA_FAILURE':
            return {
                ...state,
                loadingAllServiceDataApi: false,
                findAllServiceDataError: action.payload,
            };
        //ALL SERVICE PAGE DATA//

        //SERVICE DETAILS PAGE DATA//
        case 'FIND_SERVICE_DETAILS_DATA_REQUEST':
            return {
                ...state,
                loadingServiceDetailsApi: true,
                findServiceDetails: "",
                findServiceDetailsError: '',
            };
        case 'FIND_SERVICE_DETAILS_DATA_SUCCESS':
            return {
                ...state,
                loadingServiceDetailsApi: false,
                findServiceDetails: action.payload,
            };
        case 'FIND_SERVICE_DETAILS_DATA_FAILURE':
            return {
                ...state,
                loadingServiceDetailsApi: false,
                findServiceDetailsError: action.payload,
            };
        //SERVICE DETAILS PAGE DATA//

        //book complaint data//
        case 'BOOK_COMPLAINT_REQUEST':
            return {
                ...state,
                loadingBookComplaintApi: true,
                findBookComplaint: "",
                findBookComplaintError: '',
            };
        case 'BOOK_COMPLAINT_SUCCESS':
            return {
                ...state,
                loadingBookComplaintApi: false,
                findBookComplaint: action.payload,
            };
        case 'BOOK_COMPLAINT_FAILURE':
            return {
                ...state,
                loadingBookComplaintApi: false,
                findBookComplaintError: action.payload,
            };
        //book complaint data//

        //PRODUCT REGISTRATION//
        case 'PRODUCT_REGISTRATION_REQUEST':
            return {
                ...state,
                loadingProductRegistrationApi: true,
                findProductRegistration: "",
                findProductRegistrationError: '',
            };
        case 'PRODUCT_REGISTRATION_SUCCESS':
            return {
                ...state,
                loadingProductRegistrationApi: false,
                findProductRegistration: action.payload,
            };
        case 'PRODUCT_REGISTRATION_FAILURE':
            return {
                ...state,
                loadingProductRegistrationApi: false,
                findProductRegistrationError: action.payload,
            };
        //PRODUCT REGISTRATION//

        //EXTENDED WARRANTY//
        case 'EXTENDED_WARRANTY_REQUEST':
            return {
                ...state,
                loadingExtendedWarrantyApi: true,
                findExtendedWarranty: "",
                findExtendedWarrantyError: '',
            };
        case 'EXTENDED_WARRANTY_SUCCESS':
            return {
                ...state,
                loadingExtendedWarrantyApi: false,
                findExtendedWarranty: action.payload,
            };
        case 'EXTENDED_WARRANTY_FAILURE':
            return {
                ...state,
                loadingExtendedWarrantyApi: false,
                findExtendedWarrantyError: action.payload,
            };
        //EXTENDED WARRANTY//

        //installation help//
        case 'INSTALLATION_HELP_REQUEST':
            return {
                ...state,
                loadingInstallationHelpApi: true,
                findInstallationHelp: "",
                findInstallationHelpError: '',
            };
        case 'INSTALLATION_HELP_SUCCESS':
            return {
                ...state,
                loadingInstallationHelpApi: false,
                findInstallationHelp: action.payload,
            };
        case 'INSTALLATION_HELP_FAILURE':
            return {
                ...state,
                loadingInstallationHelpApi: false,
                findInstallationHelpError: action.payload,
            };
        //installation help//

        //HEADER FOOTER//
        case 'HEADER_FOOTER_REQUEST':
            return {
                ...state,
                loadingHeaderFooterApi: true,
                findHeaderFooter: "",
                findHeaderFooterError: '',
            };
        case 'HEADER_FOOTER_SUCCESS':
            return {
                ...state,
                loadingHeaderFooterApi: false,
                findHeaderFooter: action.payload,
            };
        case 'HEADER_FOOTER_FAILURE':
            return {
                ...state,
                loadingHeaderFooterApi: false,
                findHeaderFooterError: action.payload,
            };
        //HEADER FOOTER//

        //FAQ//
        case 'FAQ_REQUEST':
            return {
                ...state,
                loadingFAQApi: true,
                findFAQ: "",
                findFAQError: '',
            };
        case 'FAQ_SUCCESS':
            return {
                ...state,
                loadingFAQApi: false,
                findFAQ: action.payload,
            };
        case 'FAQ_FAILURE':
            return {
                ...state,
                loadingFAQApi: false,
                findFAQError: action.payload,
            };
        //FAQ//

        //CMS//
        case 'CMS_REQUEST':
            return {
                ...state,
                loadingCMSApi: true,
                findCMS: "",
                findCMSError: '',
            };
        case 'CMS_SUCCESS':
            return {
                ...state,
                loadingCMSApi: false,
                findCMS: action.payload,
            };
        case 'CMS_FAILURE':
            return {
                ...state,
                loadingCMSApi: false,
                findCMSError: action.payload,
            };
        //CMS//

        //BLOG DETAILS PAGE//
        case 'BLOG_DETAILS_PAGE_REQUEST':
            return {
                ...state,
                loadingBlogDetailsPageApi: true,
                findBlogDetailsPage: "",
                findBlogDetailsPageError: '',
            };
        case 'BLOG_DETAILS_PAGE_SUCCESS':
            return {
                ...state,
                loadingBlogDetailsPageApi: false,
                findBlogDetailsPage: action.payload,
            };
        case 'BLOG_DETAILS_PAGE_FAILURE':
            return {
                ...state,
                loadingBlogDetailsPageApi: false,
                findBlogDetailsPageError: action.payload,
            };
        //BLOG DETAILS PAGE//

        //DESIGN YOUR SOLAR PRODUCT//
        case 'DESIGN_YOUR_SOLAR_PRODUCT_REQUEST':
            return {
                ...state,
                loadingDesignYourSolarProductApi: true,
                findDesignYourSolarProduct: "",
                findDesignYourSolarProductError: '',
            };
        case 'DESIGN_YOUR_SOLAR_PRODUCT_SUCCESS':
            return {
                ...state,
                loadingDesignYourSolarProductApi: false,
                findDesignYourSolarProduct: action.payload,
            };
        case 'DESIGN_YOUR_SOLAR_PRODUCT_FAILURE':
            return {
                ...state,
                loadingDesignYourSolarProductApi: false,
                findDesignYourSolarProductError: action.payload,
            };
        //DESIGN YOUR SOLAR PRODUCT//

        //ODM OEM DATA//
        case 'ODM_OEM_DATA_REQUEST':
            return {
                ...state,
                loadingOdmOemDataApi: true,
                findOdmOemData: "",
                findOdmOemDataError: '',
            };
        case 'ODM_OEM_DATA_SUCCESS':
            return {
                ...state,
                loadingOdmOemDataApi: false,
                findOdmOemData: action.payload,
            };
        case 'ODM_OEM_DATA_FAILURE':
            return {
                ...state,
                loadingOdmOemDataApi: false,
                findOdmOemDataError: action.payload,
            };
        //ODM OEM DATA//

        //installation area//

        case 'INSTALLATION_AREA_SUCCESS':
            return {
                ...state,
                findInstallationArea: action.payload,
            };

        //installation area//

        //TYPE OF PROPERTY//

        case 'TYPE_OF_PROPERTY_SUCCESS':
            return {
                ...state,
                findTypeOfProperty: action.payload,
            };

        //TYPE OF PROPERTY//
        //INSTALLATION TIMELINE//

        case 'INSTALLATION_TIME_LINE_SUCCESS':
            return {
                ...state,
                findInstallationTimeline: action.payload,
            };

        //INSTALLATION TIMELINE//

        //SOLAR PANEL TYPE//

        case 'SOLAR_PANEL_TYPE_SUCCESS':
            return {
                ...state,
                findSolarPanelType: action.payload,
            };

        //SOLAR PANEL TYPE//

        //SOLAR INVERTER TECH//
        case 'SOLAR_INVERTER_TECH_SUCCESS':
            return {
                ...state,
                findSolarInverterTech: action.payload,
            };
        //SOLAR INVERTER TECH//

        //HEARED_ABOUT_US//
        case 'HEARED_ABOUT_US_SUCCESS':
            return {
                ...state,
                findHearedAboutUs: action.payload,
            };
        //HEARED_ABOUT_US//

         //CONTACT US DATA//
         case 'CONTACT_US_DATA_REQUEST':
            return {
                ...state,
                loadingContactUsDataApi: true,
                findContactUsData: "",
                findContactUsDataError: '',
            };
        case 'CONTACT_US_DATA_SUCCESS':
            return {
                ...state,
                loadingContactUsDataApi: false,
                findContactUsData: action.payload,
            };
        case 'CONTACT_US_DATA_FAILURE':
            return {
                ...state,
                loadingContactUsDataApi: false,
                findContactUsDataError: action.payload,
            };
        //CONTACT US DATA//
        //load calculator//
        case 'LOAD_CALCULATOR_REQUEST':
            return {
                ...state,
                loadingLoadCalculatorApi: true,
                findLoadCalculator: "",
                findLoadCalculatorError: '',
            };
        case 'LOAD_CALCULATOR_SUCCESS':
            return {
                ...state,
                loadingLoadCalculatorApi: false,
                findLoadCalculator: action.payload,
            };
        case 'LOAD_CALCULATOR_FAILURE':
            return {
                ...state,
                loadingLoadCalculatorApi: false,
                findLoadCalculatorError: action.payload,
            };
        //load calculator//


        //store locator//
        case 'STORE_LOCATOR_REQUEST':
            return {
                ...state,
                loadingStoreLocatorApi: true,
                findStoreLocator: "",
                findStoreLocatorError: '',
            };
        case 'STORE_LOCATOR_SUCCESS':
            return {
                ...state,
                loadingStoreLocatorApi: false,
                findStoreLocator: action.payload,
            };
        case 'STORE_LOCATOR_FAILURE':
            return {
                ...state,
                loadingStoreLocatorApi: false,
                findStoreLocatorError: action.payload,
            };
        //store locator//

        //store locator page//
        case 'STORE_LOCATOR_PAGE_REQUEST':
            return {
                ...state,
                loadingStoreLocatorPageApi: true,
                findStoreLocatorPage: "",
                findStoreLocatorPageError: '',
            };
        case 'STORE_LOCATOR_PAGE_SUCCESS':
            return {
                ...state,
                loadingStoreLocatorPageApi: false,
                findStoreLocatorPage: action.payload,
            };
        case 'STORE_LOCATOR_PAGE_FAILURE':
            return {
                ...state,
                loadingStoreLocatorPageApi: false,
                findStoreLocatorPageError: action.payload,
            };
        //store locator page//

        default:
            return state;
    }
}

export default reducer;