import React,{useEffect,useState} from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/SolarLighting.css"
import CapexModel from '../Model/CapexModel'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
const SolarLighting = () => {
    const [captchaInput, setCaptchaInput] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    useEffect(() => {
        loadCaptchaEnginge(6); // Initialize the CAPTCHA with 6 characters
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateCaptcha(captchaInput)) {
            alert('Captcha Matched');
        } else {
            alert('Captcha Does Not Match. Please try again.');
        }
    };
    return (
        <div className="solar-lighting-section">
            <Header />
            <>
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-caPex">
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h2>
                                                Solar <span>Lighting Projects</span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                {/* ==========Our Solar Services=========== */}
                <section className="padding-b-t-50 bg_images_inverters_series">
                    <div className="container">
                        <div className="row padding-b-t-20 flex_dir-xs align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="main_detail_services">
                                    <h2 className="pb-1">
                                        Solar <span> Lighting Projects </span>
                                    </h2>
                                    <p className="pb-3">
                                        Solar Lighting Projects deprecation" could refer to a decline or
                                        reduction in the implementation, popularity, or viability of
                                        solar-powered lighting projects.
                                    </p>
                                    <p className="pb-3">
                                        Solar Lighting Projects deprecation" reflects a decline in the
                                        implementation or preference for solar-powered lighting systems,
                                        influenced by technological advancements, cost considerations,
                                        regulatory changes, market dynamics, and evolving customer needs
                                        and expectations in the lighting industry.
                                    </p>
                                    <p className="pb-3">
                                        Advances in solar lighting technology, such as improvements in
                                        solar panel efficiency, battery storage capacity, and LED lighting
                                        efficiency, may lead to more cost-effective and reliable
                                        solutions. This could shift preferences towards newer technologies
                                        and away from older, less efficient systems.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="srvices_images s-right b-right">
                                    <img src="/assets/images/CapEx/Capex-detail-4.png" alt="savices" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="main_detail_services list_style_auto">
                                    <ul>
                                        <li>
                                            <b> Technological Advancements:</b> Advances in solar lighting
                                            technology, such as improvements in solar panel efficiency,
                                            battery storage capacity, and LED lighting efficiency, may lead
                                            to more cost-effective and reliable solutions. This could shift
                                            preferences towards newer technologies and away from older, less
                                            efficient systems
                                        </li>
                                        <li>
                                            <b>Cost Considerations:</b> While solar lighting projects can be
                                            cost-effective over the long term due to reduced operating costs
                                            (no electricity bills), the initial investment can be higher
                                            compared to conventional lighting systems. Changes in the cost
                                            of solar components or competition from grid-connected lighting
                                            solutions could impact the attractiveness of solar lighting
                                            projects.
                                        </li>
                                        <li>
                                            <b>Regulatory and Policy Changes:</b> Government incentives and
                                            regulations play a significant role in the adoption of solar
                                            technologies. Changes in incentives, subsidies, or regulatory
                                            frameworks may affect the financial feasibility of solar
                                            lighting projects.
                                        </li>
                                        <li>
                                            <b>Integration with Other Technologies:</b> Advances in smart
                                            lighting systems and IoT (Internet of Things) capabilities may
                                            drive preferences towards integrated lighting solutions that
                                            combine energy efficiency with advanced control and monitoring
                                            capabilities. In summary, "Solar Lighting Projects deprecation"
                                            reflects a decline in the implementation or preference for
                                            solar-powered lighting systems, influenced by technological
                                            advancements, cost considerations.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-50 pt-0">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="contact-form p-5">
                                <div className="main_detail_services">
                                    <h2 className="pb-4">
                                        Apply for <span> Solar </span> Lighting Projects
                                    </h2>
                                </div>
                                <form className="input_detail_sectrion" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Your Name"
                                                    required=""
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-lg-8">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Your Email Address"
                                                    className="form-control"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    name="subject"
                                                    placeholder="Subject"
                                                    className="form-control"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <textarea
                                                    name="Message"
                                                    placeholder="Message"
                                                    className="form-control"
                                                    value={formData.Message}
                                                    onChange={handleChange}
                                                    defaultValue={""}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 custom-captcha">
                                                <LoadCanvasTemplate reloadText={"Reload"} />
                                                <input
                                                    type="text"
                                                    value={captchaInput}
                                                    onChange={(e) => setCaptchaInput(e.target.value)}
                                                    placeholder="Enter CAPTCHA"
                                                    required
                                                    className="form-control mt-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button
                                                    type="submit"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#exampleModal"
                                                >
                                                   Submit Now
                                                    <span style={{marginLeft:"12px"}}>
                                                        <i className="fa-solid fa-arrow-right-long" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>

            <Footer />
            <CapexModel id="solarLighting" />
        </div>
    )
}

export default SolarLighting