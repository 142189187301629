import React, { useEffect } from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/BusinessDivision.css";
import { useDispatch, useSelector } from 'react-redux';
import { BusinessDivisionData } from '../../../Redux/actions/action';
const BusinessDivision = () => {
    const host = process.env.REACT_APP_API_SERVER
    const dispatch = useDispatch();
    const {
        loadingBusinessDivisionDataApi,
        findBusinessDivisionData,
        findBusinessDivisionDataError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(BusinessDivisionData());
    }, []);
    return (
        <div className="business-division">
            <Header />
            <>
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-Bussiness" style={{ background: `${findBusinessDivisionData?.banner ? `url(${host}/${findBusinessDivisionData?.banner})` : `url(/assets/images/CapEx/Bussiness-Banner.png)`}` }} >
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h2>{findBusinessDivisionData?.heading}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                {/* ==========Our Solar product=========== */}
                {
                    findBusinessDivisionData?.list?.map((value, index) => {
                        const isOdd = index % 2 !== 0;
                        // Determine the class to add
                        const sectionClass = isOdd ? "bg_images_product_next" : "bg_images_product";
                        const imgRight = isOdd ? "s-left" : "s-right";
                        return (

                            <section className={`padding-b-t-50  ${sectionClass}`}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="main_why_choose_us services_payra pb-5">
                                                <h2 className='text-center'>{value?.title}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row flex_dir-xs align-items-center">
                                        <div className=" col-md-6">
                                            <div className="main_detail_services product_text_detail">
                                                <p className="pb-3">
                                                    {value?.description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className={`srvices_images ${imgRight} product_img_swc`}>
                                                <img src={`${host}/${value?.image}`} alt="Products" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    })
                }
              
            </>

            <Footer />
        </div>
    )
}

export default BusinessDivision