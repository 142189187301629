import React, { useEffect } from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/Products.css";
import { Link, useParams } from 'react-router-dom';
import { CategoryData } from '../../../Redux/actions/action';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
const AllProducts = () => {
    const host = process.env.REACT_APP_API_SERVER
    const { name } = useParams();
    const dispatch = useDispatch();
    const {
        findCategoryData,
        findCategoryDataError,
        loadingCategoryApi,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(CategoryData(name, host));
    }, [name, host]);
    return (
        <>
            <div className="on-grid">
                <Header />
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-products"
                            style={{ background: `url(${host}/${findCategoryData?.banner})` }}
                        >
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h2>
                                                {findCategoryData?.bannertitle}
                                                {/* <span> {parse(`${findCategoryData?.subtitle}`)}</span> */}
                                                {/* Solar Power <span>Products</span> */}
                                            </h2>
                                            <p>
                                            {parse(`${findCategoryData?.subtitle}`)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                {/* ==========Our Solar product=========== */}
                {
                    findCategoryData?.SubCategory?.map((value, index) => {
                        const isOdd = index % 2 !== 0;
                        // Determine the class to add
                        const sectionClass = isOdd ? "bg_images_product_next" : "bg_images_product";
                        const imgRight = isOdd ? "s-left" : "s-right";
                        return (
                            <section className={`padding-b-t-50  ${sectionClass}`}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="main_why_choose_us services_payra pb-3 text-center">
                                                <h2>
                                                    {
                                                        value?.name
                                                    }

                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row flex_dir-xs align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="main_detail_services product_text_detail">
                                                {
                                                    value?.description ? <p className='pb-3'>
                                                        {parse(value?.description)}  </p> :
                                                        <div className='d-flex justify-content-start align-items-center' style={{ height: "300px" }}>

                                                            <p>
                                                                no data available
                                                            </p>
                                                        </div>
                                                }

                                                <Link to={`/on-grid-solar/${value?.slug}`} className=''>View All Product</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className={`srvices_images ${imgRight} product_img_swc`}>
                                                <img src={`${host}/${value?.image}`} alt="Products" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    })
                }

                {/* ==========Our Solar product=========== */}
                {/*only product */}
                {
                    findCategoryData?.Product?.map((value, index) => {
                        const isOdd = index % 2 !== 0;
                        // Determine the class to add
                        const sectionClass = isOdd ? "bg_images_product_next" : "bg_images_product";
                        const imgRight = isOdd ? "s-left" : "s-right";
                        return (
                            <section className={`padding-b-t-50  ${sectionClass}`}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="main_why_choose_us services_payra pb-3 text-center">
                                                <h2>
                                                    {
                                                        value?.name
                                                    }

                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row flex_dir-xs align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="main_detail_services product_text_detail">
                                                {
                                                    value?.description ? <p className='pb-3'>
                                                        {parse(value?.description)}  </p> :
                                                        <div className='d-flex justify-content-start align-items-center' style={{ height: "300px" }}>

                                                            <p>
                                                                no data available
                                                            </p>
                                                        </div>
                                                }

                                                <Link to={`/on-grid-solar/${value?.slug}`} className=''>View All Product</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className={`srvices_images ${imgRight} product_img_swc`}>
                                                <img src={`${host}/${value?.productImg[0]?.image}`} alt="Products" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    })
                }
                <Footer />
            </div>
        </>

    )
}

export default AllProducts