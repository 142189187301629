import React, { useEffect } from 'react'
import "./Scss/Faq.css"
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { CMSData } from '../../../Redux/actions/action';
import Loader from '../../Common/Loader/Loader';
import parse from 'html-react-parser';
const Privacy = () => {
    const dispatch = useDispatch();
    const {
        findCMS,
        findCMSError,
        loadingCMSApi,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(CMSData("privacy_policy"));
    }, []);

    if (loadingCMSApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }
    return (
        <>
            <div className="faq-section">
                <Header />
                <section className="padding-b-t-20 faq_bg_detail">
                    <div className="container my-5">
                        <div className="asked_question">
                            <h4 className="mb-4">Privacy Policy</h4>
                        </div>
                        <div className="accordion main_faq_asked" id="faqAccordion">
                            <div className="terms-privacy-div p-5">

                                {findCMS ? (
                                    findCMS.description ? (
                                        parse(`${findCMS.description}`)
                                    ) : (
                                        <div className="text-center">
                                            Privacy policy not found.
                                        </div>
                                    )
                                ) : (
                                    <div className="text-center">
                                        Privacy policy not found.
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default Privacy