import React from 'react'
import Header from '../../../../Common/Header/Header'
import Footer from '../../../../Common/Footer/Footer'
import { Link } from 'react-router-dom'

const BatteryProductList = () => {
    return (
        <div className='inverter-list-section'>
            <Header />
            <>
                {/* ==========banner=========== */}
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-Battery">
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2>Battery Power Solution</h2>
                                            <p># BEAT THE ELECTRICITY BILL </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                {/* ==========Our Solar product=========== */}
                <section className="padding-b-t-50 bg_images_inverters_series">
                    <div className="container">
                        <div className="row inverters_series_bg">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images_porduct s-left product_img_swc">
                                    <img
                                        src="/assets/images/Inverters-Product/detail_main_1.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Battery Series 1</h4>
                                    <p className="pb-3">
                                        An inverter&nbsp;converts the DC electricity from sources such as
                                        batteries or fuel cells to AC electricity
                                    </p>
                                    <p className="pb-5">
                                        The electricity can be at any required voltage; in particular it
                                        can operate AC equipment designed for mains operation, or
                                        rectified to produce DC at any desired voltage.
                                    </p>
                                    <Link to="/battery-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row inverters_series_bg">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images_porduct s-left product_img_swc">
                                    <img
                                        src="/assets/images/Inverters-Product/detail_main_1.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Battery Series 2</h4>
                                    <p className="pb-3">
                                        An inverter&nbsp;converts the DC electricity from sources such as
                                        batteries or fuel cells to AC electricity
                                    </p>
                                    <p className="pb-5">
                                        The electricity can be at any required voltage; in particular it
                                        can operate AC equipment designed for mains operation, or
                                        rectified to produce DC at any desired voltage.
                                    </p>
                                    <Link to="/battery-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row inverters_series_bg">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images_porduct s-left product_img_swc">
                                    <img
                                        src="/assets/images/Inverters-Product/detail_main_1.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Battery Series 2</h4>
                                    <p className="pb-3">
                                        An inverter&nbsp;converts the DC electricity from sources such as
                                        batteries or fuel cells to AC electricity
                                    </p>
                                    <p className="pb-5">
                                        The electricity can be at any required voltage; in particular it
                                        can operate AC equipment designed for mains operation, or
                                        rectified to produce DC at any desired voltage.
                                    </p>
                                    <Link to="/battery-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========Our Solar product=========== */}
            </>
            <Footer />
        </div>
    )
}

export default BatteryProductList