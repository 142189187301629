import React from 'react'
import "../Scss/OnGridSolar.css";
import Header from '../../../../Common/Header/Header';
import Footer from '../../../../Common/Footer/Footer';
import { Link } from 'react-router-dom';
const SolarPanelProductList = () => {
    return (
        <div className='inverter-list-section'>
            <Header />
            <>
                {/* ==========banner=========== */}
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-Solar">
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2>
                                                <span>Solar</span> Panel Power Solution
                                            </h2>
                                            <p># BEAT THE ELECTRICITY BILL </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}
                {/* ==========Our Solar product=========== */}
                <section className="padding-b-t-50 bg_images_inverters_series">
                    <div className="container">
                        <div className="row inverters_series_bg">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images_porduct s-left product_img_swc">
                                    <img
                                        src="/assets/images/Inverters-Product/detail_main_list_1.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Solar Panel Series 1</h4>
                                    <p className="pb-3">
                                        Solar panels are&nbsp;usually made from silicon, or another
                                        semiconductor material installed in a metal panel frame with a
                                        glass casing.
                                    </p>
                                    <p className="pb-5">
                                        When this material is exposed to photons of sunlight (very small
                                        packets of energy) it releases electrons and produces an electric
                                        charge.
                                    </p>
                                    <Link to="/solar-panel-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row inverters_series_bg">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images_porduct s-left product_img_swc">
                                    <img
                                        src="/assets/images/Inverters-Product/detail_main_list_1.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Solar Panel Series 2</h4>
                                    <p className="pb-3">
                                        Solar panels are&nbsp;usually made from silicon, or another
                                        semiconductor material installed in a metal panel frame with a
                                        glass casing.
                                    </p>
                                    <p className="pb-5">
                                        When this material is exposed to photons of sunlight (very small
                                        packets of energy) it releases electrons and produces an electric
                                        charge.
                                    </p>
                                    <Link to="/solar-panel-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row inverters_series_bg">
                            <div className="col-lg-5 col-md-5">
                                <div className="srvices_images_porduct s-left product_img_swc">
                                    <img
                                        src="/assets/images/Inverters-Product/detail_main_list_1.png"
                                        alt="Products"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="main_detail_services inveter_detail_new product_text_detail">
                                    <h4 className="pb-3">Solar Panel Series 2</h4>
                                    <p className="pb-3">
                                        Solar panels are&nbsp;usually made from silicon, or another
                                        semiconductor material installed in a metal panel frame with a
                                        glass casing.
                                    </p>
                                    <p className="pb-5">
                                        When this material is exposed to photons of sunlight (very small
                                        packets of energy) it releases electrons and produces an electric
                                        charge.
                                    </p>
                                    <Link to="/solar-panel-product-details">View All Product</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========Our Solar product=========== */}
            </>
            <Footer />
        </div>
    )
}

export default SolarPanelProductList