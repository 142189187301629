import React,{useEffect,useState} from 'react';
import "./Scss/ContactFormServices.css";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
const   ContactFormServices = ({content}) => {
    const [captchaInput, setCaptchaInput] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    useEffect(() => {
        loadCaptchaEnginge(6); // Initialize the CAPTCHA with 6 characters
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateCaptcha(captchaInput)) {
            alert('Captcha Matched');
        } else {
            alert('Captcha Does Not Match. Please try again.');
        }
    };
    return (
        <>
            <div className="alcorn-contact-form-services">
                <section className="padding-b-t-50 pt-3">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="contact-form p-5">
                                <div className="main_detail_services">
                                    <h2 className="pb-4">
                                        Apply for {content} <span> Solar </span> Projects
                                    </h2>
                                </div>
                                <form className="input_detail_sectrion" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Your Name"
                                                    required=""
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-lg-8">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Your Email Address"
                                                    className="form-control"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    name="subject"
                                                    placeholder="Subject"
                                                    className="form-control"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <textarea
                                                    name="Message"
                                                    placeholder="Message"
                                                    className="form-control"
                                                    value={formData.Message}
                                                    onChange={handleChange}
                                                    defaultValue={""}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 custom-captcha">
                                                <LoadCanvasTemplate reloadText={"Reload"} />
                                                <input
                                                    type="text"
                                                    value={captchaInput}
                                                    onChange={(e) => setCaptchaInput(e.target.value)}
                                                    placeholder="Enter CAPTCHA"
                                                    required
                                                    className="form-control mt-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button
                                                    type="submit"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#exampleModal"
                                                >
                                                   Submit Now
                                                    <span style={{marginLeft:"12px"}}>
                                                        <i className="fa-solid fa-arrow-right-long" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </section>

                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog Registra_modul_Request">
                        <div className="modal-content ">
                            <div className="modal-body thank_heading_detail">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/popup_back.png`} alt="img" />
                                <h4>Thank You</h4>
                                <p>Your submission has been received,</p>
                                <p>We will be in touch and contact you soon!</p>
                                <div className="request_send">
                                    <button
                                        type="button"
                                        className="btn btn-secondary back_color_main"
                                        data-bs-dismiss="modal"
                                    >
                                        Back to site{" "}
                                        <span>
                                            <i className="fa-solid fa-arrow-right-long" />
                                        </span>
                                    </button>
                                    {/* <a href="#" class="btn btn-secondary back_color_main"></span></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactFormServices