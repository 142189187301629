// import axios from "axios";
// import axios from "axios";

let host = process.env.REACT_APP_API_SERVER;
// let host = `https://alcorn.alobhatech.com`

//header//
export const HeaderData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/menu-bar`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchHeaderDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchHeaderDataRequest = () => ({
    type: "FIND_Header_PAGE_REQUEST",
});

export const fetchHeaderDataSuccess = (data) => ({
    type: "FIND_Header_PAGE_SUCCESS",
    payload: data,
});

export const fetchHeaderDataFailure = (error) => ({
    type: "FIND_Header_PAGE_FAILURE",
    payload: error,
});
//header//

//product-category-page//
export const CategoryData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-category-page-data?slug=${slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchCategoryDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchCategoryDataRequest = () => ({
    type: "FIND_CATEGORY_DATA_PAGE_REQUEST",
});

export const fetchCategoryDataSuccess = (data) => ({
    type: "FIND_CATEGORY_DATA_PAGE_SUCCESS",
    payload: data,
});

export const fetchCategoryDataFailure = (error) => ({
    type: "FIND_CATEGORY_DATA_PAGE_FAILURE",
    payload: error,
});
//product-category-page//

//sub-category-page//
export const SubCategoryData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-subcategory-page-data?slug=${slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchSubCategoryDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchSubCategoryDataRequest = () => ({
    type: "FIND_SUB_CATEGORY_DATA_PAGE_REQUEST",
});

export const fetchSubCategoryDataSuccess = (data) => ({
    type: "FIND_SUB_CATEGORY_DATA_PAGE_SUCCESS",
    payload: data,
});

export const fetchSubCategoryDataFailure = (error) => ({
    type: "FIND_SUB_CATEGORY_DATA_PAGE_FAILURE",
    payload: error,
});
//sub-category-page//

//get-all-category-page//
export const AllSubCategory = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-subcategory-page-data?slug=${slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchAllSubCategorySuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchAllSubCategoryRequest = () => ({
    type: "FIND_ALL_SUB_CATEGORY_DATA_PAGE_REQUEST",
});

export const fetchAllSubCategorySuccess = (data) => ({
    type: "FIND_ALL_SUB_CATEGORY_DATA_PAGE_SUCCESS",
    payload: data,
});

export const fetchAllSubCategoryFailure = (error) => ({
    type: "FIND_ALL_SUB_CATEGORY_DATA_PAGE_FAILURE",
    payload: error,
});
//get-all-category-page//

//product details page//
export const ProductDetails = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-product-page-data?slug=${slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchProductDetailsSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchProductDetailsRequest = () => ({
    type: "FIND_ALL_PRODUCT_DETAILS_PAGE_REQUEST",
});

export const fetchProductDetailsSuccess = (data) => ({
    type: "FIND_ALL_PRODUCT_DETAILS_PAGE_SUCCESS",
    payload: data,
});

export const fetchProductDetailsFailure = (error) => ({
    type: "FIND_ALL_PRODUCT_DETAILS_PAGE_FAILURE",
    payload: error,
});
//product details page//

//get download page data//
export const DownloadPageData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-download-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchDownloadPageDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchDownloadPageDataRequest = () => ({
    type: "FIND_DOWNLOAD_PAGE_DATA_REQUEST",
});

export const fetchDownloadPageDataSuccess = (data) => ({
    type: "FIND_DOWNLOAD_PAGE_DATA_SUCCESS",
    payload: data,
});

export const fetchDownloadPageDataFailure = (error) => ({
    type: "FIND_DOWNLOAD_PAGE_DATA_FAILURE",
    payload: error,
});
//get download page data//

//Get Business Division Data//
export const BusinessDivisionData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-business-division-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchBusinessDivisionDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchBusinessDivisionDataRequest = () => ({
    type: "FIND_BUSINESS_DIVISION_DATA_REQUEST",
});

export const fetchBusinessDivisionDataSuccess = (data) => ({
    type: "FIND_BUSINESS_DIVISION_DATA_SUCCESS",
    payload: data,
});

export const fetchBusinessDivisionDataFailure = (error) => ({
    type: "FIND_BUSINESS_DIVISION_DATA_FAILURE",
    payload: error,
});
//Get Business Division Data//

//home page data//
export const HomePageData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-home-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchHomePageDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchHomePageDataRequest = () => ({
    type: "FIND_HOME_PAGE_DATA_REQUEST",
});

export const fetchHomePageDataSuccess = (data) => ({
    type: "FIND_HOME_PAGE_DATA_SUCCESS",
    payload: data,
});

export const fetchHomePageDataFailure = (error) => ({
    type: "FIND_HOME_PAGE_DATA_FAILURE",
    payload: error,
});
//home page data//

//about us page data//
export const AboutUsData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-about-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchAboutUsDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchAboutUsDataRequest = () => ({
    type: "FIND_ABOUT_US_DATA_REQUEST",
});

export const fetchAboutUsDataSuccess = (data) => ({
    type: "FIND_ABOUT_US_DATA_SUCCESS",
    payload: data,
});

export const fetchAboutUsDataFailure = (error) => ({
    type: "FIND_ABOUT_US_DATA_FAILURE",
    payload: error,
});
//about us page data//

//all service page data//
export const AllServiceData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-service-main-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchAllServiceDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchAllServiceDataRequest = () => ({
    type: "FIND_ALL_SERVICE_DATA_REQUEST",
});

export const fetchAllServiceDataSuccess = (data) => ({
    type: "FIND_ALL_SERVICE_DATA_SUCCESS",
    payload: data,
});

export const fetchAllServiceDataFailure = (error) => ({
    type: "FIND_ALL_SERVICE_DATA_FAILURE",
    payload: error,
});
//all service page data//

//service details page data//
export const ServiceDetails = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-service-page-data?slug=${slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchServiceDetailsSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchServiceDetailsRequest = () => ({
    type: "FIND_SERVICE_DETAILS_DATA_REQUEST",
});

export const fetchServiceDetailsSuccess = (data) => ({
    type: "FIND_SERVICE_DETAILS_DATA_SUCCESS",
    payload: data,
});

export const fetchServiceDetailsFailure = (error) => ({
    type: "FIND_SERVICE_DETAILS_DATA_FAILURE",
    payload: error,
});
//service details page data//

//BOOK COMPLAINT DATA//
export const BookComplaint = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-book-a-complaint-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchBookComplaintSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchBookComplaintRequest = () => ({
    type: "BOOK_COMPLAINT_REQUEST",
});

export const fetchBookComplaintSuccess = (data) => ({
    type: "BOOK_COMPLAINT_SUCCESS",
    payload: data,
});

export const fetchBookComplaintFailure = (error) => ({
    type: "BOOK_COMPLAINT_FAILURE",
    payload: error,
});
//BOOK COMPLAINT DATA//

//PRODUCT REGISTRATION//
export const ProductRegistrationDetails = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-register-your-product-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchProductRegistrationSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchProductRegistrationRequest = () => ({
    type: "PRODUCT_REGISTRATION_REQUEST",
});

export const fetchProductRegistrationSuccess = (data) => ({
    type: "PRODUCT_REGISTRATION_SUCCESS",
    payload: data,
});

export const fetchProductRegistrationFailure = (error) => ({
    type: "PRODUCT_REGISTRATION_FAILURE",
    payload: error,
});
//PRODUCT REGISTRATION//

//EXTENDED WARRANTY//
export const ExtendedWarrantyDetails = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-extended-warranty-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchExtendedWarrantySuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchExtendedWarrantyRequest = () => ({
    type: "EXTENDED_WARRANTY_REQUEST",
});

export const fetchExtendedWarrantySuccess = (data) => ({
    type: "EXTENDED_WARRANTY_SUCCESS",
    payload: data,
});

export const fetchExtendedWarrantyFailure = (error) => ({
    type: "EXTENDED_WARRANTY_FAILURE",
    payload: error,
});
//EXTENDED WARRANTY//

//INSTALLATION HELP//
export const InstallationHelp = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-installation-help-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchInstallationHelpSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchInstallationHelpRequest = () => ({
    type: "INSTALLATION_HELP_REQUEST",
});

export const fetchInstallationHelpSuccess = (data) => ({
    type: "INSTALLATION_HELP_SUCCESS",
    payload: data,
});

export const fetchInstallationHelpFailure = (error) => ({
    type: "INSTALLATION_HELP_FAILURE",
    payload: error,
});
//INSTALLATION HELP//

//header footer data//
export const HeaderFooterData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/header-footer-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchHeaderFooterDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchHeaderFooterDataRequest = () => ({
    type: "HEADER_FOOTER_REQUEST",
});

export const fetchHeaderFooterDataSuccess = (data) => ({
    type: "HEADER_FOOTER_SUCCESS",
    payload: data,
});

export const fetchHeaderFooterDataFailure = (error) => ({
    type: "HEADER_FOOTER_FAILURE",
    payload: error,
});
//header footer data//


//blog details page//
export const BlogDetailsPage = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-blog-page-data?slug=${slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchBlogDetailsPageSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchBlogDetailsPageRequest = () => ({
    type: "BLOG_DETAILS_PAGE_REQUEST",
});

export const fetchBlogDetailsPageSuccess = (data) => ({
    type: "BLOG_DETAILS_PAGE_SUCCESS",
    payload: data,
});

export const fetchBlogDetailsPageFailure = (error) => ({
    type: "BLOG_DETAILS_PAGE_FAILURE",
    payload: error,
});
//blog details page//

//faqdata//
export const FAQData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/faq`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchFAQDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in FAQData function(redux):", err);
            })
    }
};
export const fetchFAQDataRequest = () => ({
    type: "FAQ_REQUEST",
});

export const fetchFAQDataSuccess = (data) => ({
    type: "FAQ_SUCCESS",
    payload: data,
});

export const fetchFAQDataFailure = (error) => ({
    type: "FAQ_FAILURE",
    payload: error,
});
//faqdata//

//cmsData//
export const CMSData = (type) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-all-cms?name=${type}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchCMSDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in CMSData function(redux):", err);
            })
    }
};
export const fetchCMSDataRequest = () => ({
    type: "CMS_REQUEST",
});

export const fetchCMSDataSuccess = (data) => ({
    type: "CMS_SUCCESS",
    payload: data,
});

export const fetchCMSDataFailure = (error) => ({
    type: "CMS_FAILURE",
    payload: error,
});
//cmsData//

//DesignYourSolarProduct//
export const DesignYourSolarProduct = (type) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-design-your-solar-products-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchDesignYourSolarProductSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in DesignYourSolarProduct function(redux):", err);
            })
    }
};
export const fetchDesignYourSolarProductRequest = () => ({
    type: "DESIGN_YOUR_SOLAR_PRODUCT_REQUEST",
});

export const fetchDesignYourSolarProductSuccess = (data) => ({
    type: "DESIGN_YOUR_SOLAR_PRODUCT_SUCCESS",
    payload: data,
});

export const fetchDesignYourSolarProductFailure = (error) => ({
    type: "DESIGN_YOUR_SOLAR_PRODUCT_FAILURE",
    payload: error,
});
//DesignYourSolarProduct//

//dropdown//
//installation area//
export const InstallationArea = (type) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/installation-area`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchInstallationAreaSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in InstallationArea function(redux):", err);
            })
    }
};

export const fetchInstallationAreaSuccess = (data) => ({
    type: "INSTALLATION_AREA_SUCCESS",
    payload: data,
});


//installation area//

//type of property//
export const TypeOfProperty = (type) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/type-of-property`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchTypeOfPropertySuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in TypeOfProperty function(redux):", err);
            })
    }
};

export const fetchTypeOfPropertySuccess = (data) => ({
    type: "TYPE_OF_PROPERTY_SUCCESS",
    payload: data,
});


//type of property//
//installation timeline//
export const InstallationTimeLine = (type) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/installation-timeline`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchInstallationTimeLineSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in InstallationTimeLine function(redux):", err);
            })
    }
};

export const fetchInstallationTimeLineSuccess = (data) => ({
    type: "INSTALLATION_TIME_LINE_SUCCESS",
    payload: data,
});


//installation timeline//

//solar panel type//
export const SolarPanelType = (type) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/preferred-solar-panel-type`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchSolarPanelTypeSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in SolarPanelType function(redux):", err);
            })
    }
};

export const fetchSolarPanelTypeSuccess = (data) => ({
    type: "SOLAR_PANEL_TYPE_SUCCESS",
    payload: data,
});
//solar panel type//

//preferred-solar-inverter-tech//
export const SolarInverterTech = (type) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/preferred-solar-inverter-tech`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchSolarInverterTechSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in SolarInverterTech function(redux):", err);
            })
    }
};

export const fetchSolarInverterTechSuccess = (data) => ({
    type: "SOLAR_INVERTER_TECH_SUCCESS",
    payload: data,
});
//preferred-solar-inverter-tech//

//how-did-you-heared-about-us//
export const HearedAboutUs = (type) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/how-did-you-heared-about-us`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchHearedAboutUs(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in SolarInverterTech function(redux):", err);
            })
    }
};

export const fetchHearedAboutUs = (data) => ({
    type: "HEARED_ABOUT_US_SUCCESS",
    payload: data,
});
//how-did-you-heared-about-us//
//dropdown data//


//odm/oem data//
export const OdmData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-oem-odm-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchOdmDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchOdmDataRequest = () => ({
    type: "ODM_OEM_DATA_REQUEST",
});

export const fetchOdmDataSuccess = (data) => ({
    type: "ODM_OEM_DATA_SUCCESS",
    payload: data,
});

export const fetchOdmDataFailure = (error) => ({
    type: "ODM_OEM_DATA_FAILURE",
    payload: error,
});
//odm/oem data//

//get-contact-us-page-data//
export const ContactUsData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-contact-us-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchContactUsDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchContactUsDataRequest = () => ({
    type: "CONTACT_US_DATA_REQUEST",
});

export const fetchContactUsDataSuccess = (data) => ({
    type: "CONTACT_US_DATA_SUCCESS",
    payload: data,
});

export const fetchContactUsDataFailure = (error) => ({
    type: "CONTACT_US_DATA_FAILURE",
    payload: error,
});
//get-contact-us-page-data//

//load calculator data//
export const LoadCalculatorData = (slug, token) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-load-calculator-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchLoadCalculatorDataSuccess(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in LoadCalculatorData function(redux):", err);
            })
    }
};
export const fetchLoadCalculatorDataRequest = () => ({
    type: "LOAD_CALCULATOR_REQUEST",
});

export const fetchLoadCalculatorDataSuccess = (data) => ({
    type: "LOAD_CALCULATOR_SUCCESS",
    payload: data,
});

export const fetchLoadCalculatorDataFailure = (error) => ({
    type: "LOAD_CALCULATOR_FAILURE",
    payload: error,
});
//load calculator data//

//Store locator data//
export const StoreLocatorData = (pincode) => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/search-store?pincode=${pincode}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchStoreLocatorDataSuccess(resData?.data));
                });
            }).catch((err) => {
                console.log("ERROR in StoreLocatorData function(redux):", err);
            })
    }
};
export const fetchStoreLocatorDataRequest = () => ({
    type: "STORE_LOCATOR_REQUEST",
});

export const fetchStoreLocatorDataSuccess = (data) => ({
    type: "STORE_LOCATOR_SUCCESS",
    payload: data,
});

export const fetchStoreLocatorDataFailure = (error) => ({
    type: "STORE_LOCATOR_FAILURE",
    payload: error,
});
//Store locator data//

//Store locator page data//
export const StoreLocatorPageData = () => {
    return async function (dispatch) {
        let config;
        config = { method: "GET" }

        await fetch(`${host}/api/master/get-store-locator-page-data`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchStoreLocatorPageDataSuccess(resData?.data));
                });
            }).catch((err) => {
                console.log("ERROR in StoreLocatorPageData function(redux):", err);
            })
    }
};
export const fetchStoreLocatorPageDataRequest = () => ({
    type: "STORE_LOCATOR_PAGE_REQUEST",
});

export const fetchStoreLocatorPageDataSuccess = (data) => ({
    type: "STORE_LOCATOR_PAGE_SUCCESS",
    payload: data,
});

export const fetchStoreLocatorPageDataFailure = (error) => ({
    type: "STORE_LOCATOR_PAGE_FAILURE",
    payload: error,
});
//Store locator data//