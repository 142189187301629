import React, { useEffect, useState } from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/StoreLocator.css";
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { StoreLocatorPageData } from '../../../Redux/actions/action';
import Loader from '../../Common/Loader/Loader';
const host = process.env.REACT_APP_API_SERVER;
const StoreLocator2 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [banner, setBanner] = useState("/assets/images/store_locator/banner.png");
    const {
        findStoreLocatorPage,
        findStoreLocatorPageError,
        loadingStoreLocatorPageApi,
    } = useSelector(state => state?.reducer);

    useEffect(() => {
        dispatch(StoreLocatorPageData());
    }, [])


    const formik = useFormik({
        initialValues: {
            pincode: ""
        },
        validationSchema: Yup.object({
            pincode: Yup.string().required("Please enter a city or pincode."),
        }),
        onSubmit: (values) => {
            navigate(`/store-locator-search?pin=${values.pincode}`)
        }
    });
    useEffect(() => {
        if (findStoreLocatorPage) {
            if (findStoreLocatorPage.storeLocatorBanner) {
                setBanner(`${host}/${findStoreLocatorPage.storeLocatorBanner}`)
            }
        }
    }, [findStoreLocatorPage])
    if (loadingStoreLocatorPageApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }
    return (
        <>
            <div className="store-locator">
                {/* <Toaster /> */}
                <Header />
                {/* ==========banner=========== */}
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-store-locator" style={{ background: `url("${banner}")` }}>
                            <div className="container">
                                <div className="row main_stor_locator">
                                    <div className="col-lg-6">
                                        <div className="main_store_locator">
                                            <h2>{findStoreLocatorPage?.storeLocatorHeading}</h2>
                                            <form onSubmit={formik.handleSubmit}>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Pincode or City"
                                                    className="form-control"
                                                    name='pincode'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.pincode}
                                                />
                                                {formik.touched.pincode && formik.errors.pincode ? (
                                                    <div className="error mb-3">{formik.errors.pincode}</div>
                                                ) : null}
                                                <button type="submit" className="searchStore">
                                                    Search
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ==========banner=========== */}

                <section className="padding-b-t-50 bg_images_product_next">
                    <div className="container">
                        <div className="main_detail_section">
                            <h3>Please enter pincode or city in search box to search for Alcorn Stores.</h3>
                        </div>

                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default StoreLocator2