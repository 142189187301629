import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import parse from 'html-react-parser';
const HelpandSupport = ({ findHomePageDataData, host }) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: false, // Hide previous arrow
        nextArrow: false, // Hide next arrow
        responsive: [
            {
                breakpoint: 1024, // screens up to 1024px
                settings: {
                    slidesToShow: 3, // Show 3 slides at once
                    slidesToScroll: 3, // Scroll 3 slides at once
                }
            },
            {
                breakpoint: 767, // screens up to 767px
                settings: {
                    slidesToShow: 2, // Show 2 slides at once
                    slidesToScroll: 2, // Scroll 2 slides at once
                }
            },
            {
                breakpoint: 480, // screens up to 480px
                settings: {
                    slidesToShow: 1, // Show 1 slide at once
                    slidesToScroll: 1, // Scroll 1 slide at once
                }
            }
        ]
    };
    const TruncatedTextWithTooltip = ({ text, maxLength }) => {
        const shouldTruncate = text.length > maxLength;
        const truncatedText = shouldTruncate ? `${text.slice(0, maxLength)}...` : text;

        return (
            <p title={text}>{truncatedText}</p>
        );
    };
    return (
        <>
            <div className="alcorn-home-help-and-support">
                <section className="main_Support padding-b-t-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main_why_choose_us pb-2 design_ul_list">
                                    <h4 className='text-center'>Help &amp; Support </h4>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <span>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/ion_call.png`} alt="call" />
                                                </span>
                                                {findHomePageDataData?.help_support_mobile}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/ion_time.png`} alt="call" />
                                                </span>
                                                {findHomePageDataData?.help_support_timing}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/ion_email.png`} alt="call" />
                                                </span>
                                                {findHomePageDataData?.help_support_email}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <Slider {...settings} className="main-slider-Support owl-carousel owl-theme">
                                    <div className="item">
                                        <div className="card-support">
                                            <div className="icon-support">
                                                {
                                                    findHomePageDataData?.help_support_bc_logo
                                                        ?
                                                        <img src={`${host}/${findHomePageDataData?.help_support_bc_logo}`} alt="Complaint Icon" />
                                                        :
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/supp_1.png`} alt="Complaint Icon" />
                                                }
                                            </div>
                                            <h2>Book A Complaint</h2>
                                            <p className='custom-line-clamp'>
                                                {parse(`${findHomePageDataData?.help_support_bc_description}`)}
                                            </p>
                                            <Link to="/book-your-complaint">Read More →</Link>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="card-support">
                                            <div className="icon-support">{
                                                findHomePageDataData?.help_support_rp_logo
                                                    ? <img src={`${host}/${findHomePageDataData?.help_support_rp_logo}`} alt="Complaint Icon" /> : <img src={`${process.env.PUBLIC_URL}/assets/images/supp_2.png`} alt="Complaint Icon" />
                                            }

                                            </div>
                                            <h2>Register Your Product</h2>
                                            <p className='custom-line-clamp'>
                                                {parse(`${findHomePageDataData?.help_support_ew_description}`)}

                                            </p>
                                            <Link to="/product-registration">Read More →</Link>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="card-support">
                                            <div className="icon-support">
                                                {findHomePageDataData?.help_support_ew_logo
                                                    ?
                                                    <img src={`${host}/${findHomePageDataData?.help_support_ew_logo}`} alt="Complaint Icon" />
                                                    :

                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/supp_3.png`} alt="Complaint Icon" />
                                                }
                                            </div>
                                            <h2>Extended Warranty</h2>
                                            <p className='custom-line-clamp'>

                                                {parse(`${findHomePageDataData?.help_support_ih_description}`)}
                                            </p>

                                            <Link to="/extended-warranty">Read More →</Link>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="card-support">
                                            <div className="icon-support">
                                                {
                                                    findHomePageDataData?.help_support_ih_logo ?
                                                        <img src={`${host}/${findHomePageDataData?.help_support_ih_logo}`} alt="Complaint Icon" />
                                                        :

                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/supp_4.png`} alt="Complaint Icon" />
                                                }
                                            </div>
                                            <h2>Installation Help</h2>
                                            <p className='custom-line-clamp'>

                                                {parse(`${findHomePageDataData?.help_support_rp_description}`)}
                                            </p>

                                            <Link to="/installation">Read More →</Link>
                                        </div>
                                    </div>
                                </Slider>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default HelpandSupport