import React,{useEffect} from 'react'
import Header from '../../Common/Header/Header'
import Banner from './Banner'
import About from './About'
import Footer from '../../Common/Footer/Footer'
import Whychooseus from './Whychooseus'
import Counter from './Counter'
import Product from './Product'
import KnowledgeMore from './KnowledgeMore'
import Wecanhelp from './Wecanhelp'
import Testimonial from './Testimonial'
import HelpandSupport from './HelpandSupport'
import {useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { HomePageData } from '../../../Redux/actions/action'
import ContactusPage from '../Contactus/ContactusPage'

let envhost = process.env.REACT_APP_API_SERVER;

const Home = () => {
    const host = `${envhost}/`
    const dispatch = useDispatch();
    const {
        loadingHomePageDataDataApi,
        findHomePageDataData,
        findHomePageDataDataError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(HomePageData());
    }, []);
    return (
        <>
            <Header />
            <Banner bannerData={findHomePageDataData?.banners} host={host}/>
            <About aboutUs={findHomePageDataData?.about} host={host} />
            <Whychooseus WhychooseUs={findHomePageDataData?.whychooseusData} whychooseusHeading={findHomePageDataData?.whychooseusHeading} host={host}/>
            <Counter counterdata={findHomePageDataData?.counterdata} host={host}/>
            <Product findHomePageDataData={findHomePageDataData} host={host}/>
            <KnowledgeMore findHomePageDataData={findHomePageDataData} host={host}/>
            <Wecanhelp findHomePageDataData={findHomePageDataData} host={host}/>
            <Testimonial findHomePageDataData={findHomePageDataData} host={host}/>
            <HelpandSupport findHomePageDataData={findHomePageDataData} host={host} />
            <ContactusPage/>
            <Footer />
        </>
    )
}

export default Home