import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Team = ({ findAboutUsData, host }) => {
    const settings = {
        infinite: findAboutUsData && findAboutUsData.team && findAboutUsData.team.length > 3,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: null,
        nextArrow: null,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: findAboutUsData && findAboutUsData.team && findAboutUsData.team.length > 3,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: findAboutUsData && findAboutUsData.team && findAboutUsData.team.length > 2,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: findAboutUsData && findAboutUsData.team && findAboutUsData.team.length > 1,

                }
            }
        ]
    };
    return (
        <div className="alcorn-about-team">
            <section className="padding-b-t-50 bg_images_product align-items-center">
                <div className="container">
                    <div className=" pt-4 ">
                    <Slider {...settings} className="main-slider-testimonials owl-carousel owl-theme">
                        {
                            findAboutUsData?.team?.map((value, index) => {
                                const TruncatedTextWithTooltip = ({ text, maxLength }) => {
                                    const shouldTruncate = text.length > maxLength;
                                    const truncatedText = shouldTruncate ? `${text.slice(0, maxLength)}...` : text;

                                    return (
                                        <h2 title={text}>{truncatedText}</h2>
                                    );
                                };
                                return (
                                        <div className="profile_about_main">
                                            <div className="profile_about_img">
                                                <img src={`${host}/${value?.image}`} alt="img" />
                                            </div>
                                            <h4>  <TruncatedTextWithTooltip text={value?.name} maxLength={10} /></h4>
                                            <p>({value?.description})</p>
                                        </div>
                                )
                            })
                        }
                        </Slider>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Team