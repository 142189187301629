import React from 'react';
import "./Scss/Home.css";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
const Services = ({ findHomePageDataData,host }) => {
    const serviceCount = findHomePageDataData.service_list ? findHomePageDataData.service_list.length : 0;
    var settings = {
        dots: false,
        infinite: serviceCount > 4,
        autoplay: true,           // Enable autoplay
        autoplaySpeed: 3000,   
        speed: 500,
        slidesToShow: Math.min(serviceCount, 4), // Show 4 slides at once
        slidesToScroll: 1, // Scroll 4 slides at once
        prevArrow: null, // Hide previous arrow
        nextArrow: null, // Hide next arrow
        responsive: [
            {
                breakpoint: 1024, // screens up to 1024px
                settings: {
                    slidesToShow: Math.min(serviceCount, 3), // Show 3 slides at once
                    slidesToScroll: 1, // Scroll 3 slides at once
                    infinite: serviceCount > 3,
                }
            },
            {
                breakpoint: 767, // screens up to 767px
                settings: {
                    slidesToShow: Math.min(serviceCount, 2),
                    slidesToScroll: 1, // Scroll 2 slides at once
                    infinite: serviceCount > 2,
                }
            },
            {
                breakpoint: 480, // screens up to 480px
                settings: {
                    slidesToShow: 1, // Show 1 slide at once
                    slidesToScroll: 1, // Scroll 1 slide at once
                }
            }
        ]
    };
    return (
        <>
            <div className="alcorn-home-service">
                <div className="padding-b-t-20 main_bg_services pt-5 pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <h6 className="main_Product_icon">
                                    <span>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/fav_icon.png`} alt="who" />
                                    </span>
                                    Our Services
                                </h6>
                            </div>
                            <div className="col-md-6">
                                <div className="header_text_main">
                                    <h2>
                                        {findHomePageDataData?.our_services_line1}
                                        <span>{findHomePageDataData?.our_services_line2}</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="p_text_detail">
                                    <p className='text-right'>
                                        {parse(`${findHomePageDataData?.our_services_description}`)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-4 pb-2">
                            <div className="col-md-12">
                                <Slider {...settings} className="main-slider-services owl-carousel owl-theme">
                                    {
                                        findHomePageDataData?.service_list?.map((value, index) => {
                                            const isOdd = index % 2 !== 0;
                                            // Determine the class to add
                                            const sectionClass = isOdd ? "item-odd" : "";
                                            return (
                                                <div key={index} className={`item ${sectionClass}`} style={{ marginRight: "20px" }}>
                                                    <div className="bg_best_services">
                                                     
                                                        <div className="service-logo-svg">
                                                            <img src={`${host}/${value?.logo}`} alt="" />
                                                        </div>
                                                        <h3>{value?.name}</h3>
                                                        <p>
                                                          {parse(`${value?.short_description}`)}
                                                        </p>
                                                        <Link to={`/capex-solar-projects/${value?.slug}`}>
                                                            Read More <i className="fa-solid fa-arrow-right-long" />
                                                        </Link>
                                                        <div className="wrap-decription">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/Union_hover.png`} alt="icon" />
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                 
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services