import React, { useRef } from "react";
import "../Services/Scss/services.css";
import CapexSolarProjectForm from "../Services/CapexSolarProjectForm";
const EnquiryForm = ({ id ,product_service_id}) => {
    const closeButtonRef = useRef(null);
    return (
        <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="EnquiryFormLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title enquiry-title fs-5" id="EnquiryFormLabel">Enquiry Now For Inverter</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <section className="">

                            <div className="col-md-12">
                                <div className="contact-form">

                                    <CapexSolarProjectForm enquiry_for="PRODUCT" product_service_id={product_service_id} closeButtonRef={closeButtonRef}/>
                                </div>
                            </div>

                        </section>
                    </div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        ref={closeButtonRef}
                        style={{ display: 'none' }} // Hide the button if you don't want it visible
                    >
                        Close
                    </button>

                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Understood</button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default EnquiryForm;