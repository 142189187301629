import React from 'react'
import parse from 'html-react-parser';
const Missionvisonvalue = ({ findAboutUsData,host }) => {

    return (
        <>
            <div className="alcorn-about-mission">
                <section className="padding-b-t-50 bg_images_product_next align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 ">
                                <div className="main_solar_about">
                                    <div className="solar_about_img">
                                        <img src={`${host}/${findAboutUsData?.mission_image}`} alt="About" />
                                    </div>
                                    <div className="solar_about_text">
                                        <h3>Mission</h3>
                                        <p className='max-content'>
                                            {parse(`${findAboutUsData?.mission_text}`)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 ">
                                <div className="main_solar_about">
                                    <div className="solar_about_img">
                                        <img src={`${host}/${findAboutUsData?.vision_image}`} alt="About" />
                                    </div>
                                    <div className="solar_about_text">
                                        <h3>Vision</h3>
                                        <p className='max-content'>
                                        {parse(`${findAboutUsData?.vision_text}`)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 ">
                                <div className="main_solar_about">
                                    <div className="solar_about_img">
                                        <img src={`${host}/${findAboutUsData?.value_image}`} alt="About" />
                                    </div>
                                    <div className="solar_about_text ">
                                        <h3>Value</h3>
                                        <p className='max-content'>
                                        {parse(`${findAboutUsData?.value_text}`)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Missionvisonvalue