import React, { useEffect } from 'react';

const GoogleMap = ({ locations }) => {
  useEffect(() => {
    // Initialize the map
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: locations[0].lat, lng: locations[0].lng }, // Default center
      zoom: 10,
    });

    // Add markers to the map
    locations.forEach(location => {
      new window.google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: map,
        title: location.name,
      });
    });
  }, [locations]);

  return (
    <div id="map" style={{ width: '100%', height: '500px' }}></div>
  );
};

export default GoogleMap;
