import React, { useEffect, useState } from 'react'
import "./Scss/Footer.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderFooterData } from '../../../Redux/actions/action';
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import * as Yup from 'yup';
import axios from "axios";
const Footer = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const newsletterSchema = Yup.object().shape({
        emailid: Yup.string().email('Invalid email').required('Please Enter Email'),

    });
    const host = process.env.REACT_APP_API_SERVER
    const dispatch = useDispatch();
    const {
        loadingHeaderFooterApi,
        findHeaderFooter,
        findHeaderFooterError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(HeaderFooterData());
    }, []);
    const formik = useFormik({
        initialValues: {
            emailid: ""

        },
        validationSchema: newsletterSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsSubmitting(true);
            try {

                const response = await axios.post(
                    `${host}/web/newsletter`,
                    values,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.data.code === 200) {
                    toast.success(response?.data?.msg);
                    resetForm();
                }
                else {
                    toast.error(response?.data?.msg);
                    resetForm();
                }
            } catch (error) {
                // Show error message
                const errorMessage = error.response ? error.response.data : error.message;
                toast.error(errorMessage);
                console.error("Error:", errorMessage);
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });
    return (
        <footer className="footer_bg_sec pt-3"
        >
            <Toaster />
            <div className="bg_footer_bott">
                <div className="container">
                    <div className="row pt-2 pb-2">
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="main_footer_detail">
                                <div className="logo_footer">
                                    <img src="/assets/images/logo_footer.png" alt="logo_footer" />
                                </div>
                                <p>
                                    {findHeaderFooter?.footer_text}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="main_footer_listing">
                                <h6>Alcorn India Care</h6>
                                <ul>
                                    <li>
                                        <Link to="/about">About us</Link>
                                    </li>
                                    {findHeaderFooter && findHeaderFooter?.categories && findHeaderFooter?.categories.map((cat, index) => {
                                        return <li key={index + 1}>
                                            <Link to={`/products/${cat?.slug}`}>{cat?.name}</Link>
                                        </li>
                                    })}
                                    <li>
                                        <Link to="/all-services">Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/book-your-complaint">Book your Complaint</Link>
                                    </li>
                                    <li>
                                        <Link to="/installation">Installation Help</Link>
                                    </li>
                                    <li>
                                        <Link to="/product-registration">Product Registration</Link>
                                    </li>
                                    <li>
                                        <Link to="/extended-warranty">Extended Warranty</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="main_footer_listing">
                                <h6>Get in Touch </h6>
                                <ul>
                                    <li>
                                        <Link to="/contact-us">Contact us</Link>
                                    </li>
                                    <li>
                                        <Link to="/ODM-query">OEM/ODM Query</Link>
                                    </li>
                                    <li>
                                        <Link to="/store-locator">Store locator</Link>
                                    </li>
                                    <li>
                                        <Link to="/download">Download E-Catalogue&nbsp;</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-conditions">Terms & Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/FAQ">FAQ</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="main_footer_listing">
                                <h6>Business division</h6>
                                <ul>
                                    {
                                        findHeaderFooter?.business_divisions?.map((value, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to="/business-division">{value}</Link>
                                                </li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="main_footer_listing">
                                <h6>Subscribe to our newsletter</h6>
                                <form className="mages_detail_section" onSubmit={formik.handleSubmit}>
                                    <div className="input-group mb-1">
                                        <span className="icon email-icon">
                                            <i className="fa-solid fa-envelope" />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Enter Your Email"
                                            required=""
                                            name="emailid"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.emailid}
                                        />
                                        {formik.touched.emailid && formik.errors.emailid ? (
                                            <div className="error">{formik.errors.emailid}</div>
                                        ) : null}
                                    </div>
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? <i className="fa fa-spinner fa-spin" /> : "Subscribe"}
                                    </button>
                                </form>
                                <div className="input-group main_detail_footer mb-1">
                                    <a href="#">
                                        <span>
                                            <i className="fa-solid fa-envelope" />
                                        </span>
                                        {findHeaderFooter?.email}
                                    </a>
                                </div>
                                <div className="input-group main_detail_footer mb-1">
                                    <a type='submit'>
                                        <span>
                                            <i className="fa-solid fa-phone" />
                                        </span>
                                        {findHeaderFooter?.mobile}
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row pt-2 pb-2">
                        <div className="col-lg-7 col-md-7 corporate_footer">
                            <h6 className='mb-3'>Corporate Tower</h6>
                            <p>
                                {findHeaderFooter?.corporate_tower}
                            </p>
                        </div>
                        <div className="col-lg-5 col-md-5 corporate_footer">
                            <h6 className='text-right mb-3' style={{ textAlign: "right" }}>Follow us</h6>
                            <div className="sosal_mid_icon text-right" style={{ textAlign: "right" }}>
                                <ul>
                                    <li>
                                        <Link to={findHeaderFooter?.fb} target='blank'>
                                            <i className="fa-brands fa-square-facebook" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={findHeaderFooter?.twitter} target='blank'>
                                            <i className="fa-brands fa-x-twitter" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={findHeaderFooter?.linkedin} target='blank'>
                                            <i className="fa-brands fa-linkedin" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={findHeaderFooter?.instagram} target='blank'>
                                            <i className="fa-brands fa-instagram" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="border_footer">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="copy_right_main">
                                    <a href="#">@ Copyright Alcorn India 2024. All rights reserved</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer