
import { object, string, number, boolean, date, mixed } from 'yup';

export const DesignYourSolarProductSchema = object({
    fname: string().required("First Name is required"),
    lname: string().required("Last Name is required"),
    email: string()
        .email("Invalid email address")
        .required("Email is required"),
    mobile: string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone Number must be exactly 10 digits"),
    address1: string().required("Address1 is required"),
    address2: string().required("Address2 is required"),
    address3: string().required("Address3 is required"),
    address4: string().required("Address4 is required"),
    typeofproperty: number().required("Type of Property is required"),
    propertysize: number().required("Property Size is required"),
    installationarea: number().required("Installation Area is required"),
    availablespace: number().required("Available Space is required"),
    last_year_avg_monthly_consumption: number().required("Last Year's Average Monthly Consumption is required"),
    last_year_avg_monthly_bill: number().required("Last Year's Average Monthly Bill is required"),
    current_electricity_provider: string().required("Current Electricity Provider is required"),
    existing_solar_panel_installed: boolean().required("Existing Solar Panel Installed status is required"),
    preferred_solar_inverter_technology: number().required("Preferred Solar Inverter Technology is required"),
    preferred_solar_panel_type: number().required("Preferred Solar Panel Type is required"),
    energy_storage_options: boolean().required("Energy Storage Options is required"),
    system_size: number().required("System Size is required"),
    preferred_installation_timeline: number().required("Preferred Installation Timeline is required"),
    specific_concerns: string().required("Specific Concerns are required"),
    how_did_you_hear_about_us: number().required("How did you hear about us is required"),
});

export  const feedBackSchema = object().shape({
    product_slug: string().required("Product slug is required"),
    name: string().required("Name is required"),
    rating: number().min(1, "Rating is required").required("Rating is required"),
    review: string().required("Review is required")
});

export const contactUsSchema = object({
    name: string().required(" Name is required"),
    email: string()
        .email("Invalid email address")
        .required("Email is required"),
    subject: string().required(" subject is required"),
    address: string().required(" address is required"),
});

export const capexSolarProjectFormSchema = object({
    name: string().required(" Name is required"),
    email: string()
        .email("Invalid email address")
        .required("Email is required"),
    subject: string().required(" subject is required"),
    address: string().required(" address is required"),
});
export const odmQuerySchema = object({
    name: string().required(" Name is required"),
    email: string()
        .email("Invalid email address")
        .required("Email is required"),
    phone_no: string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone Number must be exactly 10 digits"),
    address: string().required(" address is required"),
});

export const extendedWarranty = object({
    product_name_model: string().required("Product Name/Model is required"),
    serial_no: string().required("Serial Number is required"),
    manufacturer: string().required("Manufacturer is required"),
    standard_warranty_period: string().required("Warranty Period is required"),
    extended_warranty_option: boolean().required("Extended Warranty Option is required"),
    fname: string().required("First Name is required"),
    lname: string().required("Last Name is required"),
    email: string().email("Invalid email address").required("Email is required"),
    mobile: string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone Number must be exactly 10 digits"),
    address: string().required("Address is required"),
    additional_note: string(),
    invoice: mixed().required("Invoice is required"),
    product_images: mixed().required("Product images is required"),

})
export const bookComplaint = object({
    fname: string().required("First Name is required"),
    lname: string().required("Last Name is required"),
    email: string()
        .email("Invalid email address")
        .required("Email is required"),
    mobile: string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone Number must be exactly 10 digits"),
    address: string().required("Address is required"),
    product_type_id: string().required("Product Type is required"),
    serial_number: string().required("Serial Number is required"),
    installation_date: date().required("Installation Date is required"),
    reason_of_problem: string().required("Reason of the Problem is required"),
    problem_description: string().required("Problem Description is required"),
    availability_date: date().required("Availability Date is required"),
    additional_note: string().optional(),
    invoice: mixed().required("Invoice is required"),
    product_images:  mixed().required("Product images is required"),
    warranty_doc:  mixed().required("Warranty Doc is required"),
})
export const installationSchema = object({
    fname: string().required("First Name is required"),
    lname: string().required("Last Name is required"),
    email: string().email("Invalid email address").required("Email is required"),
    mobile: string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone Number must be exactly 10 digits"),
    address: string().required("Address is required"),
    product_name_model: string().required("Product Name is required"),
    serial_number: string().required("Serial Number is required"),
    purchase_date: string().required("Date is required"),
    installation_information: string().required("Installation Information is required"),
    invoice: mixed().required("Invoice is required"),
});
export const productRegistration = object({
    fname: string().required("First Name is required"),
    lname: string().required("Last Name is required"),
    email: string().email("Invalid email address").required("Email is required"),
    mobile: string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone Number must be exactly 10 digits"),
    address: string().required("Address is required"),
    product_name_model: string().required("Product Name is required"),
    serial_number: string().required("Serial Number is required"),
    purchase_date: string().required("Date is required"),
    installation_information: string().required("Installation Information is required"),
    invoice: mixed().required("Invoice is required"),
});