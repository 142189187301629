import React, { useEffect, useState } from 'react'
import "./Scss/ODM.css";
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { OdmData } from '../../../Redux/actions/action';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import { odmQuerySchema } from '../../../validationSchema/Index';
const OdmQuery = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const host = process.env.REACT_APP_API_SERVER
    const { name } = useParams();
    const dispatch = useDispatch();
    const {
        loadingOdmOemDataApi,
        findOdmOemData,
        findOdmOemDataError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(OdmData(name));
    }, [name]);
    const [captchaInput, setCaptchaInput] = useState('');
    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone_no: "",
            address: "",
            enquiry_for: "OEM/ODM"
        },
        validationSchema: odmQuerySchema,
        onSubmit: async (values, { resetForm }) => {
            setIsSubmitting(true);
          

            try {
                if (!validateCaptcha(captchaInput)) {
                    toast.error("please enter a valid captcha");
                    return; // Prevent form submission if captcha doesn't match
                }
                // Send a POST request with the JSON data
                const response = await axios.post(
                    `${host}/web/contact-us`,
                    values, // Send values directly as JSON
                    {
                        headers: {
                            "Content-Type": "application/json", // Set header for JSON data
                        },
                    }
                );
                // Show success message and reset the form
                if (response.data.code === 200) {

                    toast.success(response?.data?.msg);

                    resetForm();
                }
            } catch (error) {
                // Show error message
                const errorMessage = error.response ? error.response.data : error.message;
                toast.error(errorMessage);
                console.error("Error:", errorMessage);
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });
    return (
        <div className="Odm-query">
            <Toaster />
            <Header />
            <section>
                <div className="slider-wrapper">
                    <div className="slider-items-SPGS" style={{ background: `url(${host}/${findOdmOemData?.banner})` }}>
                        <div className="banner_detail_text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <h2>
                                            {findOdmOemData?.title}
                                        </h2>
                                        <p>{findOdmOemData?.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="padding-b-t-50 bg_images_product_next">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="main_why_choose_us services_payra pb-5">
                                <h2 className="text-left">{findOdmOemData?.heading}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="main_detail_services product_text_detail">
                                <p className="pb-3">
                                    {parse(`${findOdmOemData?.description}`)}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="input_main_query">
                                <form className="input_detail_query" onSubmit={formik.handleSubmit}>
                                    <h3 className="pb-1">
                                        Lorem Ipsum <span>Simply</span>&nbsp;{" "}
                                    </h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                                        a nunc at libero tempus aliquam. Maecenas
                                    </p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                placeholder="Your Name"
                                                className="main_quary_input"
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <div className="error">{formik.errors.name}</div>
                                            )}
                                        </div>
                                        <div className="col-md-12">
                                            <input
                                                type="email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                placeholder="Your Email Address"
                                                className="main_quary_input"
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <div className="error">{formik.errors.email}</div>
                                            )}
                                        </div>
                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                name="phone_no"
                                                value={formik.values.phone_no}
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                onChange={(e) => {
                                                    // Allow only digits
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        formik.handleChange(e);
                                                    }
                                                }}
                                                placeholder="Phone number *"
                                                className="main_quary_input"
                                            />
                                            {formik.touched.phone_no && formik.errors.phone_no ? (
                                                <div className="error">{formik.errors.phone_no}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <textarea
                                                name="address"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                placeholder="Address"
                                                className="form-control"
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <div className="error">{formik.errors.address}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row py-3">
                                        <div className="col-md-12 custom-captcha">
                                            <LoadCanvasTemplate reloadText={"Reload"} />
                                            <input
                                                type="text"
                                                value={captchaInput}
                                                onChange={(e) => setCaptchaInput(e.target.value)}
                                                placeholder="Enter CAPTCHA"
                                                // required
                                                className="form-control mt-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                className="submil_button"
                                                type="submit"
                                            // data-bs-toggle="modal"
                                            // data-bs-target="#exampleModal"
                                            >
                                                {isSubmitting ? (
                                                    <i className="fa fa-spinner fa-spin" />
                                                ) : (
                                                    <>
                                                        Submit Now
                                                        <span style={{ marginLeft: "12px" }}>
                                                            <i className="fa-solid fa-arrow-right-long" />
                                                        </span>
                                                    </>
                                                )}
                                            </button>
                                            {/* <button type="submit">
                                                Submit
                                            </button> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="padding-b-t-50 bg_images_product_next">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main_why_choose_us services_payra pb-5">
                                <h2 className="text-left">About Alcorn India</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-100">
                        <div className="col-lg-4 col-md-6 ">
                            <div className="main_solar_about">
                                <div className="solar_about_img">
                                    <img src={`${host}/${findOdmOemData?.mission_image}`} alt="About" />
                                </div>
                                <div className="solar_about_text">
                                    <h3>Mission</h3>
                                    <div className='max-content'>
                                        {parse(`${findOdmOemData?.mission_text}`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="main_solar_about mt-100">
                                <div className="solar_about_img">
                                    <img src={`${host}/${findOdmOemData?.vision_image}`} alt="About" />
                                </div>
                                <div className="solar_about_text">
                                    <h3>Vision</h3>
                                    <div className='max-content'>
                                        {parse(`${findOdmOemData?.vision_text}`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="main_solar_about">
                                <div className="solar_about_img">
                                    <img src={`${host}/${findOdmOemData?.value_image}`} alt="About" />
                                </div>
                                <div className="solar_about_text">
                                    <h3>Value</h3>
                                    <div className='max-content'>
                                        {parse(`${findOdmOemData?.value_text}`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* <div className="row mb-100"> */}
                        <div className="col-lg-4 col-md-6 ">
                            <div className="main_solar_about">
                                <div className="solar_about_img">
                                    <img src={`${host}/${findOdmOemData?.qa_image}`} alt="About" />
                                </div>
                                <div className="solar_about_text">
                                    <h3>QUALITY ASSURANCE</h3>
                                    <div className='max-content'>
                                        {parse(`${findOdmOemData?.qa_text}`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="main_solar_about mt-100">
                                <div className="solar_about_img">
                                    <img src={`${host}/${findOdmOemData?.sustainability_image}`} alt="About" />
                                </div>
                                <div className="solar_about_text">
                                    <h3>SUSTAINABILITY</h3>
                                    <div className='max-content'>
                                        {parse(`${findOdmOemData?.sustainability_text}`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="main_solar_about">
                                <div className="solar_about_img">
                                    <img src={`${host}/${findOdmOemData?.customer_centric_image}`} alt="About" />
                                </div>
                                <div className="solar_about_text">
                                    <h3>CUSTOMER-CENTRIC APPROACH</h3>
                                    <div className='max-content'>
                                        {parse(`${findOdmOemData?.customer_centric_text}`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default OdmQuery