import React, { useState, useEffect, useRef } from 'react';
import "./Scss/Contactus.css";
import CapexModel from '../Model/CapexModel';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import { contactUsSchema } from '../../../validationSchema/Index';
import { useDispatch, useSelector } from 'react-redux';
import { ContactUsData } from '../../../Redux/actions/action';
const host = process.env.REACT_APP_API_SERVER;

const ContactusPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const modalButtonRef = useRef(null);
    // const host = process.env.REACT_APP_API_SERVER
    const dispatch = useDispatch();
    const {
        loadingContactUsDataApi,
        findContactUsData,
        findContactUsDataError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(ContactUsData());
    }, []);
    const [captchaInput, setCaptchaInput] = useState('');
    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            subject: "",
            address: "",
        },
        validationSchema: contactUsSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsSubmitting(true);

            try {
                if (!validateCaptcha(captchaInput)) {
                    toast.error('Captcha Does Not Match. Please try again.');
                    return;
                }
                const response = await axios.post(
                    `${host}/web/contact-us`,
                    values,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.data.code === 200) {
                    toast.success(response?.data?.msg);
                    resetForm();
                    setCaptchaInput('');
                    if (modalButtonRef.current) {
                        modalButtonRef.current.click();
                    }
                }
            } catch (error) {
                const errorMessage = error.response ? error.response.data : error.message;
                toast.error(errorMessage);
                console.error("Error:", errorMessage);
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });
    return (
        <>
            <Toaster />
            <div className="alcorn-contact-us">
                <section className="main_contact padding-b-t-50">
                    <div className="container">
                        <div className="row flex_dir-xs align-items-center">
                            <div className="col-md-7">
                                <div className="contact-form">
                                    <form className="input_detail_sectrion" onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="mb-3">

                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={formik.values.name}
                                                        onChange={formik.handleChange}
                                                        placeholder="Your Name"
                                                        className="form-control"

                                                    />
                                                    {formik.touched.name && formik.errors.name && (
                                                        <div className="error">{formik.errors.name}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="mb-3">

                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        placeholder="Your Email Address"
                                                        className="form-control"

                                                    />
                                                    {formik.touched.email && formik.errors.email && (
                                                        <div className="error">{formik.errors.email}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">

                                                    <input
                                                        type="text"
                                                        name="subject"
                                                        value={formik.values.subject}
                                                        onChange={formik.handleChange}
                                                        placeholder="Subject"
                                                        className="form-control"

                                                    />
                                                    {formik.touched.subject && formik.errors.subject && (
                                                        <div className="error">{formik.errors.subject}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">

                                                    <textarea
                                                        name="address"
                                                        value={formik.values.address}
                                                        onChange={formik.handleChange}
                                                        placeholder="Address"
                                                        className="form-control"
                                                    />
                                                    {formik.touched.address && formik.errors.address && (
                                                        <div className="error">{formik.errors.address}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 custom-captcha mb-3">
                                                <LoadCanvasTemplate reloadText={"Reload"} />
                                                <input
                                                    type="text"
                                                    value={captchaInput}
                                                    onChange={(e) => setCaptchaInput(e.target.value)}
                                                    placeholder="Enter CAPTCHA"
                                                    // required
                                                    className="form-control mt-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <button
                                                    type="submit"
                                                // data-bs-toggle="modal"
                                                // data-bs-target="#exampleModal"
                                                >
                                                    {isSubmitting ? (
                                                        <i className="fa fa-spinner fa-spin" />
                                                    ) : (
                                                        <>
                                                            Submit Now
                                                            <span style={{ marginLeft: "12px" }}>
                                                                <i className="fa-solid fa-arrow-right-long" />
                                                            </span>
                                                        </>
                                                    )}
                                                </button>
                                                <button
                                                    ref={modalButtonRef}
                                                    style={{ display: "none" }}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#contact-us"
                                                >modal open</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="detail_text_main">
                                    <h2>Contact us? </h2>
                                    <p>
                                        {findContactUsData?.contact_text}
                                    </p>
                                </div>
                                <div className="profile-card-con">
                                    <div className="main_profile_con">
                                        <div className="profile-img-con">
                                            <img src={findContactUsData ? `${host}/${findContactUsData.contactperson_image}` : `${process.env.PUBLIC_URL}/assets/images/pro_con.png`} alt="Profile Picture" />
                                        </div>
                                        <div className="teax_name_con">
                                            <h2>{findContactUsData?.contactperson_name}</h2>
                                            <p className="email-con">{findContactUsData?.contactperson_email}</p>
                                        </div>
                                    </div>
                                    <div className="info-con">
                                        <div className="info-item-con">
                                            <a href="#">
                                                <span className="icon location-icon-con">
                                                    <i className="fa-solid fa-phone" />
                                                </span>
                                                {findContactUsData?.contactperson_mobile}
                                            </a>
                                        </div>
                                        <div className="info-item-con">
                                            <a href="#">
                                                <span className="icon location-icon-con">
                                                    <i className="fa-solid fa-location-dot" />
                                                </span>
                                                {findContactUsData?.address}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <CapexModel id="contact-us" />
        </>
    )
}

export default ContactusPage