import React, { useEffect, useState, useRef } from 'react'
import "./Scss/services.css";
import CapexModel from '../Model/CapexModel';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import { capexSolarProjectFormSchema } from '../../../validationSchema/Index';
const CapexSolarProjectForm = ({ enquiry_for, closeButtonRef, product_service_id }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    console.log(product_service_id, "product_service_id")
    const modalButtonRef = useRef(null);
    const host = process.env.REACT_APP_API_SERVER
    const [captchaInput, setCaptchaInput] = useState('');
    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            subject: "",
            address: "",
            enquiry_for: enquiry_for,
            product_service_id: null
        },
        validationSchema: capexSolarProjectFormSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsSubmitting(true);
          

            try {
                if (!validateCaptcha(captchaInput)) {
                    toast.error("please enter a valid captcha");
                    return; // Prevent form submission if captcha doesn't match
                }
                // Send a POST request with the JSON data
                const response = await axios.post(
                    `${host}/web/contact-us`,
                    values, // Send values directly as JSON
                    {
                        headers: {
                            "Content-Type": "application/json", // Set header for JSON data
                        },
                    }
                );
                if (response.data.code === 200) {
                    // Show success message and reset the form
                    toast.success(response?.data?.msg);
                    setCaptchaInput('');
                    resetForm();
                    // if (modalButtonRef.current) {
                    //     modalButtonRef.current.click();
                    // }
                    if (enquiry_for === 'PRODUCT' && closeButtonRef.current) {
                        closeButtonRef.current.click();
                    }

                }
            } catch (error) {
                // Show error message
                const errorMessage = error.response ? error.response.data : error.message;
                toast.error(errorMessage);
                console.error("Error:", errorMessage);
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });
    useEffect(() => {
        if (product_service_id) {
            formik.setFieldValue('product_service_id', product_service_id);
        }
    }, [product_service_id]);
    return (
        <div>
            <Toaster />
            <form className="input_detail_sectrion" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="mb-3">

                            <input
                                type="text"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                placeholder="Your Name"
                                className="form-control"

                            />
                            {formik.touched.name && formik.errors.name && (
                                <div className="error">{formik.errors.name}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="mb-3">
                            <input
                                type="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                placeholder="Your Email Address"
                                className="form-control"

                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className="error">{formik.errors.email}</div>
                            )}

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <input
                                type="text"
                                name="subject"
                                value={formik.values.subject}
                                onChange={formik.handleChange}
                                placeholder="Subject"
                                className="form-control"

                            />
                            {formik.touched.subject && formik.errors.subject ? (
                                <div className="error">{formik.errors.subject}</div>
                            ) : null}

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">

                            <textarea
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                placeholder="Address"
                                className="form-control"
                            />
                            {formik.touched.address && formik.errors.address && (
                                <div className="error">{formik.errors.address}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 custom-captcha mb-3">
                        <LoadCanvasTemplate reloadText={"Reload"} />
                        <input
                            type="text"
                            value={captchaInput}
                            onChange={(e) => setCaptchaInput(e.target.value)}
                            placeholder="Enter CAPTCHA"
                            // required
                            className="form-control mt-3"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? (
                                <i className="fa fa-spinner fa-spin" />
                            ) : (
                                <>
                                    Submit Now
                                    <span style={{ marginLeft: "12px" }}>
                                        <i className="fa-solid fa-arrow-right-long" />
                                    </span>
                                </>
                            )}
                        </button>

                        <button
                            ref={modalButtonRef}
                            style={{ display: "none" }}
                            data-bs-toggle="modal"
                            data-bs-target="#EnquiryForm"
                        >modal open</button>
                    </div>
                </div>
            </form>

            <CapexModel id="exampleModal" />
        </div>
    )
}

export default CapexSolarProjectForm