import React, { useState } from 'react';
import "./Scss/Home.css";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
const KnowledgeMore = ({ findHomePageDataData, host }) => {
    const [blog, setBlog] = useState(true);
    const [currentVideoLink, setCurrentVideoLink] = useState("");
    const handleVideoClick = (link) => {
        const videoId = link?.split('/')[3].split('?')[0];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        setCurrentVideoLink(embedUrl);
        let modalBTN = document.getElementById("youtubeVideoModalBTN");
        if (modalBTN) { modalBTN.click() }
    };
    const blogSection = findHomePageDataData.blogs?.length || 0;
    const productVideoSection = findHomePageDataData.product_video?.length || 0;
    
    const settings = {
        dots: false,
        infinite: blogSection > 3,
        speed: 500,
        slidesToShow: Math.min(blogSection, 3),
        slidesToScroll: 1,
        prevArrow: null,
        nextArrow: null,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    infinite: blogSection > 2,
                    slidesToShow: Math.min(blogSection, 2),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    infinite: blogSection > 2,
                    slidesToShow: Math.min(blogSection, 2),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    
    const productVideo = {
        dots: false,
        infinite: productVideoSection > 3,
        speed: 500,
        slidesToShow: Math.min(productVideoSection, 3),
        slidesToScroll: 1,
        prevArrow: null,
        nextArrow: null,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    infinite: productVideoSection > 2,
                    slidesToShow: Math.min(productVideoSection, 2),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    infinite: productVideoSection > 2,
                    slidesToShow: Math.min(productVideoSection, 2),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    function pauseVideo() {
        let videoId = document.getElementById("iframeyt");
        videoId.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*')

    }
    return (
        <>
            <div className="alcorn-home-knowledge-more">
                <section className="main_Knowledge_more padding-b-t-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main_why_choose_us_new">
                                    <h2>Knowledge More</h2>
                                    <h4 className='d-flex justify-content-center align-items-center gap-3'>
                                        <h4 className={`mb-0 pb-0`} style={{ cursor: "pointer" }} onClick={() => {
                                            setBlog(true)
                                        }}>
                                            Knowledge Centre
                                        </h4>
                                        <a onClick={() => {
                                            setBlog(false)
                                        }} style={{ cursor: "pointer" }}> Blog</a>
                                    </h4>

                                </div>
                                
                                <Slider  {...(blog ? productVideo : settings)} className="main-slider-knowledge owl-carousel owl-theme">
                                    {
                                        blog ?
                                            findHomePageDataData?.product_video?.map((value, index) => {
                                                return (
                                                    <div className="item" key={index}>
                                                        <div className="card-knowledge">

                                                            <div className="card-content-knowledge">
                                                                <div
                                                                    className="youtube-video"
                                                                    onClick={() => handleVideoClick(value?.link)}
                                                                >
                                                                    <img
                                                                        src="/assets/images/youTubeIcon.png"
                                                                        className="youTubeIcon"
                                                                        alt="YouTube Icon"
                                                                    />
                                                                    <img
                                                                        src={`${host}/${value?.image}`}
                                                                        alt=""
                                                                    />

                                                                    <button type="button" style={{ display: "none" }} id="youtubeVideoModalBTN" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                                        Launch static backdrop modal
                                                                    </button>

                                                                </div>

                                                            </div>

                                                            <div className="contact-knowledge">
                                                                <h4>{value?.title}</h4>
                                                                <p>{value?.subtitle}</p>
                                                                <p>
                                                                    Know More:
                                                                    <Link to={value?.url} target='_blank' rel="noopener noreferrer">{value?.url}</Link>
                                                                </p>
                                                                <p>Call: {value?.phone1}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) :

                                            findHomePageDataData?.blogs?.map((value, index) => {
                                                return (
                                                    <div className="item" key={index}>
                                                        <div className="card-knowledge">
                                                            <Link to={`/blog/${value?.slug}`}>
                                                                <div className="card-content-knowledge bg_blog_detail_home" style={{ background: `url(${host}/${value?.image})` }}>
                                                                </div>
                                                            </Link>
                                                            <div className="contact-knowledge">
                                                                <h4>{value?.title}</h4>
                                                                <p>{parse(`${value?.short_description}`)}</p>
                                                                <p>
                                                                    Know More:
                                                                    <Link to={value?.link}>{value?.link}</Link>
                                                                </p>
                                                                <p>Call: {value?.calling_no}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            {/* modas */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { pauseVideo() }}></button>
                        </div>
                        <div className="modal-body">
                            <iframe id='iframeyt' className='yt_player_iframe' width="100%" height="400" src={currentVideoLink + `?autoplay=1&enablejsapi=1`} title="PWM vs MPPT - Which one to choose for your application?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {/* <YouTubeModal /> */}
        </>
    )
}

export default KnowledgeMore