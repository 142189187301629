import { applyMiddleware, createStore } from 'redux';
// import rootReducer from "./reducer/index";
import {thunk} from 'redux-thunk';
// import reducer from './Reducers/reducer';
import rootReducer from './Reducers';

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

export default store;