import React, { useEffect } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Testimonial = ({ findHomePageDataData, host }) => {
    const settings = {
        infinite: findHomePageDataData && findHomePageDataData.testimonial && findHomePageDataData.testimonial.length > 4,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: null,
        nextArrow: null,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: findHomePageDataData && findHomePageDataData.testimonial && findHomePageDataData.testimonial.length > 3,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: findHomePageDataData && findHomePageDataData.testimonial && findHomePageDataData.testimonial.length > 2,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: findHomePageDataData && findHomePageDataData.testimonial && findHomePageDataData.testimonial.length > 1,

                }
            }
        ]
    };
    return (
        <>
            <div className="alcorn-home-testimonial">
                <section className="main_Testimonials padding-b-t-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main_why_testimonial pb-2">
                                    <h4>Testimonials</h4>
                                    <h2>
                                        What Our Customers Say <span>About us </span>
                                    </h2>
                                </div>

                                <Slider {...settings} className="main-slider-testimonials owl-carousel owl-theme">
                                    {
                                        findHomePageDataData?.testimonial?.map((value, index) => {
                                            const TruncatedTextWithTooltip = ({ text, maxLength }) => {
                                                const shouldTruncate = text.length > maxLength;
                                                const truncatedText = shouldTruncate ? `${text.slice(0, maxLength)}...` : text;

                                                return (
                                                    <h2 title={text}>{truncatedText}</h2>
                                                );
                                            };
                                            return (

                                                <div className="item" key={index}>
                                                    <div className="card-testimonial">
                                                        {/* <h2>{value?.name}</h2> */}
                                                        <TruncatedTextWithTooltip text={value?.name} maxLength={10} />
                                                        <h3>{value?.location}</h3>
                                                        <p>
                                                            {value?.message}
                                                        </p>
                                                        <div className="stars-testimonial">  {Array.from({ length: 5 }, (_, starIndex) => (
                                                            <i
                                                                key={starIndex}
                                                                className={`fa-solid fa-star ${starIndex < value?.stars ? "filled" : "empty"}`}
                                                            />
                                                        ))}</div>
                                                        <div className="profile-pic-testimonial">
                                                            <img src={`${host}/${value?.image}`} alt="Profile Picture" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Testimonial