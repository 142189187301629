import React from 'react';
import "./Scss/Revex.css"
import ContactFormServices from './ContactFormServices';

const RevexContent = () => {
    return (
        <>
            <section>
                <div className="slider-wrapper">
                    <div className="slider-items-caPex">
                        <div className="banner_detail_text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <h2>
                                            RevEx <span>Solar</span> Projects
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="padding-b-t-50 bg_images_inverters_series">
                <div className="container">
                    <div className="row padding-b-t-20 flex_dir-xs align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="main_detail_services">
                                <h2 className="pb-1">
                                    RevEx <span> Solar </span> Services
                                </h2>
                                <p className="pb-3">
                                    RevEx Solar Projects deprecation" isn't a widely recognized term in
                                    the solar energy industry or finance. However, based on common
                                    financial terminology and context.
                                </p>
                                <p className="pb-3">
                                    In essence, "RevEx Solar Projects deprecation" would imply a trend
                                    or shift away from revenue-based financing structures for solar
                                    projects, driven by various financial, market, and investor-related
                                    factors influencing the renewable energy sector.
                                </p>
                                <p className="pb-3">
                                    Investor Preferences: Shifts in investor preferences towards more
                                    predictable or stable returns, such as through fixed interest
                                    payments or ownership stakes (equity), could also contribute to the
                                    deprecation of revenue-based financing models.based on common
                                    financial terminology and context, it could be interpreted as
                                    follows:
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="srvices_images s-right b-right">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/CapEx/Capex-detail-3.png`} alt="savices" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="main_detail_services list_style_auto">
                                <ul>
                                    <li>
                                        <b>Revenue-Based Financing (RevEx):</b> This could refer to a
                                        financing model where the repayment of funds is based on the
                                        revenue generated by the solar project. In such a model, investors
                                        or financiers receive a portion of the project's revenue instead
                                        of a fixed payment or interest rate.
                                    </li>
                                    <li>
                                        <b> Deprecation in this Context:</b>
                                        If we consider "RevEx Solar Projects deprecation," it might
                                        suggest a decline or reduction in the use or popularity of
                                        revenue-based financing structures specifically for solar
                                        projects. This could happen due to several reasons: Risk and
                                        Return Considerations:** Revenue-based financing may be perceived
                                        as riskier compared to traditional debt or equity financing,
                                        especially if revenue projections are uncertain or volatile.
                                        Market Conditions:** Changes in energy prices, regulatory
                                        environment, or market dynamics could impact the revenue
                                        generation potential of solar projects, affecting the
                                        attractiveness of revenue-based financing.
                                    </li>
                                    <li>
                                        <b>Alternative Financing Models:</b> As the solar industry
                                        evolves, stakeholders might favor other financing options like
                                        power purchase agreements (PPAs), leasing arrangements,
                                        traditional debt financing, or equity investments, which offer
                                        different risk-return profiles and financial benefits. In essence,
                                        "RevEx Solar Projects deprecation" would imply a trend or shift
                                        away from revenue-based financing structures for solar projects,
                                        driven by various financial, market, and investor-related factors
                                        influencing the renewable energy sector
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ContactFormServices content="Revex"/>
        </>
    )
}

export default RevexContent