import React from 'react'
import { Link } from 'react-router-dom'

const Wecanhelp = ({findHomePageDataData,host}) => {
    return (
        <>
            <div className="alcorn-home-we-can-help">
                <section className="main_can_help padding-b-t-20" style={{backgroundImage:`url(${host}/${findHomePageDataData?.load_calc_bg_image})`}}>
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-md-7">
                                <div className="main_why_can_help text-right">
                                    <h3>
                                      {findHomePageDataData?.load_calc_title}
                                    </h3>
                                    <p>
                                        {findHomePageDataData?.load_calc_subheading}
                                    </p>
                                    <Link to="/design-your-solar-product">
                                        <span>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/design.png`} alt="design" />
                                        </span>
                                        DESIGN YOUR SOLAR PRODUCTS
                                    </Link>
                                    <Link to="/load-calculator">
                                        <span>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/load.png`} alt="load" />
                                        </span>
                                        LOAD calculator
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Wecanhelp