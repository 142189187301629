import React,{useEffect,useState} from 'react'
import "./Scss/SolarLighting.css"
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import CapexModel from '../Model/CapexModel'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
const AMC = () => {
    const [captchaInput, setCaptchaInput] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    useEffect(() => {
        loadCaptchaEnginge(6); // Initialize the CAPTCHA with 6 characters
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateCaptcha(captchaInput)) {
            alert('Captcha Matched');
        } else {
            alert('Captcha Does Not Match. Please try again.');
        }
    };
    return (
        <>
            <div className="solar-lighting-section">
                <Header />
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-caPex">
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h2>AMC</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-50 bg_images_inverters_series">
                    <div className="container">
                        <div className="row padding-b-t-20 flex_dir-xs">
                            <div className="col-lg-6 col-md-6">
                                <div className="main_detail_services">
                                    <h2 className="pb-1">AMC</h2>
                                    <p className="pb-3">
                                        AMC typically stands for Asset Management and Control, which
                                        involves projects focused on managing and controlling assets
                                        throughout their lifecycle. In various industries, including
                                        construction, infrastructure, and utilities, AMC projects aim to
                                        optimize asset performance, ensure regulatory compliance, and
                                        extend asset lifespans.
                                    </p>
                                    <p className="pb-3">
                                        The depreciation of AMC projects would reflect broader trends
                                        within industries where asset management practices are evolving in
                                        response to technological advancements, regulatory shifts, and
                                        changing economic conditions.
                                    </p>
                                    <p className="pb-3">
                                        n summary, "AMC Projects deprecation" suggests a decrease in the
                                        prominence or implementation of traditional Asset Management and
                                        Control projects, driven by factors such as.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="srvices_images s-right b-right">
                                    <img src="/assets/images/CapEx/Capex-detail-5.png" alt="savices" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="main_detail_services list_style_auto">
                                    <ul>
                                        <li>
                                            <b> Definition of AMC Projects:</b> AMC typically stands for
                                            Asset Management and Control, which involves projects focused on
                                            managing and controlling assets throughout their lifecycle. In
                                            various industries, including construction, infrastructure, and
                                            utilities, AMC projects aim to optimize asset performance,
                                            ensure regulatory compliance, and extend asset lifespans.
                                        </li>
                                        <li>
                                            <b>Reasons for Deprecation:</b>
                                            Technological Advancements: Advances in technology, such as the
                                            adoption of IoT (Internet of Things), AI (Artificial
                                            Intelligence), and predictive analytics, may offer more
                                            efficient and cost-effective ways to manage assets, reducing the
                                            need for traditional AMC projects. Shift in Industry
                                            Practices:** There could be a shift towards integrated asset
                                            management systems or platforms that provide comprehensive
                                            management capabilities, thereby making standalone AMC projects
                                            less necessary. Economic Considerations Cost pressures or budget
                                            constraints may lead organizations to prioritize other types of
                                            projects over AMC initiatives, especially if they do not
                                            directly contribute to immediate cost savings or revenue
                                            generation. Regulatory Changes Changes in regulatory
                                            requirements or standards may influence how organizations
                                            approach asset management, potentially impacting the demand for
                                            AMC projects.
                                        </li>
                                        <li>
                                            <b>Industry Trends:</b> The depreciation of AMC projects would
                                            reflect broader trends within industries where asset management
                                            practices are evolving in response to technological
                                            advancements, regulatory shifts, and changing economic
                                            conditions. In summary, "AMC Projects deprecation" suggests a
                                            decrease in the prominence or implementation of traditional
                                            Asset Management and Control projects.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-50">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="contact-form p-5">
                                <div className="main_detail_services">
                                    <h2 className="pb-4">
                                        Apply for <span> AMC </span>
                                    </h2>
                                </div>
                                <form className="input_detail_sectrion" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Your Name"
                                                    required=""
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-lg-8">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Your Email Address"
                                                    className="form-control"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    name="subject"
                                                    placeholder="Subject"
                                                    className="form-control"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <textarea
                                                    name="Message"
                                                    placeholder="Message"
                                                    className="form-control"
                                                    value={formData.Message}
                                                    onChange={handleChange}
                                                    defaultValue={""}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 custom-captcha">
                                                <LoadCanvasTemplate reloadText={"Reload"} />
                                                <input
                                                    type="text"
                                                    value={captchaInput}
                                                    onChange={(e) => setCaptchaInput(e.target.value)}
                                                    placeholder="Enter CAPTCHA"
                                                    required
                                                    className="form-control mt-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button
                                                    type="submit"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#exampleModal"
                                                >
                                                   Submit Now
                                                    <span style={{marginLeft:"12px"}}>
                                                        <i className="fa-solid fa-arrow-right-long" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
            <CapexModel id="Amc" />
        </>
    )
}

export default AMC