import React, { useEffect } from 'react'
import "./Scss/Download.css"
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadPageData } from '../../../Redux/actions/action';
const Download = () => {
    const host = process.env.REACT_APP_API_SERVER
    const { name } = useParams();
    const dispatch = useDispatch();
    const {
        loadingDownloadPageApi,
        findDownloadPageData,
        findDownloadPageDataError,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(DownloadPageData(name));
    }, [name]);
    return (
        <>
            <div className="download-section">
                <Header />
                <section>
                    <div className="slider-wrapper">
                        <div className="slider-items-download" style={{ background: `url(${host}/${findDownloadPageData?.banner})` }}>
                            <div className="banner_detail_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <h2>{findDownloadPageData?.heading}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padding-b-t-20 bg_images_inverters_series">
                    <div className="container">
                        {
                            findDownloadPageData?.category?.map((value, index) => {
                                return (
                                    <div key={index}>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="main_detail_services pt-3 pb-3">
                                                    <h2 className="pb-3 text-center">{value?.name}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                value?.catalogue?.map((value, index) => {
                                                    return (

                                                        <div key={index} className="col-lg-4 col-md-4 col-sm-6">
                                                            <div className="main_detail_catalogue">
                                                                <div className="catalogue_detail_img">
                                                                    <img style={{ objectFit: "contain" }} src={`${host}/${value?.image}`} alt="" />
                                                                </div>
                                                                {
                                                                    value?.catalogue_file ?
                                                      <a className="main_solar_det" href={`${host}${value?.catalogue_file}`} target='blank'>
                                                                            {value?.title}
                                                                            <span>
                                                                                <i className="fa-solid fa-download" />
                                                                            </span>
                                                                        </a>
                                                                        :
                                                                        <a className="main_solar_det" href="#" >
                                                                            {value?.title}
                                                                            <span>
                                                                                <i className="fa-solid fa-download" />
                                                                            </span>
                                                                        </a>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                )
                            })

                        }

                    </div>
                </section>
                <Footer />
            </div>
        </>

    )
}

export default Download