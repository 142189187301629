
import './App.css';
import './Responsive.css';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Routes,  Route } from 'react-router-dom';
import ScrollToTop from './Components/Common/ScrollToTop';
import Home from './Components/Pages/Home/Home';
import AllProducts from './Components/Pages/Products/AllProducts';
import AllServices from './Components/Pages/Services/AllServices';
import StoreLocator from './Components/Pages/StoreLocator/StoreLocator';
import StoreLocator2 from './Components/Pages/StoreLocator/StoreLocator2';
import About from './Components/Pages/About/About';
import Contactus from './Components/Pages/Contactus/Contactus';
import OnGridSolar from './Components/Pages/Products/OnGridSolar/OnGridSolar';
import OffGridSolar from './Components/Pages/Products/OffGridSolar/OffGridSolar';
import InverterProductList from './Components/Pages/Products/OnGridSolar/InverterProduct/InverterProductList';
import InverterProductDetails from './Components/Pages/Products/OnGridSolar/InverterProduct/InverterProductDetails';
import BatteryProductList from './Components/Pages/Products/OnGridSolar/BatteryProduct/BatteryProductList';
import BatteryProductDetails from './Components/Pages/Products/OnGridSolar/BatteryProduct/BatteryProductDetails';
import CapexSolarProject from './Components/Pages/Services/CapexSolarProject';
import SolarPanelProductList from './Components/Pages/Products/OnGridSolar/SolarPanelProduct/SolarPanelProductList';
import SolarPanelProductDetails from './Components/Pages/Products/OnGridSolar/SolarPanelProduct/SolarPanelProductDetails';
import EPC from './Components/Pages/ServicesPage/EPC';
import Opex from './Components/Pages/ServicesPage/Opex';
import Revex from './Components/Pages/ServicesPage/Revex';
import BusinessDivision from './Components/Pages/BusinessDivision/BusinessDivision';
import SolarLighting from './Components/Pages/ServicesPage/SolarLighting';
import AMC from './Components/Pages/ServicesPage/AMC';
import Faq from './Components/Pages/Faq/Faq';
import Download from './Components/Pages/Download/Download';
import LoadCalculator from './Components/Pages/LoadCalculator/LoadCalculator';
import DesignSolarProduct from './Components/Pages/DesignSolarProduct/DesignSolarProduct';
import OdmQuery from './Components/Pages/OdmQuery/OdmQuery';
import BookYourComplaint from './Components/Pages/BookYourComplaint/BookYourComplaint';
import Installation from './Components/Pages/Installation/Installation';
import ProductRegistration from './Components/Pages/ProductRegistration/ProductRegistration';
import ExtendedWarranty from './Components/Pages/ExtendedWarranty/ExtendedWarranty';
import Blog from './Components/Pages/Blog/Blog';
import TermsConditions from './Components/Pages/Faq/TermsConditions';
import Privacy from './Components/Pages/Faq/Privacy';

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/about' element={<About />}></Route>
            <Route exact path='/contact-us' element={<Contactus />}></Route>
            <Route exact path='/epc-services' element={<EPC />}></Route>
            <Route exact path='/opex-solar-projects' element={<Opex />}></Route>
            <Route exact path='/revex-solar-projects' element={<Revex />}></Route>
            <Route exact path='/on-grid-solar/:name' element={<OnGridSolar />}></Route>
            <Route exact path='/off-grid-solar' element={<OffGridSolar />}></Route>
            <Route exact path='/products/:name' element={<AllProducts />}></Route>
            <Route exact path='/all-services' element={<AllServices />}></Route>
            <Route exact path='/store-locator' element={<StoreLocator2 />}></Route>
            <Route exact path='/store-locator-search' element={<StoreLocator />}></Route>
            <Route exact path='/inverters-product-list' element={<InverterProductList />}></Route>
            <Route exact path='/battery-product-list' element={<BatteryProductList />}></Route>
            <Route exact path='/battery-product-details' element={<BatteryProductDetails />}></Route>
            <Route exact path='/solar-panel-product-list' element={<SolarPanelProductList />}></Route>
            <Route exact path='/solar-panel-product-details' element={<SolarPanelProductDetails />}></Route>
            <Route exact path='/inverters-product-details/:name' element={<InverterProductDetails />}></Route>
            <Route exact path='/capex-solar-projects/:name' element={<CapexSolarProject />}></Route>
            <Route exact path='/business-division' element={<BusinessDivision />}></Route>
            <Route exact path='/solar-lighting-projects' element={<SolarLighting />}></Route>
            <Route exact path='/AMC' element={<AMC />}></Route>
            <Route exact path='/FAQ' element={<Faq />}></Route>
            <Route exact path='/privacy-policy' element={<Privacy />}></Route>
            <Route exact path='/terms-conditions' element={<TermsConditions />}></Route>
            <Route exact path='/download' element={<Download />}></Route>
            <Route exact path='/load-calculator' element={<LoadCalculator />}></Route>
            <Route exact path='/design-your-solar-product' element={<DesignSolarProduct />}></Route>
            <Route exact path='/ODM-query' element={<OdmQuery />}></Route>
            <Route exact path='/book-your-complaint' element={<BookYourComplaint />}></Route>
            <Route exact path='/installation' element={<Installation />}></Route>
            <Route exact path='/product-registration' element={<ProductRegistration />}></Route>
            <Route exact path='/extended-warranty' element={<ExtendedWarranty />}></Route>
            <Route exact path='/blog/:name' element={<Blog />}></Route>
          </Routes>
          <ScrollToTop />
        </div>
      </Router>
    </>
  );
}

export default App;
