import React from 'react'
import Header from '../../Common/Header/Header'
import Footer from '../../Common/Footer/Footer'
import "./Scss/Epc.css";
import EPCcontent from './EPCcontent';

const EPC = () => {
    return (
        <>
            <div className="alcorn-epc-services">
                <Header />
                <EPCcontent />
                <Footer />
            </div>
        </>
    )
}

export default EPC