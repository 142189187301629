import React from 'react'

const CapexModel = ({id}) => {
    return (
        <div className="capex-model">

        <div
            className="modal fade"
            id={id}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog Registra_modul_Request">
                <div className="modal-content ">
                    <div className="modal-body thank_heading_detail">
                        <img src="/assets/images/popup_back.png" alt="img" />
                        <h4>Thank You</h4>
                        <p>Your submission has been received,</p>
                        <p>We will be in touch and contact you soon!</p>
                        <div className="request_send">
                            <button
                                type="button"
                                className="btn btn-secondary back_color_main"
                                data-bs-dismiss="modal"
                            >
                                Back to site{" "}
                                <span>
                                    <i className="fa-solid fa-arrow-right-long" />
                                </span>
                            </button>
                            {/* <a href="#" class="btn btn-secondary back_color_main"></span></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    )
}

export default CapexModel