import React, { useEffect, useState } from 'react'
import "./Scss/Header.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderData } from '../../../Redux/actions/action';
import { HeaderFooterData } from '../../../Redux/actions/action';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const host = process.env.REACT_APP_API_SERVER

    const {
        loadingHeaderFooterApi,
        findHeaderFooter,
        findHeaderFooterError,
    } = useSelector(state => state?.reducer);

    let HeaderProductData = useSelector(state => state?.reducer ? state?.reducer?.findHeaderData : []);

    useEffect(() => {
        dispatch(HeaderFooterData());
        dispatch(HeaderData());

        window.onscroll = function () {
            myFunction();
        };

        var header = document.getElementById('myHeader');
        var sticky = header.offsetTop;

        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        }
    }, [dispatch]);

    useEffect(() => {
        // Toggle a class on the body when the menu state changes
        if (isMenuOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }

        // Clean up function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('menu-open');
        };
    }, [isMenuOpen]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='header-alcorn'>
            <header className="bg_traprent_fix" id="myHeader">
                <div className="container">
                    <div className={`main_header_sosal header_padd_20 `}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="sosal_email_icon">
                                    <a href="#">
                                        <i className="fa-regular fa-envelope" /> {findHeaderFooter?.email}
                                    </a>
                                    <a href="#">
                                        <i className="fa-solid fa-phone" /> {findHeaderFooter?.mobile}
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="sosal_mid_icon">
                                    <ul>
                                        <li>
                                            <Link to={findHeaderFooter?.fb} target='blank'>
                                                <i className="fa-brands fa-square-facebook" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={findHeaderFooter?.twitter} target='blank'>
                                                <i className="fa-brands fa-x-twitter" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={findHeaderFooter?.linkedin} target='blank'>
                                                <i className="fa-brands fa-linkedin" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={findHeaderFooter?.instagram} target='blank'>
                                                <i className="fa-brands fa-instagram" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-lg navbar-dark bg-primary-black">
                        <div className="container-fluid main_header_detail">
                            <Link className="navbar-brand logo_main" to="/">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/alcorn_logo.png`} alt="logo" />
                            </Link>
                            <button
                                className="navbar-toggler left_section_padd"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded={isMenuOpen}
                                aria-label="Toggle navigation"
                                onClick={toggleMenu}
                            >
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                                <ul className="navbar-nav  mb-2 mb-lg-0 header_border_xs">
                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to='/about'>
                                            About us
                                        </Link>
                                    </li>
                                    {
                                        HeaderProductData?.product?.map((value, index) => {
                                            return (
                                                <li className="nav-item dropdown" key={index}>
                                                    <a
                                                        className="nav-link dropdown-toggle"
                                                        role="button"
                                                        id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"
                                                    >
                                                        {value?.name} <i className="fa-sharp fa-solid fa-chevron-down" />
                                                    </a>
                                                    <ul className="dropdown-menu bg_detail_drop" aria-labelledby="dropdownMenuClickableInside">
                                                        <li>
                                                            <Link className="dropdown-item ps-2" to={`/products/${value?.slug}`}>
                                                                All {value?.name}
                                                            </Link>
                                                        </li>
                                                        {value?.Product?.map((productValue, productIndex) => (
                                                            <li key={productIndex} className="nav-item dropend">
                                                                <Link className="nav-link dropdown-toggle" to={`/on-grid-solar/${productValue?.slug}`}>
                                                                    {productValue?.name}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                        {value?.SubCategory?.map((subCategoryValue, subCategoryIndex) => (
                                                            <li key={subCategoryIndex} className="nav-item dropend">
                                                                <Link className="nav-link dropdown-toggle" role="button"
                                                                    id="dropDownSecond" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                                                    {subCategoryValue?.name}
                                                                    {subCategoryValue?.Prouduct_detail?.length > 0 && (
                                                                        <i className="fa-sharp fa-solid fa-chevron-right icon_drop_sec head_xs_i" />
                                                                    )}
                                                                </Link>
                                                                <ul className="dropdown-menu bg_detail_drop" aria-labelledby="dropDownSecond">
                                                                    <li>
                                                                        <Link className="dropdown-item" to={`/on-grid-solar/${subCategoryValue?.slug}`}>
                                                                            All {subCategoryValue?.name}
                                                                        </Link>
                                                                    </li>
                                                                    {subCategoryValue?.Prouduct_detail?.length > 0 &&
                                                                        subCategoryValue?.Prouduct_detail?.map((productDetailValue, productDetailIndex) => (
                                                                            <li key={productDetailIndex}>
                                                                                <Link className="dropdown-item" to={`/inverters-product-details/${productDetailValue?.slug}`}>
                                                                                    {productDetailValue?.name}
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )
                                        })
                                    }
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Services <i className="fa-sharp fa-solid fa-chevron-down" />
                                        </a>
                                        <ul className="dropdown-menu bg_detail_drop">
                                            <li>
                                                <Link className="dropdown-item" to="/all-services">
                                                    All Services
                                                </Link>
                                            </li>
                                            {
                                                HeaderProductData?.service?.map((value, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link className="dropdown-item" to={`/capex-solar-projects/${value?.slug}`}>
                                                                {value?.name}
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">
                                            Other Solutions
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/store-locator">
                                            Store Locator
                                        </Link>
                                    </li>
                                    <li className="nav-item main_nav_button">
                                        <Link className="nav-link" to="/contact-us">Contact us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Header;
