import React, { useEffect } from 'react'
import "./Scss/Faq.css"
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { FAQData } from '../../../Redux/actions/action';
import Loader from '../../Common/Loader/Loader';
import Question from './Question';
const Faq = () => {
    const dispatch = useDispatch();
    const {
        findFAQ,
        findFAQError,
        loadingFAQApi,
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(FAQData());
    }, []);

    if (loadingFAQApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    return (
        <>
            <div className="faq-section">
                <Header />
                <section className="padding-b-t-20 faq_bg_detail">
                    <div className="container my-5">
                        <div className="asked_question">
                            <h4 className="mb-4">Frequently Asked Questions</h4>
                        </div>
                        <div className="accordion main_faq_asked" id="faqAccordion">
                            {findFAQ && findFAQ.length > 0 ?
                                findFAQ.map((faq, index) => {
                                    return <Question faq={faq} index={index + 1} />
                                }) :
                                <div className="text-center">
                                    <i>No FAQ found.</i>
                                </div>
                            }
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default Faq