import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import 'intl-tel-input/build/css/intlTelInput.css';
import "./Scss/DesignSolarProduct.css";
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import CapexModel from '../Model/CapexModel';
import parse from 'html-react-parser';
import { boolean } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { DesignYourSolarProduct, HearedAboutUs, InstallationArea, InstallationTimeLine, SolarInverterTech, SolarPanelType, TypeOfProperty } from '../../../Redux/actions/action';
import { useFormik } from "formik";
import { DesignYourSolarProductSchema } from '../../../validationSchema/Index';
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
const DesignSolarProduct = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const host = process.env.REACT_APP_API_SERVER
    const dispatch = useDispatch();
    const {
        loadingDesignYourSolarProductApi,
        findDesignYourSolarProduct,
        findInstallationArea,
        findTypeOfProperty,
        findInstallationTimeline,
        findSolarInverterTech,
        findDesignYourSolarProductError,
        findSolarPanelType,
        findHearedAboutUs
    } = useSelector(state => state?.reducer);
    useEffect(() => {
        dispatch(DesignYourSolarProduct());
        dispatch(InstallationArea());
        dispatch(TypeOfProperty());
        dispatch(InstallationTimeLine());
        dispatch(SolarPanelType());
        dispatch(SolarInverterTech());
        dispatch(HearedAboutUs());
    }, []);
    const selectInstallationArea = findInstallationArea?.map((value, index) => ({
        value: value?.name,
        label: value?.name
    }));
    const selectTypeOfProperty = findTypeOfProperty?.map((value, index) => ({
        value: value?.name,
        label: value?.name
    }));
    const selectInstallationTimeline = findInstallationTimeline?.map((value, index) => ({
        value: value?.name,
        label: value?.name
    }));
    const selectSolarPanelType = findSolarPanelType?.map((value, index) => ({
        value: value?.name,
        label: value?.name
    }));
    const selectSolarInverterTech = findSolarInverterTech?.map((value, index) => ({
        value: value?.name,
        label: value?.name
    }));
    const selectHearedAboutUs = findHearedAboutUs?.map((value, index) => ({
        value: value?.name,
        label: value?.name
    }));
    const formik = useFormik({
        initialValues: {
            fname: "",
            lname: "",
            email: "",
            mobile: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            typeofproperty: "",
            propertysize: "",
            installationarea: "",
            availablespace: "",
            last_year_avg_monthly_consumption: "",
            last_year_avg_monthly_bill: "",
            current_electricity_provider: "",
            existing_solar_panel_installed: boolean,
            preferred_solar_inverter_technology: "",
            preferred_solar_panel_type: "",
            energy_storage_options: boolean,
            system_size: "",
            preferred_installation_timeline: "",
            specific_concerns: "",
            how_did_you_hear_about_us: "",
        },
        validationSchema: DesignYourSolarProductSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await axios.post(
                    `${host}/web/design-your-solar-product`,
                    values,
                    {
                        headers: {
                            "Content-Type": "application/json", 
                        },
                    }
                );
                if (response.data.code === 200) {
                    toast.success(response?.data?.msg);
                    setIsModalOpen(true);
                    resetForm();

                }
            } catch (error) {
                const errorMessage = error.response ? error.response.data : error.message;
                toast.error(errorMessage);
                console.error("Error:", errorMessage);
            }
        },
    });
    return (
        <div className="design-solar-product">
            <Toaster/>
            <Header />
            <section>
                <div className="slider-wrapper">
                    <div className="slider-items-srvices" style={{ background: `url(${host}/${findDesignYourSolarProduct?.banner})` }}>
                        <div className="banner_detail_text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className='text-capitalize'>
                                            {findDesignYourSolarProduct?.title}
                                        </h2>
                                        <p>
                                            {findDesignYourSolarProduct?.subtitle} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="padding-b-t-50 bg_images_se">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main_why_choose_us services_payra pb-3">
                                <h2 className="pb-3 text-capitalize">
                                    {findDesignYourSolarProduct?.heading}
                                </h2>
                                <p className="pb-3">
                                    {parse(`${findDesignYourSolarProduct?.description}`)}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="card-fans-details">
                                    <div className="customize-header">Personal Details</div>
                                    <div className="main_detail_persanal">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>First Name </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter First Name "
                                                        name="fname"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.fname}
                                                    />
                                                    {formik.touched.fname && formik.errors.fname ? (
                                                        <div className="error">{formik.errors.fname}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Last Name </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Last Name"
                                                        name="lname"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.lname}
                                                    />
                                                    {formik.touched.lname && formik.errors.lname ? (
                                                        <div className="error">{formik.errors.lname}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Email Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Email Address "
                                                        name="email"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email}
                                                    />
                                                    {formik.touched.email && formik.errors.email ? (
                                                        <div className="error">{formik.errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Phone Number</label>
                                                    <div className="form-group custom-mobile-code">
                                                        <input
                                                            type="tel"
                                                            // id="mobile_code"
                                                            // ref={inputRef}
                                                            className="form-control main_detail_numbar w-100"
                                                            placeholder="Phone Number"
                                                            name="mobile"
                                                            inputMode="numeric"
                                                            pattern="[0-9]*"
                                                            onChange={(e) => {
                                                                // Allow only digits
                                                                const re = /^[0-9\b]+$/;
                                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                                    formik.handleChange(e);
                                                                }
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.mobile}

                                                        />
                                                        {formik.touched.mobile && formik.errors.mobile ? (
                                                            <div className="error">{formik.errors.mobile}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-fans-details">
                                    <div className="customize-header">Address</div>
                                    <div className="main_detail_persanal">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-sm-6">
                                                <div className="main_input_detail pb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Address 1  "
                                                        name="address1"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.address1}
                                                    />
                                                    {formik.touched.address1 && formik.errors.address1 ? (
                                                        <div className="error">{formik.errors.address1}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6">
                                                <div className="main_input_detail pb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Address 2 "
                                                        name="address2"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.address2}
                                                    />
                                                    {formik.touched.address2 && formik.errors.address2 ? (
                                                        <div className="error">{formik.errors.address2}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 ">
                                                <div className="main_input_detail pb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Address 3 "
                                                        name="address3"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.address3}
                                                    />
                                                    {formik.touched.address3 && formik.errors.address3 ? (
                                                        <div className="error">{formik.errors.address3}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6">
                                                <div className="main_input_detail pb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Address 4"
                                                        name="address4"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.address4}
                                                    />
                                                    {formik.touched.address4 && formik.errors.address4 ? (
                                                        <div className="error">{formik.errors.address4}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-fans-details">
                                    <div className="customize-header">Property Details</div>
                                    <div className="main_detail_persanal">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Type of property* </label>
                                                    <Select
                                                        options={selectTypeOfProperty}
                                                        value={selectTypeOfProperty.filter((option) =>
                                                            formik.values.typeofproperty.includes(option.value)
                                                        )}
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue("typeofproperty", selectedOption.value);
                                                        }}
                                                    />
                                                    {formik.touched.typeofproperty &&
                                                        formik.errors.typeofproperty && (
                                                            <div className="error">
                                                                {formik.errors.typeofproperty}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Property Size (in square feet)* </label>
                                                    <input
                                                        type="text"
                                                        className="form-control" placeholder="Property Size"
                                                        name="propertysize"
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        onChange={(e) => {
                                                            // Allow only digits
                                                            const re = /^[0-9\b]+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                formik.handleChange(e);
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.propertysize}
                                                    />
                                                    {formik.touched.propertysize && formik.errors.propertysize ? (
                                                        <div className="error">{formik.errors.propertysize}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Installation Area*</label>
                                                    <Select
                                                        options={selectInstallationArea}
                                                        value={selectInstallationArea.filter((option) =>
                                                            formik.values.installationarea.includes(option.value)
                                                        )}
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue("installationarea", selectedOption.value);
                                                        }}

                                                    />
                                                    {formik.touched.installationarea &&
                                                        formik.errors.installationarea && (
                                                            <div className="error">
                                                                {formik.errors.installationarea}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>
                                                        Available Space for Solar Panels (in square feet)
                                                    </label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Available Space for Solar Panels (in square feet)"
                                                            name="availablespace"
                                                            inputMode="numeric"
                                                            pattern="[0-9]*"
                                                            onChange={(e) => {
                                                                // Allow only digits
                                                                const re = /^[0-9\b]+$/;
                                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                                    formik.handleChange(e);
                                                                }
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.availablespace}
                                                        />
                                                        {formik.touched.availablespace && formik.errors.availablespace ? (
                                                            <div className="error">{formik.errors.availablespace}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-fans-details">
                                    <div className="customize-header">Energy Usage</div>
                                    <div className="main_detail_persanal">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>
                                                        Last 1 Year Average Monthly Electricity Consumption in
                                                        Unit
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Last 1 Year Average Monthly Electricity Consumption in Unit"
                                                        name="last_year_avg_monthly_consumption"
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        onChange={(e) => {
                                                            // Allow only digits
                                                            const re = /^[0-9\b]+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                formik.handleChange(e);
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.last_year_avg_monthly_consumption}
                                                    />
                                                    {formik.touched.last_year_avg_monthly_consumption && formik.errors.last_year_avg_monthly_consumption ? (
                                                        <div className="error">{formik.errors.last_year_avg_monthly_consumption}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>
                                                        Last 1 Year Average Monthly Electricity Bill in INR*
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Last 1 Year Average Monthly Electricity Bill in INR*" name="last_year_avg_monthly_bill"
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        onChange={(e) => {
                                                            // Allow only digits
                                                            const re = /^[0-9\b]+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                formik.handleChange(e);
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.last_year_avg_monthly_bill}
                                                    />
                                                    {formik.touched.last_year_avg_monthly_bill && formik.errors.last_year_avg_monthly_bill ? (
                                                        <div className="error">{formik.errors.last_year_avg_monthly_bill}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Current Electricity Provider</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Current Electricity Provider"
                                                        name="current_electricity_provider"
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        onChange={(e) => {
                                                            // Allow only digits
                                                            const re = /^[0-9\b]+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                formik.handleChange(e);
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.current_electricity_provider}
                                                    />
                                                    {formik.touched.current_electricity_provider && formik.errors.current_electricity_provider ? (
                                                        <div className="error">{formik.errors.current_electricity_provider}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>
                                                        Do you have any existing solar panels installed?*
                                                    </label>
                                                    <div className="options">
                                                        <input
                                                            type="radio"
                                                            id="yes"
                                                            name="existing_solar_panel_installed"

                                                            value={true}
                                                            checked={formik.values.existing_solar_panel_installed === true}
                                                            onChange={() => formik.setFieldValue("existing_solar_panel_installed", true)}
                                                        />
                                                        <label htmlFor="yes">Yes</label>
                                                        <input
                                                            type="radio"
                                                            id="no"
                                                            name="existing_solar_panel_installed"
                                                            value={false}
                                                            checked={formik.values.existing_solar_panel_installed === false}
                                                            onChange={() => formik.setFieldValue("existing_solar_panel_installed", false)}
                                                        />
                                                        <label htmlFor="no">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-fans-details">
                                    <div className="customize-header">Solar System Preferences</div>
                                    <div className="main_detail_persanal">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Preferred Solar Inverter Technology*</label>
                                                    <Select
                                                        options={selectSolarInverterTech}
                                                        value={selectSolarInverterTech.filter((option) =>
                                                            formik.values.preferred_solar_inverter_technology.includes(option.value)
                                                        )}
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue("preferred_solar_inverter_technology", selectedOption.value);
                                                        }}

                                                    />
                                                    {formik.touched.preferred_solar_inverter_technology &&
                                                        formik.errors.preferred_solar_inverter_technology && (
                                                            <div className="error">
                                                                {formik.errors.preferred_solar_inverter_technology}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Preferred Solar Panel Type* </label>
                                                    <Select
                                                        options={selectSolarPanelType}
                                                        value={selectSolarPanelType.filter((option) =>
                                                            formik.values.preferred_solar_panel_type.includes(option.value)
                                                        )}
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue("preferred_solar_panel_type", selectedOption.value);
                                                        }}

                                                    />
                                                    {formik.touched.preferred_solar_panel_type &&
                                                        formik.errors.preferred_solar_panel_type && (
                                                            <div className="error">
                                                                {formik.errors.preferred_solar_panel_type}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>Energy Storage Options</label>
                                                    <div className="options">
                                                        <input
                                                            type="radio"
                                                            id="yes_1"
                                                            name="energy_storage_options"
                                                            value={true}
                                                            checked={formik.values.energy_storage_options === true}
                                                            onChange={() => formik.setFieldValue("energy_storage_options", true)}
                                                        />
                                                        <label htmlFor="yes_1">Yes</label>
                                                        <input
                                                            type="radio"
                                                            id="no_1"
                                                            name="energy_storage_options"
                                                            value={false}
                                                            checked={formik.values.energy_storage_options === false}
                                                            onChange={() => formik.setFieldValue("energy_storage_options", false)}
                                                        />
                                                        <label htmlFor="no_1">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="main_input_detail pb-3">
                                                    <label>System Size (KW)*</label>
                                                    <input
                                                        type="text"

                                                        className="form-control"
                                                        placeholder="Enter System Size (KW)*"
                                                        name="system_size"
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        onChange={(e) => {
                                                            // Allow only digits
                                                            const re = /^[0-9\b]+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                formik.handleChange(e);
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.system_size}
                                                    />
                                                    {formik.touched.system_size && formik.errors.system_size ? (
                                                        <div className="error">{formik.errors.system_size}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-fans-details">
                                    <div className="customize-header">Additional Information</div>
                                    <div className="main_detail_persanal">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="main_input_detail pb-3">
                                                    <label>Preferred Installation Timeline*</label>
                                                    <Select
                                                        options={selectInstallationTimeline}
                                                        value={selectInstallationTimeline.filter((option) =>
                                                            formik.values.preferred_installation_timeline.includes(option.value)
                                                        )}
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue("preferred_installation_timeline", selectedOption.value);
                                                        }}

                                                    />
                                                    {formik.touched.preferred_installation_timeline &&
                                                        formik.errors.preferred_installation_timeline && (
                                                            <div className="error">
                                                                {formik.errors.preferred_installation_timeline}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-fans-details">
                                    <div className="customize-header">
                                        Is specific concerns or questions you have about solar
                                        installation? Please write down your all queries
                                    </div>
                                    <div className="main_detail_persanal">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="main_input_detail pb-3">
                                                    <textarea
                                                        placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "
                                                        className="form-control-technology"
                                                        name="specific_concerns"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.specific_concerns}
                                                    />
                                                    {formik.touched.specific_concerns && formik.errors.specific_concerns ? (
                                                        <div className="error">{formik.errors.specific_concerns}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-fans-details">
                                    <div className="customize-header">
                                        How did you hear about us?*
                                    </div>
                                    <div className="main_detail_persanal">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="main_input_detail pb-3">
                                                    <Select
                                                        options={selectHearedAboutUs}
                                                        value={selectHearedAboutUs.filter((option) =>
                                                            formik.values.how_did_you_hear_about_us.includes(option.value)
                                                        )}
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue("how_did_you_hear_about_us", selectedOption.value);
                                                        }}

                                                    />
                                                    {formik.touched.how_did_you_hear_about_us &&
                                                        formik.errors.how_did_you_hear_about_us && (
                                                            <div className="error">
                                                                {formik.errors.how_did_you_hear_about_us}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="pull-left pb-3">
                                                    <input type="checkbox" name="" />
                                                    <label
                                                        htmlFor=""
                                                        className="inline-block pointer fz15 white-color"
                                                    >
                                                        *I agree to be contacted by Alcorn India regarding my
                                                        solar installation inquiry.
                                                    </label>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="main_input_detail_button"
                                                // data-bs-toggle="modal"
                                                // data-bs-target="#designSolarProduct"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <CapexModel id="designSolarProduct" />
        </div>
    )
}

export default DesignSolarProduct